import { useApolloClient } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { History } from 'history'
import iassign from 'immutable-assign'
import { cloneDeep, compact, find, findIndex, first, flatMap, forEach, get, isArray, isEqual, lowerFirst, mergeWith, remove, set, uniq } from 'lodash'
import moment, { Moment } from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import Auth from '../../../Auth/Auth'
import { appDate } from '../../../Context/CalendarContext'
import { EditButtonContext } from '../../../Context/EditButtonContext'
import { TemporaryAlertContext } from '../../../Context/TemporaryAlertContext'
import { ClientPortfolioDetailComponentFragment, ClientPortfolioDetailLayoutFragment, ClientReportOrderFragment, ComponentApprovalCode, ComponentOverrideSettings, ComponentSettingsInput, ComponentType, CreateComponentInput, ListMemberIdType, MeFragment, PublishLayoutArgs, PublishReportMutationVariables, ReportsFragment, ReportsListFragment, ReportsListMemberFragment, UpdateComponentInput, UpdateListInput, UpdateReportInput, UpsertLayoutArgs, useClientAllReportsLazyQuery, useCreateComponentMutation, useDeleteComponentMutation, useExportReportLazyQuery, usePublishReportMutation, usePushAllComponentDatesMutation, useUpdateComponentMutation, useUpdateListMutation, useUpdateReportMutation, useUpdateReportOrderMutation, useUpsertLayoutMutation } from '../../../__generated__/graphql'
import { DATE_API_FORMAT } from '../../../helpers/constant'
import { downloadWithFilename } from '../../../helpers/download'
import { typeIdMapping } from '../../../helpers/list'
import { convertLookupToString, excludePropertyArray } from '../../../helpers/object'
import { convertComponentForMutation, listReportExpanded, recursivelyOrderReport } from '../../../helpers/report'
import RouteLeavingGuard, { GuardModal, ModalScript } from '../../Shared/RouteLeavingGuard'
import { EditButtons } from '../../ui/EditButtons'
import { FormInput } from '../../ui/Forms/FormInput'
import { ReportAddable } from './ReportAddable'
import { AfterPublishMapping, ExportSettingsMapping, ReportDisplayType, ReportTab, selectedComponentProp } from './ReportComponent'
import ReportEdit from './ReportEdit'
import ReportSection, { SectionMapping } from './ReportSection'
import ReportSidebar from './ReportSidebar'

type idProps = {
  clientId: number
  reportId: number
  history: History
  auth: Auth
  portfolioId?: number
  user?: MeFragment
  list: listReportExpanded[]
  view: ReportDisplayType
  report: ReportsFragment
  hideFullExport?: boolean
  hideSingleExport?: boolean
}

enum PublishStatus{
  Start = 1,
  Publishing = 2,
  Error = 3,
  Success = 4,
}

enum savingStatus{
  Initial = 1,
  StartUpdatingComponents = 2,
  CreatingUpdatingComponents = 3,
  StartOrder = 4,
  UpdatingOrder = 5,
  Error = 6,
}


const ReportMain: React.FC<idProps> = (props) => {
  const { auth, portfolioId, reportId, user, list, clientId, view, report } = props
  const { hideFullExport, hideSingleExport} = props
  let usedList:ReportsListFragment | undefined , usedInstance:ReportsListMemberFragment | undefined
  let usedLayout:ClientPortfolioDetailLayoutFragment | undefined
  if(view === ReportDisplayType.Live){
    usedList = report.liveList || undefined
  } else if (view === ReportDisplayType.Draft){
    usedList = report.draftList || undefined
  }
  usedInstance = find(usedList?.items, (item) => {
    if(item.item?.__typename === 'ClientPortfolio'){
      return item.item.id === portfolioId
    }
  }) as ReportsListMemberFragment
  if(view === ReportDisplayType.Live){
    usedLayout = usedInstance?.liveLayout || undefined
  } else if (view === ReportDisplayType.Draft){
    usedLayout = usedInstance?.draftLayout || undefined
  }

  const [editedDraftLayout, setEditedDraftLayout] = useState<ClientPortfolioDetailLayoutFragment | undefined>(usedLayout || undefined)
  const [editedReport, setEditedReport] = useState<ReportsFragment>(report)
  const [reportState, setReportState] = useState({}) // Used to store state that must be shared with export
  const [reportTabOrder, setReportTabOrder] = useState<ClientReportOrderFragment[]>([])

  const [publishReportMutation] = usePublishReportMutation()
  const [updateComponentMutation] = useUpdateComponentMutation()
  const [createComponentMutation] = useCreateComponentMutation()
  const [deleteComponentMutation] = useDeleteComponentMutation()
  const [upsertLayoutMutation] = useUpsertLayoutMutation()
  const [updateReportMutation] = useUpdateReportMutation()
  const [updateListMutation] = useUpdateListMutation()
  const [pushAllComponentDatesMutation] = usePushAllComponentDatesMutation()
  const [updateReportOrderMutation] = useUpdateReportOrderMutation()
  const { addAlert } = useContext(TemporaryAlertContext)
  const [fetchClientReports, { data: clientReportData }] = useClientAllReportsLazyQuery()

  const [publishModalOpen, setPublishModalOpen] = useState(false)
  const [rolloverModalOpen, setRolloverModalOpen] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [publishCount, setPublishCount] = useState(0)
  const [publishStatus, setPublishStatus] = useState(PublishStatus.Start)
  const { resetErrors } = useContext(EditButtonContext)
  const graphqlClient = useApolloClient()

  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(savingStatus.Initial)
  const [savingComponents, setSavingComponents] = useState(0)
  const [selectedComponent, setSelectedComponent] = useState<selectedComponentProp | undefined>(undefined)
  const [toSelectedComponent, setToSelectedComponent] = useState<selectedComponentProp | undefined>(undefined)
  const [editNavbar, setEditNavbar] = useState(editMode ? "Component" : "Report")
  const [toEditNavbar, setToEditNavbar] = useState<string | undefined>(undefined)

  const allEditedComponents = flatMap(editedDraftLayout?.sections, (section) => section?.components)
  const allBaseComponents = flatMap(usedLayout?.sections, (section) => section?.components)
  let latestAsOfDate: Moment | undefined = undefined
  let componentEdited = allEditedComponents?.filter(x => {
    const matchingComponent = find(allBaseComponents, {id: x?.id})
    const componentDate = get(x, view === ReportDisplayType.Live ? "liveSettings.date" : "draftSettings.date")
    if(componentDate && (!latestAsOfDate || moment(componentDate).isAfter(latestAsOfDate))){
      latestAsOfDate = moment(componentDate)
    }
    if(matchingComponent){
      return JSON.stringify(matchingComponent) !== JSON.stringify(x)
    }
    return true
  }).length > 0

  useEffect(() => {
    if(savingComponents < 1 && editMode && saving === savingStatus.CreatingUpdatingComponents){
      setSaving(savingStatus.StartOrder)
    }
  }, [editMode, saving, savingComponents])

  useEffect(() => {
    setEditedDraftLayout(usedLayout)
  }, [usedLayout, editMode])

  useEffect(() => {
    setEditedReport(report)
  }, [report, editMode])

  const handleEdit = (value: boolean) => {
    setEditMode(value)
    setEditedReport(report)
    if(usedLayout){
      setEditedDraftLayout(usedLayout)
    }
  }

  const afterSave = () => {
    setSavingComponents(prevCount => prevCount - 1)
  }

  const handleSubmit = () => {
    setSaving(savingStatus.StartUpdatingComponents)
  }

  const rolloverReport = () => {
    setRolloverModalOpen(false)
    pushAllComponentDatesMutation({ variables: { input: { reportId }, liveView: false, draftView: true }})
      .then(result => {
        addAlert({title: "Success | Report updated.", message: "", color: "userSuccess", timeout: 3000})
      })
      .catch(error => {
        addAlert({title: "Error | Report not updated.", message: error.message, color: "danger", timeout: 5000})
      })
  }

  const [getExportUrl, {loading: exportLoading, data: exportData, error: exportError}] = useExportReportLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if(data.exportReport.url) downloadWithFilename(data.exportReport.url, (report?.exportSettings?.filename || report?.name) + ".pptx")
    }
  })

  const exportReport = () => {
    if(!exportLoading) {
      let reviewType = ""
      let reviewDate = latestAsOfDate?.format(DATE_API_FORMAT)
      let componentOverrideSettings:ComponentOverrideSettings = {}
      usedLayout?.sections?.forEach((section, sectionNum) => {
        section?.components?.forEach((component, componentNum) => {
          if(!component?.type) return
          const exportSettings = ExportSettingsMapping[component.type]
          if(!exportSettings) return
          const currentReportState = get(reportState, component.id.toString(), {})
          const currentTab = get(currentReportState, 'tab', {} as ReportTab)
          if(currentTab && currentTab.primary === true && (reviewType === "" || component.type === ComponentType.ManagerPerformance)){
            reviewType = "Quarterly Review"
            reviewDate = currentTab.value
          } else if (currentTab && (reviewType === "" || component.type === ComponentType.ManagerPerformance)) {
            reviewType = "Monthly Review"
            reviewDate = currentTab.value
          }
          const exportedComponent = exportSettings({
            component: component,
            report: report,
            auth: auth,
            view: view,
            sectionNumber: sectionNum,
            componentNumber: componentNum,
            editMode: false,
            setEditedDraftLayout,
            graphqlClient: graphqlClient,
            editedDraftLayout: usedLayout as ClientPortfolioDetailLayoutFragment,
            reportState: currentReportState,
            setReportState: setReportState,
            clientId: clientId,
          })
          componentOverrideSettings = mergeWith(componentOverrideSettings, exportedComponent, (objValue, srcValue) => {
            if (isArray(objValue)) {
              return objValue.concat(srcValue);
            }
          })
        })
      })

      getExportUrl({variables: {input: {
        name: report?.exportSettings?.filename || report?.name,
        slides: compact(usedLayout?.sections?.map((section, idx) => {
          if(section?.type){
            return {
              title: first(section.components)?.name || report.name,
              sections:[{
                components: compact(section.components?.map((component) => {
                  return component?.id
                })),
                type: section.type
              }]
            }
          }
          return undefined
        })),
        settings: {
          live: view === ReportDisplayType.Live,
          footerName: report?.exportSettings?.footerName || report.client?.name,
          componentOverrideSettings,
        },
        titleSlide: {
          date: reviewDate,
          title: report?.exportSettings?.titleSlide?.title || report?.name,
          subtitle: (report?.exportSettings?.titleSlide?.subtitle || "Investment Measurement Service") + `\n${reviewType}`,
        }
      }}})
    }
  }

  // This starts when handleSubmit is called
  if(editedDraftLayout){
    if(saving === savingStatus.StartUpdatingComponents){
      // work out what has been changed
      setSaving(savingStatus.CreatingUpdatingComponents)
      const editedComponents = compact(editedDraftLayout?.sections?.flatMap((section) => section?.components))
      const draftComponents = compact(usedLayout?.sections?.flatMap((section) => section?.components))
      let updatedComponents = editedComponents?.filter(x => {
        const matchingComponent = find(draftComponents, {id: x.id})
        if(matchingComponent){
          return JSON.stringify(matchingComponent) !== JSON.stringify(x)
        }
        return false
      })
      let newComponents = editedComponents?.filter(x => {
        const matchingComponent = find(draftComponents, {id: x.id})
        if(matchingComponent){
          return false
        }
        return true
      })
      let deletedComponents = draftComponents?.filter(x => {
        const matchingComponent = find(editedComponents, {id: x.id})
        if(matchingComponent){
          return false
        }
        return true
      })

      let reportUpdateCount = 0

      // update report order
      updateReportOrderMutation({ variables: { input: {
        orgId: clientId,
        order: reportTabOrder.map((r, idx) => ({ reportId: r.id, order: idx }))
      }}}).then(() => {
        fetchClientReports({
          fetchPolicy: "network-only",
          variables: { id: clientId }
        })
      })

      // update the report
      const isReportEdited = JSON.stringify(report) !== JSON.stringify(editedReport)
      if(isReportEdited){
        let patchList = convertLookupToString(editedReport, false, [], ["Person", "Client", "List"])
        let excludedPatch = excludePropertyArray(patchList, ["__typename", "id", "lastPublished", "liveList", "plans", "dueDates", "contacts"])

        // handle due date
        const currentDueDate = find(editedReport.dueDates, {quarterDate: appDate.format(DATE_API_FORMAT)})
        const previousDueDate = find(report.dueDates, {quarterDate: appDate.format(DATE_API_FORMAT)})
        if(currentDueDate && currentDueDate.dueDate && (!previousDueDate || !previousDueDate.dueDate || currentDueDate.dueDate !== previousDueDate.dueDate)){
          excludedPatch.dueDate = {add: [currentDueDate]}
          if(previousDueDate){
            excludedPatch.dueDate.remove = [appDate.format(DATE_API_FORMAT)]
          }
        }
        //handle owner change
        const currentOwner = editedReport.owner?.id
        const previousOwner = report.owner?.id
        if(currentOwner !== previousOwner){
            //editedReport.draftList
          const ownerLists: number[] = compact(uniq([editedReport.draftList?.id, ...editedReport?.draftList?.items?.flatMap((item:any) => {
            return item.draftLayout?.sections?.flatMap((section:any) =>{
              return section?.components?.flatMap((component:any) =>{
                if("list" in component?.draftSettings){
                  return component?.draftSettings?.list.id
                }
                return []
              }) || []
            }) || []
          })|| []]))
          ownerLists.forEach((list)=>{
            reportUpdateCount += 1
            const listSettings: UpdateListInput = {
              id: list,
              patch: {owner: currentOwner}
            }
            updateListMutation({
              variables: {
                input: listSettings,
              }}).then(result => {
                afterSave()
              })
              .catch(err => {
                afterSave()
                setSaving(savingStatus.Error)
                console.log("Error Component Save", err.message)
              })
          })
        }
        // handle contacts
        let usedContacts = cloneDeep(report.contacts) || []
        editedReport.contacts?.forEach((x) => {
          const matchingContact = remove(usedContacts, (c) => c.contact?.id === x.contact?.id)
          const contactPatch = {contactId: x.contact?.id, deliveryMethod: x.deliveryMethod?.code}
          if(!contactPatch.contactId || !contactPatch.deliveryMethod) return
          if(matchingContact && matchingContact.length > 0){
            if(JSON.stringify(matchingContact[0]) !== JSON.stringify(x)){
              excludedPatch.contacts = {add: compact([...(excludedPatch.contacts?.add || []), contactPatch]), remove: compact([...(excludedPatch.contacts?.remove || []) , matchingContact[0].contact?.id])}
            }
          } else {
            excludedPatch.contacts = {add: compact([...(excludedPatch.contacts?.add || []), contactPatch])}
          }
        })
        usedContacts?.forEach((x) => {
          excludedPatch.contacts = {...excludedPatch.contacts, remove: compact([...(excludedPatch.contacts?.remove || []), x.contact?.id])}
        })

        let reportSettings:UpdateReportInput = {
          id: editedReport.id,
          patch: excludedPatch,
        }
        reportUpdateCount += 1

        updateReportMutation({
          variables: {
            input: reportSettings,
            liveView: false,
            draftView: true,
          }}).then(result => {
            afterSave()
          })
          .catch(err => {
            afterSave()
            setSaving(savingStatus.Error)
            console.log("Error Component Save", err.message)
          })
      }

      setSavingComponents(updatedComponents.length + newComponents.length + deletedComponents.length + reportUpdateCount)

      // update each component that was updated
      forEach(updatedComponents, (component) => {
        let patchList = convertComponentForMutation(component.draftSettings || undefined, component.type)

        let componentSettings:ComponentSettingsInput = {}
        set(componentSettings, lowerFirst(component.type || ""), excludePropertyArray(patchList, ["__typename", "timeSpan"]))

        const updateData = {
          id: component.id,
          patch: {
            name: component.name,
            settings: componentSettings
          }
        } as UpdateComponentInput

        updateComponentMutation({ variables: { input: updateData, liveView: false, draftView: true} })
          .then(result => {
            afterSave()
          })
          .catch(err => {
            afterSave()
            setSaving(savingStatus.Error)
            console.log("Error Component Save", err.message)
          })
      })

      // create each component that was new
      forEach(newComponents, (component) => {
        let patchList = convertComponentForMutation(component.draftSettings || undefined, component.type)

        let componentSettings:ComponentSettingsInput = {}
        set(componentSettings, lowerFirst(component.type || ""), excludePropertyArray(patchList, ["__typename", "timeSpan", "id"]))

        const newData = {
          name: component.name,
          type: component.type,
          settings: componentSettings,
        } as CreateComponentInput

        createComponentMutation({ variables: { input: newData, liveView: false, draftView: true} })
          .then(result => {
            setEditedDraftLayout((prevState) => {
              let newState = iassign(
                prevState,
                currentState => get(currentState, 'sections'),
                sectionsTable => {
                  let sections = cloneDeep(sectionsTable)
                  var selectedSection = findIndex(sections, (o) => {return !!find(o?.components, {id: component.id})})
                  if(selectedSection >= 0){
                    sections = iassign(
                      sections,
                      currentSection => get(currentSection, '['+selectedSection+']components'),
                      componentsTable => {
                        let components = cloneDeep(componentsTable)
                        const componentIndex = findIndex(components, {id: component.id})
                        if(componentIndex) components.splice(componentIndex, 1, result.data?.createComponent?.component)
                        return components
                      }
                    )
                  }
                  return sections
                }
              )
              return newState
            })
            afterSave()
          })
          .catch(err => {
            afterSave()
            setSaving(savingStatus.Error)
            console.log("Error Component Save", err.message)
          })
      })
      // TODO - delete or unlink component
    } else if (saving === savingStatus.StartOrder) {
      setSaving(savingStatus.UpdatingOrder)

      const layout = editedDraftLayout.sections?.map(section => {
        return {
          type: section?.type,
          components: section?.components?.map(component => component?.id) || []
        }
      })
      const baseType = usedInstance.type as ListMemberIdType
      const orderData = {
        listId: usedList?.id,
        memberId: get(usedInstance, `item.${typeIdMapping[baseType]}`, "")?.toString(),
        memberIdType: usedInstance.type,
        layout
      } as UpsertLayoutArgs

      upsertLayoutMutation({ variables: { input: orderData, liveView: false, draftView: true} })
        .then(result => {
          setSaving(savingStatus.Initial)
          setEditMode(false)
        })
        .catch(err => {
          setSaving(savingStatus.Error)
          setEditMode(false)
          console.log("Error Component Save", err.message)
        })
    }
  }

  const handleComponentSelect = ( inSelectedComponent: selectedComponentProp | undefined ) => {
    if(!isEqual(selectedComponent,inSelectedComponent)){
    // if(editMode && componentEdited){
    //   setModalVisible(true)
    //   setToSelectedComponentId(id)
    // } else {
      setSelectedComponent(inSelectedComponent)
      resetErrors()
    }
  }


  const guardModalScript: ModalScript = {
    header: "Unsaved Component Changes",
    body: "If you leave this unsaved component, changes will be lost.",
    leaveButtonContent: "Cancel",
    stayButtonContent: "Save Draft"
  }

  const handleEditNavbar = (value:string) => {
    if(editMode && componentEdited){
      setModalVisible(true)
      setToEditNavbar(value)
    } else {
      setEditNavbar(value)
    }
  }

  const handlePublishModal = (value:boolean) => {
    if(!value){
      setPublishStatus(PublishStatus.Start)
    }
    setPublishModalOpen(value)
  }

  if(!portfolioId){
    return (
      <Col>
        <ReportMainHolder
          auth={auth}
          view={view}
          publishModalOpen={publishModalOpen}
          setPublishModalOpen={(value:boolean) => handlePublishModal(value)}
          setRolloverModalOpen={(value: boolean) => setRolloverModalOpen(value)}
          publishStatus={publishStatus}
          editMode={editMode}
          setEditMode={handleEdit}
          saving={[savingStatus.StartUpdatingComponents, savingStatus.CreatingUpdatingComponents, savingStatus.StartOrder, savingStatus.UpdatingOrder].includes(saving)}
          handleSubmit={handleSubmit}
          user={user}
          list={list}
          portfolioId={portfolioId}
          clientId={clientId}
          reportId={reportId}
          componentEdited={componentEdited}
          exportReport={exportReport}
          exportLoading={exportLoading}
          hideFullExport={hideFullExport}
        >
          <Row>
            <Col>
              <h3>No Portfolio Selected</h3>
            </Col>
          </Row>
        </ReportMainHolder>
      </Col>
    );
  }

  if(!usedInstance){
    return (
      <Col>
        <ReportMainHolder
          auth={auth}
          view={view}
          publishModalOpen={publishModalOpen}
          setPublishModalOpen={(value:boolean) => handlePublishModal(value)}
          setRolloverModalOpen={(value: boolean) => setRolloverModalOpen(value)}
          publishStatus={publishStatus}
          editMode={editMode}
          setEditMode={handleEdit}
          saving={[savingStatus.StartUpdatingComponents, savingStatus.CreatingUpdatingComponents, savingStatus.StartOrder, savingStatus.UpdatingOrder].includes(saving)}
          handleSubmit={handleSubmit}
          user={user}
          list={list}
          portfolioId={portfolioId}
          clientId={clientId}
          reportId={reportId}
          componentEdited={componentEdited}
          exportReport={exportReport}
          exportLoading={exportLoading}
          hideFullExport={hideFullExport}
        >
          <Row>
            <Col>
              <h3>Selected Item is not a Portfolio</h3>
            </Col>
          </Row>
        </ReportMainHolder>
      </Col>
    );
  }

  if (usedList && usedInstance.item?.__typename === 'ClientPortfolio') {
    let layout:ClientPortfolioDetailLayoutFragment | undefined
    if(view === ReportDisplayType.Draft ){
      layout = editedDraftLayout
    } else {
      layout = editedDraftLayout
    }
    const publishReport = () => {
      setPublishStatus(PublishStatus.Publishing)
      let layouts:PublishLayoutArgs[] = []
      let componentIdsToPublish:number[] = []
      if(usedList){
        usedList.items?.forEach(item => {
          if(item.draftLayout && usedList){
            const baseType = item.type as ListMemberIdType
            layouts.push({listId: usedList.id, memberId: get(item, `item.${item.type && typeIdMapping[baseType]}`, "").toString(), memberIdType: item?.type || ListMemberIdType.portfolio_num})
            item.draftLayout.sections?.forEach(section => {
              componentIdsToPublish = componentIdsToPublish.concat(compact(section?.components?.map(component => component?.id)))
            })
          }
        });
      }
      const publishReportInput:PublishReportMutationVariables = {
        reportId,
        layouts: layouts,
        componentIds: componentIdsToPublish,
        liveView: false,
        draftView: true,
      }
      publishReportMutation({ variables: publishReportInput })
        .then(result => {
          setPublishCount((count) => count + 1)
          setPublishStatus(PublishStatus.Success)
        })
        .catch(err => {
          console.error("Error Publishing", err.message)
          setPublishStatus(PublishStatus.Error)
        })
    }
    return (
      <Col>
        <ReportAfterPublish
          layout={usedLayout}
          auth={auth}
          report={report}
          view={view}
          publishCount={publishCount}
          setEditedDraftLayout={setEditedDraftLayout}
          setReportState={setReportState}
          reportState={reportState}
          clientId={clientId}
        />
        <ReportMainHolder
          auth={auth}
          view={view}
          publishReport={publishReport}
          publishModalOpen={publishModalOpen}
          setPublishModalOpen={(value: boolean) => handlePublishModal(value)}
          setRolloverModalOpen={(value: boolean) => setRolloverModalOpen(value)}
          publishStatus={publishStatus}
          editMode={editMode}
          setEditMode={handleEdit}
          saving={[savingStatus.StartUpdatingComponents, savingStatus.CreatingUpdatingComponents, savingStatus.StartOrder, savingStatus.UpdatingOrder].includes(saving)}
          handleSubmit={handleSubmit}
          user={user}
          list={list}
          portfolioId={portfolioId}
          clientId={clientId}
          reportId={reportId}
          componentEdited={componentEdited}
          layout={layout}
          exportReport={exportReport}
          exportLoading={exportLoading}
          hideFullExport={hideFullExport}
        >
          <Row className="w-100">
            <Col md={view === ReportDisplayType.Draft ? 9 : 12}>
              {layout?.sections?.map((section, idx) => {
                if(section?.type && section?.components && layout){
                  return(
                    <ReportSection
                      key={idx}
                      type={section?.type}
                      components={
                        section?.components as ClientPortfolioDetailComponentFragment[]
                      }
                      view={view}
                      auth={auth}
                      selectedComponentId={selectedComponent}
                      setSelectedComponentId={handleComponentSelect}
                      editMode={editMode}
                      report={editedReport}
                      sectionNumber={idx}
                      setEditedDraftLayout={setEditedDraftLayout}
                      editedDraftLayout={layout}
                      setReportState={setReportState}
                      reportState={reportState}
                      hideSingleExport={hideSingleExport}
                      clientId={clientId}
                      portfolioId={portfolioId}
                    />
                  )
                }
                return <React.Fragment key={idx} />
              })}
              {!layout && <ReportError error="No components available at this time." />}
              {layout?.sections?.length === 0 && (
                <ReportError error="No components available at this time." />
              )}
              {editedDraftLayout && editMode &&
                <div className='mt-4'>
                  <ReportAddable
                    show={editMode}
                    sectionNumber={(layout?.sections?.length || 0) + 1}
                    componentNumber={-1}
                    report={report}
                    setEditedDraftLayout={setEditedDraftLayout}
                    setSelectedComponentId={handleComponentSelect}
                    editedDraftLayout={editedDraftLayout}
                  />
                </div>
              }
            </Col>
            {view === ReportDisplayType.Draft && (
              <Col md={3}>
                <div className="background-white client-report-edit-sidebar">
                  <ReportEdit
                    reportTabOrder={reportTabOrder}
                    setReportTabOrder={setReportTabOrder}
                    editedDraftLayout={editedDraftLayout}
                    selectedComponentId={selectedComponent}
                    setEditedDraftLayout={setEditedDraftLayout}
                    setSelectedComponentId={handleComponentSelect}
                    editNavbar={editNavbar}
                    setEditNavbar={handleEditNavbar}
                    portfolio={usedInstance.item}
                    clientId={clientId}
                    reportId={reportId}
                    editMode={editMode}
                    report={editedReport}
                    setReport={setEditedReport}
                    user={user}
                    list={list}
                    auth={auth}
                  />
                </div>
              </Col>
            )}
          </Row>
        </ReportMainHolder>
        <RolloverModal
          show={rolloverModalOpen}
          toggle={() => setRolloverModalOpen(!rolloverModalOpen)}
          fireRollover={rolloverReport}
        />
        <GuardModal
          open={modalVisible}
          script={guardModalScript}
          stay={() => {
            setModalVisible(false)
            setEditedDraftLayout(usedLayout)
            setSelectedComponent(toSelectedComponent)
            if(toEditNavbar) setEditNavbar(toEditNavbar)
            setToEditNavbar(undefined)
            resetErrors()
          }}
          leave={()=>{
            setModalVisible(false)
          }}
        />
      </Col>
    )
  }

  return (
    <Col>
      <div className="pane">
        <p>Data not returned</p>
      </div>
    </Col>
  );
}

type ReportMainHolderProps = {
  auth: Auth
  editMode: boolean
  setEditMode: (value:boolean) => void
  saving: boolean
  handleSubmit: () => void
  publishReport?: () => void
  publishStatus?: PublishStatus
  publishModalOpen: boolean
  setPublishModalOpen: (value:boolean) => void
  setRolloverModalOpen: (value:boolean) => void
  user?: MeFragment
  list: listReportExpanded[]
  portfolioId?: number
  clientId: number
  reportId: number
  view: ReportDisplayType
  componentEdited: boolean
  layout?: ClientPortfolioDetailLayoutFragment | undefined
  exportReport: () => void
  exportLoading: boolean
  hideFullExport?: boolean
}

const ReportMainHolder: React.FC<ReportMainHolderProps> = (props) => {
  const {auth, children, view, publishReport, publishModalOpen, setPublishModalOpen, setRolloverModalOpen, publishStatus, editMode, setEditMode, saving, handleSubmit, user, list, portfolioId, clientId, reportId, componentEdited, layout, exportReport, exportLoading} = props
  const {hideFullExport: hideExport} = props
  const history = useHistory()

  const orderedItems = recursivelyOrderReport(list)
  const allApproved = orderedItems.every((item) => {
    const sections = item.draftLayout?.sections?.every((section) =>
      section?.components?.every((component) => {
        return component?.approval?.code === ComponentApprovalCode._3
      })
    )
    return sections !== false
  })
  const missingComponents = !layout?.sections?.every((section) => {
    if(section?.type && SectionMapping[section.type]?.expectedCount) {
      const expectedCount = SectionMapping[section.type]?.expectedCount || 0
      const realComponents = compact(section.components).length
      return realComponents >= expectedCount
    }
    return true
  })

  let heading = <></>
  //<FontAwesomeIcon
  //icon={exportLoading ? "spinner-third" : ["fas", "file-download"]}
  //spin={exportLoading}
  //size="lg"
  //className="ml-2 text-callan-blue"
///>
  if(view === ReportDisplayType.Draft && auth.checkPermissions(["view:client_portfolios"])){
    heading = (
      <div className="pane pane-toolbar sticky-top above-picker">
        {view === ReportDisplayType.Draft &&
          <Button color="light btn-thin" className="mr-1 text-callan-blue" onClick={()=> window.open(`/clients/${clientId}/${reportId}/report/${portfolioId}`, '_blank')}>
            View Live Report
            <FontAwesomeIcon icon={"external-link"} className="ml-2" />
          </Button>
        }
        {auth.checkPermissions(["publish:report"]) && view === ReportDisplayType.Draft && publishReport &&
          <Button color="light btn-thin" className="mr-1 text-callan-blue" disabled={!allApproved} onClick={()=> allApproved ? setPublishModalOpen(true) : () => null}>
            Publish Report
          </Button>
        }
        { !hideExport && !editMode && view === ReportDisplayType.Draft && auth.checkPermissions(['export:report']) &&
          <Button color="light btn-thin" className="mr-1 text-callan-blue" onClick={()=> exportReport()}>
            Download PPTX
            {!exportLoading && <img src={"/assets/PPTX.svg"} className="ml-2 text-callan-blue"/>}
            {exportLoading && <FontAwesomeIcon icon="spinner-third" spin />}
          </Button>
        }
        { !editMode &&
          <div className='ml-2 pl-2 border-left'>
            <Button color="light btn-thin" className="ml-1 text-callan-blue" onClick={() => setRolloverModalOpen(true)}>
              <FontAwesomeIcon icon={["fal","calendar-alt"]} className="mr-2" />
              Rollover As-of Dates
              <FontAwesomeIcon icon="chevron-down" className="ml-2" />
            </Button>
          </div>
        }
        {view === ReportDisplayType.Draft &&
          <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} hideOnContext={true} saveText={"Save Draft"} disableOnError={true} disabled={missingComponents}/>
        }
        <Modal size="md" className="mt-5" isOpen={publishModalOpen} toggle={() => setPublishModalOpen(!publishModalOpen)} zIndex={1500}>
          <ModalHeader className="fee-modal-header">
            Publish Report
          </ModalHeader>
          <ModalBody>
            {(publishStatus === PublishStatus.Start || publishStatus === PublishStatus.Publishing) &&
              <>
                Publishing the report will update both the list and report for the client.
                <br/>
                This update cannot be undone.
              </>
            }
            {publishStatus === PublishStatus.Success && "Publish Successful"}
            {publishStatus === PublishStatus.Error && "Error Publishing"}
          </ModalBody>
          <ModalFooter>
            {publishStatus === PublishStatus.Start &&
              <>
                <Button onClick={() => setPublishModalOpen(false)} color="secondary" className="mr-1 ml-auto">Cancel</Button>
                <Button onClick={() => publishReport && publishReport()} color="primary" className="mr-1">Publish</Button>
              </>
            }
            {publishStatus === PublishStatus.Publishing &&
              <Button color="danger" className="mr-1">
                Publishing
                <FontAwesomeIcon icon="spinner-third" className="ml-2" spin/>
              </Button>
            }
            {publishStatus === PublishStatus.Success &&
              <Button onClick={() => setPublishModalOpen(false)} color="secondary" className="mr-1 ml-auto">Okay</Button>
            }
            {publishStatus === PublishStatus.Error &&
              <Button onClick={() => setPublishModalOpen(false)} color="secondary" className="mr-1 ml-auto">Cancel</Button>
            }
          </ModalFooter>
        </Modal>
      </div>
    )
  } else if (view === ReportDisplayType.Live && auth.checkPermissions(['export:report'])) {
    heading = (
      <div className="pane pane-toolbar sticky-top above-picker">
        {!hideExport && !editMode && auth.checkPermissions(['export:report']) &&
          <Button color="light btn-thin" className="mr-1 text-callan-blue" onClick={()=> exportReport()}>
            Download PPTX
            {!exportLoading && <img src={"/assets/PPTX.svg"} className="ml-2 text-callan-blue"/>}
            {exportLoading && <FontAwesomeIcon icon="spinner-third" spin />}
          </Button>
        }
      </div>
    )
  }
  return (
    <div className="client-report">
      <RouteLeavingGuard
        when={editMode && componentEdited}
        navigate={path => history.push(path)}
      />
      {heading}
      <div className="pane pane-report d-flex">
        <ReportSidebar
          list={list}
          portfolioId={portfolioId}
          clientId={clientId}
          reportId={reportId}
          history={history}
          auth={auth}
          user={user}
          view={view}
        />
        {children}
      </div>
    </div>
  )
}

interface ReportAfterPublishProps {
  layout?: ClientPortfolioDetailLayoutFragment
  auth: Auth
  report: ReportsFragment
  view: ReportDisplayType
  publishCount: number // increment this to force get after publish to run
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  setReportState: (value:React.SetStateAction<object>) => void
  reportState: object
  clientId: number
}

export const ReportAfterPublish: React.FC<ReportAfterPublishProps> = (props) => {
  const {layout, report, auth, view, publishCount, setEditedDraftLayout, setReportState, reportState, clientId} = props
  return(<>
    {layout?.sections?.map((section, sectionNum) =>{
      return section?.components?.map(
        (component, componentNum) => {
          if(component?.type && AfterPublishMapping[component?.type]){
            const element = AfterPublishMapping[component?.type]
            if(element){
              return React.createElement(element, {
                component: component,
                report: report,
                auth: auth,
                view: view,
                publishCount: publishCount,
                key: `component-${sectionNum}-${component?.id}`,
                sectionNumber: sectionNum,
                componentNumber: componentNum,
                editMode: false,
                setEditedDraftLayout,
                editedDraftLayout: layout,
                setReportState: setReportState,
                reportState: reportState,
                clientId: clientId,
              })
            }
          }
          return (<React.Fragment key={"C-" + componentNum} />)
        }
      )
    })}
  </>
  )

}

type ReportErrorProps = {
  error: string
}

// When errors happen show this component
export const ReportError: React.FC<ReportErrorProps> = ({error}) => {
  return (
    <div>
      <div className={classNames("pane pane-table pane-profile pane-missing-component mt-4 pb-3")}>
        <div className="w-100 py-4 d-flex align-items-center justify-content-center">
          <div>
            {error}
          </div>
        </div>
      </div>
      <div className={classNames("pane pane-table pane-profile pane-missing-component mt-4 pb-3")}>
        <div className="w-100 py-4 d-flex align-items-center justify-content-center">
          <div>
          </div>
        </div>
      </div>
      <div className={classNames("pane pane-table pane-profile pane-missing-component mt-4 pb-3")}>
        <div className="w-100 py-4 d-flex align-items-center justify-content-center">
          <div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface RolloverModalProps {
  show: boolean
  toggle: () => void
  fireRollover: () => void
}

const RolloverModal: React.FC<RolloverModalProps> = ({ show, toggle, fireRollover }) => (
  <Modal size="md" className="mt-5" isOpen={show} toggle={toggle} zIndex={1500}>
    <ModalHeader toggle={toggle}>Rollover Report</ModalHeader>
    <ModalBody>
      The as-of date for every component will be advanced to the next reporting period. The new as-of date will be relative to each component's current quarter and report's frequency
    </ModalBody>
    <ModalFooter>
      <Button color="secondary" onClick={toggle} className="mr-2">Cancel</Button>
      <Button color="primary" onClick={fireRollover}>Rollover As-of Dates</Button>
    </ModalFooter>
  </Modal>
)


interface ResetAsOfModalProps {
  show: boolean
  toggle: () => void
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editedDraftLayout: ClientPortfolioDetailLayoutFragment | undefined
}

const ResetAsOfModal: React.FC<ResetAsOfModalProps> = ({ show, toggle, setEditedDraftLayout, editedDraftLayout }) => {
  const [asOfDate, setAsOfDate] = useState<string>(get(first(first(editedDraftLayout?.sections)?.components)?.draftSettings, 'date') || '')
  const  updateAsOf = () => {
    setEditedDraftLayout((prevState) => {
      if(asOfDate){
        let oldState = cloneDeep(prevState || ({sections: [], __typename: "Layout"} as ClientPortfolioDetailLayoutFragment))
        if(!oldState?.sections) {
          set(oldState, "sections", [])
        }
        let newState = iassign(
          oldState,
          currentState => currentState?.sections,
          sectionsTable => {
            let sections = cloneDeep(sectionsTable || [])
            return sections?.map(section => {
              if(section){
                if(!section?.components) {
                  set(section, "components", [])
                }
                let newSection = iassign(
                  section,
                  currentSection => currentSection?.components,
                  components => {
                    let newComponents = cloneDeep(components)
                    return newComponents?.map(component => {
                      if(component){
                        if(!component?.draftSettings) {
                          set(component, "draftSettings", [])
                        }
                        let newComponent = iassign(
                          component,
                          currentComponent => currentComponent?.draftSettings,
                          settings => {
                            if(settings && "date" in settings && "monthlyOptions" in settings && settings.monthlyOptions){
                              return {...settings, date: asOfDate, monthlyOptions: {...settings.monthlyOptions, dates: [], __typename: settings.monthlyOptions.__typename}}
                            } else if (settings && "date" in settings){
                              return {...settings, date: asOfDate}
                            }
                            return settings
                          }
                        )
                        return {...newComponent, approval: {__typename: 'ComponentApprovalLookup' as 'ComponentApprovalLookup', code: ComponentApprovalCode._1, value: "Needs Review"}}
                      }
                      return component
                    })
                  })
                return newSection
              }
              return section
            })
          }
        )
        toggle()
        return newState
      }
    })
  }

  return(
    <Modal size="md" className="mt-5" isOpen={show} toggle={toggle} zIndex={1500}>
      <ModalHeader toggle={toggle}>Reset As-of Date for all Components</ModalHeader>
      <ModalBody className='modal-medium-height'>
        <FormInput
          idx={1}
          editMode={true}
          property="reset-date"
          label="As of date"
          type="date"
          subtype="quarter"
          required={true}
          propertyVal={asOfDate}
          updateValue={(value:string) => setAsOfDate(value)}
          subClasses={{inputClasses: "force-zindex", wrapperClasses: "no-gutters"}}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle} className="mr-2">Cancel</Button>
        <Button color="primary" onClick={updateAsOf}>Update</Button>
      </ModalFooter>
    </Modal>
  )
}


export default ReportMain