import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { first, groupBy, uniqBy } from "lodash"
import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { Redirect, Route, Switch, useHistory } from "react-router-dom"
import { Button, ButtonDropdown, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Nav, NavItem, NavLink, Row } from "reactstrap"
import Auth from "../../Auth/Auth"
import { FinancialSummaryPeriod, ReportsListFragment, SumSheetSavedJobFragment, useClientPortfolioSavedJobsQuery, usePlanPerformanceListQuery } from "../../__generated__/graphql"
import { expandReportList, listReportExpanded } from "../../helpers/report"
import { ReportDisplayType } from "../Report/Shared/ReportComponent"
import ReportSidebar from "../Report/Shared/ReportSidebar"
import RouteLeavingGuard from "../Shared/RouteLeavingGuard"
import EditButtons from "../ui/EditButtons"
import LoadingOverlay from "../ui/Loading"
import PlaceHolder from "../ui/PlaceHolder"
import { PlanPerformanceReturns, ReturnsHandle } from "./PlanPerformanceReturns"
import { CalendarPicker, CalendarPickerPeriod, CalendarPickerType } from "../CalendarPicker"
import { CalendarContext, appDate } from "../../Context/CalendarContext"
import { DATE_API_FORMAT } from "../../helpers/constant"
import { PlanPerformanceCashFlow } from "./PlanPerformanceCashFlow"
import { JobModal } from "../Report/SumSheet"
import React from "react"
import { AgGridContext } from "../../Context/AgGridContext"

interface PlanPerformanceMainProps {
  planId: number
  listId?: number
  auth: any
  match: any
}

export const PlanPerformanceMain: React.FC<PlanPerformanceMainProps> = ({ planId, auth, match, listId }) => {
  const { loading, error, data, refetch: _refetch } = usePlanPerformanceListQuery({
    variables: { id: listId },
    fetchPolicy: "no-cache",
  })

  const [search, setSearch] = useState("")

  const { month: inMonth } = useContext(CalendarContext)
  const [saving, setSaving] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [searchDate, setSearchDate] = useState(inMonth || appDate.format(DATE_API_FORMAT))
  const [selectedDataPeriod, setSelectedDataPeriod] = useState<CalendarPickerPeriod>("month")
  const [selectedType, setSelectedType] = useState<CalendarPickerType>("single")

  if(error) console.log(error)

  if(data && data.list){
    let showList:listReportExpanded[] | undefined = undefined
    showList = expandReportList(data.list as ReportsListFragment, {appendUnusedGroups: true, excludeList: first(data.list.excludeList)  as ReportsListFragment})
    return (
      <>
        <Switch>
          <Route
            exact
            path={`${match.url}/:portfolioId/:subtype?`}
            render={(params) =>
              <PlanPerformanceHolder
                list={showList || []}
                match={params.match}
                auth={auth}
                editMode={editMode}
                planId={planId}
                search={search}
                setSearch={setSearch}
                setEditMode={setEditMode}
                saving={saving}
                setSaving={setSaving}
                searchDate={searchDate}
                setSearchDate={setSearchDate}
                selectedDataPeriod={selectedDataPeriod}
                setSelectedDataPeriod={setSelectedDataPeriod}
                selectedType={selectedType}
                setSelectedType={setSelectedType}
              />}
            >
          </Route>
          <Redirect
            to={`${match.url}/${first(showList)?.id}/returns`}
          />
        </Switch>
      </>
    )
  }
  return <LoadingOverlay />
}

interface PlanPerformanceHolderProps {
  auth: Auth
  match: {params: {portfolioId?: string, subtype?: string}}
  planId: number
  list: listReportExpanded[]
  editMode: boolean
  search: string
  setSearch: (search: string) => void
  setSaving: (saving: boolean) => void
  searchDate: string
  setSearchDate: (searchDate: string) => void
  setEditMode: (editMode: boolean) => void
  selectedDataPeriod: CalendarPickerPeriod
  setSelectedDataPeriod: (period: CalendarPickerPeriod) => void
  saving: boolean
  selectedType: CalendarPickerType
  setSelectedType: (type: CalendarPickerType) => void
}

const PlanPerformanceHolder: React.FC<PlanPerformanceHolderProps> = (props) => {
  const { match, ...rest } = props
  const returnsRef = useRef<ReturnsHandle>(null)

  const portfolioId = match.params.portfolioId
  const subtype = match.params.subtype
  const { selectedDataPeriod, setSelectedDataPeriod } = props

  const matchedPortfolio = useMemo(() => {
    const getObject = (array:any, key:string, value:any) => {
      let o
      array.some(function iter(a:any) {
        if (a[key] === value) {
          o = a
          return true
        }
        return Array.isArray(a.subGroup) && a.subGroup.some(iter)
      })
      return o
    }
    return getObject(props.list, "id", parseInt(portfolioId || "")) as any as (listReportExpanded | undefined)
  }, [portfolioId, props.list])

  const handleSubmit = () => {
    returnsRef.current?.handleSubmit()
  }

  useEffect(() => {
    if(portfolioId && props.editMode){
      props.setEditMode(false)
    }
  }, [portfolioId])

  return (
    <Container fluid className="d-flex flex-grow-1 flex-direction-column px-0">
      <PlanPerformanceListHolder
        portfolioId={parseInt(portfolioId || "")}
        subtype={subtype}
        handleSubmit={handleSubmit}
        {...rest}
      >
        {portfolioId && (
          <PlanPerformanceDetail
            auth={props.auth}
            portfolioId={parseInt(portfolioId)}
            portfolioName={matchedPortfolio?.name}
            editMode={props.editMode}
            subtype={subtype}
            planId={props.planId}
            setEditMode={props.setEditMode}
            saving={props.saving}
            setSaving={props.setSaving}
            selectedDataPeriod={selectedDataPeriod}
            setSelectedDataPeriod={setSelectedDataPeriod}
            search={props.search}
            returnsRef={returnsRef}
            searchDate={props.searchDate}
            selectedType={props.selectedType}
          />
        )}
        {!portfolioId && (
          <PlaceHolder />
        )}
    </PlanPerformanceListHolder>
    </Container>
  )
}

interface PlanPerformanceListHolderProps {
  list: listReportExpanded[]
  auth: any
  portfolioId?: number
  editMode: boolean
  subtype?: string
  planId: number
  search: string
  setSearch: (search: string) => void
  setEditMode: (editMode: boolean) => void
  saving: boolean
  selectedDataPeriod: CalendarPickerPeriod
  setSelectedDataPeriod: (period: CalendarPickerPeriod) => void
  searchDate: string
  setSearchDate: (searchDate: string) => void
  handleSubmit: () => void
  selectedType: CalendarPickerType
  setSelectedType: (type: CalendarPickerType) => void
}

const PlanPerformanceListHolder: React.FC<PlanPerformanceListHolderProps> = (props) => {
  const { list, auth, portfolioId, editMode, children, planId, subtype, search, setSearch, setEditMode, saving, selectedDataPeriod, setSelectedDataPeriod, handleSubmit, searchDate, setSearchDate } = props
  const history = useHistory()
  const [periodSelectOpen, setPeriodSelectOpen] = useState(false)
  const [jobDropdownOpen, setJobDropdownOpen] = useState(false)
  const [selectedJobs, setSelectedJobs] = useState<SumSheetSavedJobFragment[] | null>(null)

  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  const { data } = useClientPortfolioSavedJobsQuery({
    variables: { id: portfolioId },
    fetchPolicy: "no-cache",
  })

  const jobTypeList = useMemo(() => {
    // https://callanllc.atlassian.net/browse/CAL-3420?focusedCommentId=90618
    let usedJobList = data?.clientPortfolio?.savedJobs?.filter((job) => job?.type?.code === "HELIX")
    return groupBy(uniqBy(usedJobList, (job) => job?.id), (job) => job?.type?.code) || {}
  }, [data])

  const agGridContext = useContext(AgGridContext)
  const exportToCsv = () => {
    const gridApi = agGridContext.agGrid
    if(!gridApi) return
    gridApi.exportDataAsCsv()
  }

  const heading = (
    <div className="pane pane-toolbar sticky-top">
      <Form className="mr-2 pr-1 border-right">
        <FormGroup row className="relative m-0 mr-1">
          <Input
            className="wide-search"
            type="text"
            placeholder="Search by name or ID"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            onKeyDown={handleEnterKeyDown}
          />
          <span className="o-88 absolute center-v right-1 pe-none">
            <FontAwesomeIcon icon={["fas", "search"]} size="2x" className="fontawesome-icon dark-icon-color text-gray-50" />
          </span>
        </FormGroup>
      </Form>
      <CalendarPicker
        updateValue={(searchDate) => setSearchDate(searchDate)}
        editMode={editMode}
        setEditMode={setEditMode}
        updatePeriod={setSelectedDataPeriod}
        hasMonthly
        singular
        hideDate={subtype === "returns"}
        hasHistorical={subtype === "cashflow"}
        defaultPeriod={selectedDataPeriod}
        updateType={props.setSelectedType}
      />
      {subtype === "cashflow" && (
        <Button onClick={() => null} color={"light"!} className="mx-1 btn-thin text-gray-70"> Import
        </Button>
      )}
      {subtype === "cashflow" && jobTypeList?.hasOwnProperty("HELIX") && (
        <Button onClick={() => setSelectedJobs(jobTypeList["HELIX"] as SumSheetSavedJobFragment[] | null)} className="mx-1 btn-thin text-callan-blue"> Run Helix
        </Button>
      )}
      {/* {subtype === "cashflow" && (
        <ButtonDropdown isOpen={jobDropdownOpen} toggle={() => setJobDropdownOpen(!jobDropdownOpen)} disabled={editMode} className={"ml-1 btn-thin"}>
          <DropdownToggle caret className={classNames("mt-0", {"text-gray-70 btn-light": editMode, "text-callan-blue border-blue-80": !editMode})}>
            Run Job
          </DropdownToggle>
          <DropdownMenu>
            {Object.keys(jobTypeList).map((jobType) => {
              let job = jobTypeList[jobType][0]
              if(!jobTypeList[jobType]) return <React.Fragment key={jobType}></React.Fragment>
              return(
                <DropdownItem key={jobType} onClick={() => setSelectedJobs(jobTypeList[jobType] as SumSheetSavedJobFragment[] | null)} className="d-flex background-white text-text-color position-relative">
                  <span className="ml-2 pl-1">{`${job?.type?.value}`}</span>
                </DropdownItem>
              )
            })}
            {Object.keys(jobTypeList).length === 0 && <DropdownItem disabled>No Jobs Available</DropdownItem>}
          </DropdownMenu>
        </ButtonDropdown>
      )} */}
      <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={exportToCsv}>
        Export CSV
        <img src='/assets/CSV.svg' className="ml-2"/>
      </Button>
      <JobModal
        isOpen={!!selectedJobs}
        toggle={() => setSelectedJobs(null)}
        jobs={selectedJobs || undefined}
        planId={planId}
        date={searchDate}
        autoRun={true}
      />
      <EditButtons editMode={editMode} setEditMode={setEditMode} saving={saving} onSubmit={handleSubmit} disableOnError={true} hideOnContext={true}/>
    </div>
  )

  return(
    <div className="client-report d-flex flex-grow-1 flex-direction-column px-3">
      {heading}
      <div className="flex-grow mb-4 d-flex">
        <div className="pane p-0 d-flex h-fit report-sidebar-scroll-holder">
          <ReportSidebar
            list={list}
            portfolioId={portfolioId}
            history={history}
            auth={auth}
            view={ReportDisplayType.External}
            externalLink={(listRow: listReportExpanded) => {
              if(listRow.unused) return
              history.push(`/plans/${planId}/performance/${listRow.id}/${subtype}`)
            }}
            startOpen
            search={search}
            holderClass="mr-0"
          />
        </div>
        {children}
      </div>
    </div>
  )
}

interface PlanPerformanceDetailProps {
  auth: any
  portfolioId: number
  portfolioName?: string
  editMode: boolean
  subtype?: string
  planId: number
  setEditMode: (editMode: boolean) => void
  saving: boolean
  setSaving: (editMode: boolean) => void
  selectedDataPeriod: CalendarPickerPeriod
  setSelectedDataPeriod: (period: CalendarPickerPeriod) => void
  search: string
  returnsRef: React.RefObject<ReturnsHandle>
  searchDate: string
  selectedType: CalendarPickerType
}

export const PlanPerformanceDetail: React.FC<PlanPerformanceDetailProps> = (props) => {
  const { auth, portfolioId, portfolioName, editMode, planId, subtype, setEditMode, saving, setSaving, selectedDataPeriod, setSelectedDataPeriod, search, returnsRef, searchDate, selectedType } = props

  const history = useHistory()
  const urlWithoutSubtype = `/plans/${planId}/performance/${portfolioId}`
  return (
    <div className="pl-1 pr-0 flex-grow">
      <div className="pane d-flex flex-direction-column h-100">
        <Row>
          <Col>
            <h2 className="headline d-inline-block">{ portfolioName }</h2>
            <FontAwesomeIcon icon={["fas", "external-link"]} className="text-blue-80 ml-2 cursor-pointer" onClick={() => window.open(`/clientportfolios/${portfolioId}/overview/` ,'_blank')} />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Nav
              className="sub-nav sub-nav-primary"
              tabs
              role="group"
            >
              <NavItem>
                <NavLink
                  className={classNames({
                    active: subtype === "returns"
                  })}
                  onClick={() => { history.push(`${urlWithoutSubtype}/returns`)}}
                >Returns</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classNames({
                    active: subtype === "cashflow"
                  })}
                  onClick={() => history.push(`${urlWithoutSubtype}/cashflow`)}
                >Cash Flow</NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        {subtype === "returns" && (
          <PlanPerformanceReturns
            auth={auth}
            portfolioId={portfolioId}
            editMode={editMode}
            subtype={subtype}
            planId={planId}
            saving={saving}
            selectedDataPeriod={selectedDataPeriod}
            setSelectedDataPeriod={setSelectedDataPeriod}
            search={search}
            returnsRef={returnsRef}
            setEditMode={setEditMode}
            setSaving={setSaving}
          />
        )}
        {subtype === "cashflow" && (
          <PlanPerformanceCashFlow
            auth={auth}
            portfolioId={portfolioId}
            editMode={editMode}
            subtype={subtype}
            planId={planId}
            saving={saving}
            selectedDataPeriod={selectedDataPeriod}
            setSelectedDataPeriod={setSelectedDataPeriod}
            search={search}
            returnsRef={returnsRef}
            setEditMode={setEditMode}
            setSaving={setSaving}
            selectedDate={searchDate}
            selectedType={selectedType}
          />
        )}
      </div>
    </div>
    )
}
