import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GridApi, GridReadyEvent, RowHeightParams } from '@ag-grid-community/core';
import { History, Location } from 'history'
import React, { useCallback, useContext, useState } from 'react'
import { match } from 'react-router-dom'
import { Button, Col, Container, Form, FormGroup, Input, Row, UncontrolledAlert } from 'reactstrap'

import Auth from '../../Auth/Auth'
import { FileFullDetailsFragment, JobStatusBasicFragment, useHideJobMutation, useJobStatusDetailsQueryLazyQuery, useMeDepartmentQuery, usePlanActivitiesQuery, useReRunJobMutation } from '../../__generated__/graphql'
import SortableTable from "../Shared/SortableTable"
import PlaceHolder from '../ui/PlaceHolder'
import { PlanActivitiesColumnDef, PlanActivitiesDetailCellRendererParams } from './PlanColumnDefs'
import { SortModelDirection, SortModelState } from '../ResearchProducts/FilterViewModal'
import { compact, max } from 'lodash'
import { TemporaryAlertContext } from '../../Context/TemporaryAlertContext'

type PlanActivitiesProps = {
  history: History
  location: Location
  auth: Auth
  match: match
  planId: number
}

export type documentTableData = {
  clientId: number
  reportId: number
  associations: string[]
} & FileFullDetailsFragment

const defaultSortModel: SortModelState = [{
  sort: SortModelDirection["asc"],
  colId: "finishDate",
  sortIndex: 1,
}]

const setRowHeight =  (params: RowHeightParams) => {
  if (params.node && params.node.detail && params.data?.args) {
    var offset = 80;
    const dataCount = max([params.data?.args?.length || 0, 4])

    var allDetailRowHeight = dataCount * params.api.getSizesForCurrentTheme().rowHeight;
    var gridSizes = params.api.getSizesForCurrentTheme();
    const result = allDetailRowHeight +
    ((gridSizes && gridSizes.headerHeight) || 0) +
    offset
    return result
  } else {
    return null
  }
}

const PlanActivitiesList: React.FC<PlanActivitiesProps> = ({ history, location, auth, match, planId }) => {
  const authAccess = auth.checkPermissions(["edit:lists"])
  if(!authAccess){
    history.replace('/')
  }

  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const [search, setSearch] = useState("")
  const [filterUpdates, setFilterUpdates] = useState(0)

  const { loading:userLoading, error:userError, data:userData } = useMeDepartmentQuery({ fetchPolicy: "cache-first" })
  const user = userData?.me || undefined

  const { loading, data, error, refetch } = usePlanActivitiesQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: planId },
    errorPolicy: "all",
  })

  const [reRunJob] = useReRunJobMutation()
  const [hideJob] = useHideJobMutation()
  const { addAlert } = useContext(TemporaryAlertContext)

  const [showHidden, setShowHidden] = useState<boolean>(false)

  const [getJobStatusDetails, {loading: jobStatusLoading, data: jobStatusData, error: jobStatusError}] = useJobStatusDetailsQueryLazyQuery({fetchPolicy: "network-only"})

  const getRowHeight = useCallback(
    setRowHeight,
    [],
  );
  const handleEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.key === 'Enter' && e.preventDefault()
  }

  const scheduleRefresh = (time: number) => {
    setTimeout(() => {
      refetch()
    }, time)
  }

  // work for multiple selected rows
  const reRunSelectedJobs = async () => {
    const ids = compact(gridApi?.getSelectedRows()?.map(row => row?.id))
    console.log("rerun", {ids})
    const successful: {id: number, newId:number}[] = []
    const failed: {id:number, message: string}[] = []
    const result = await Promise.all(ids.map(async (id) => {
      reRunJob({variables: {id}}).then((res) => {
        let newId = res.data?.reRunJob?.jobId
        if(newId) {
          successful.push({id, newId})
        }
      }).catch((err) => {
        let message = err?.message
        failed.push({id, message})
      })
    }))
    addAlert({title: "Success | Jobs Kicked Off", message: `Jobs are running.`, color: "userSuccess", timeout: 3000})

    console.log({successful, failed})
    if(result && failed.length){
      setTimeout(() => {
        addAlert({title: "Error | Jobs Rerun failed.", message: "Error: "+ failed.map(item => `id:${item.id}, ${item.message}`).join("\n"), color: "error", timeout: 3000})
      }, 3000)
      console.log("forceRefresh in fail")
      scheduleRefresh(6000)
    }else {
      console.log("forceRefresh in success")
      scheduleRefresh(3000)
    }
  }

  // work for multiple selected rows
  const hideSelectedJobs = async () => {
    const ids = compact(gridApi?.getSelectedRows()?.map(row => row?.id))
    console.log("hide", {ids})
    const successful: number[] = []
    const failed: {id:number, message: string}[] = []
    const result = await Promise.all(ids.map(async (id) => {
      let input = {id, hidden: true}
      hideJob({variables: {input}}).then((res) => {
        let newId = res.data?.hideJob?.id
        if(newId) {
          successful.push(id)
        }
      }).catch((err) => {
        let message = err?.message
        failed.push({id, message})
      })
    }))
    addAlert({title: "Success | Jobs Are hidden", message: `Jobs are hidden.`, color: "userSuccess", timeout: 3000})

    if(result && failed.length){
      setTimeout(() => {
        addAlert({title: "Error | Jobs Hide failed.", message: "Error: "+ failed.map(item => `id:${item.id}, ${item.message}`).join("\n"), color: "error", timeout: 3000})
      }, 3000)
      console.log("forceRefresh in failed")
      scheduleRefresh(6000)
    }else {
      console.log("forceRefresh in success")
      scheduleRefresh(3000)
    }
  }

  const unHideSelectedJobs = async () => {
    const ids = compact(gridApi?.getSelectedRows()?.map(row => row?.id))
    console.log("rerun", {ids})
    const successful: number[] = []
    const failed: {id:number, message: string}[] = []
    const result = await Promise.all(ids.map(async (id) => {
      let input = {id, hidden: false}
      hideJob({variables: {input}}).then((res) => {
        let newId = res.data?.hideJob?.id
        if(newId) {
          successful.push(id)
        }
      }).catch((err) => {
        let message = err?.message
        failed.push({id, message})
      })
    }))
    addAlert({title: "Success | Jobs Are Shown", message: `Jobs are hidden.`, color: "userSuccess", timeout: 3000})

    if(result && failed.length){
      setTimeout(() => {
        addAlert({title: "Error | Jobs Shown failed.", message: "Error: "+ failed.map(item => `id:${item.id}, ${item.message}`).join("\n"), color: "error", timeout: 3000})
      }, 3000)
      console.log("forceRefresh in failed")
      scheduleRefresh(6000)
    }else {
      console.log("forceRefresh in success")
      scheduleRefresh(3000)
    }
  }

  const toggleHideShow = () => {
    let filterModel = gridApi?.getFilterModel
    let {hide, ...rest} = filterModel as any
    
    if(showHidden){
      gridApi?.setFilterModel({...rest,
        "hide": {
          filterType: "set",
          values: ["false"],
        },
      })
      setShowHidden(false)
    }else {
      gridApi?.setFilterModel({
        ...rest,
        "hide": {
          filterType: "set",
          values: ["false", "true"],
        },
      })
      setShowHidden(true)
    }
  }

  const heading = (
    <>
      <div className="pane pane-toolbar sticky-top above-picker">
        <Form className="pr-1 border-right">
          <FormGroup row className="relative m-0 mr-0">
            <Input
              type="text"
              placeholder="Find Job"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
              className="wide-search"
              onKeyDown={handleEnterKeyDown}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon
                icon={["fas", "search"]}
                size="2x"
                className="fontawesome-icon dark-icon-color text-gray-50"
              />
            </span>
          </FormGroup>
        </Form>
        <div className="pl-2">
          <Button color="link" className="mr-2 text-callan-blue border-blue-80 btn-thin" onClick={reRunSelectedJobs} disabled={!data}>
            Rerun Job
          </Button>
          <Button color="link" className="mr-2 text-callan-blue border-blue-80 btn-thin" onClick={hideSelectedJobs} disabled={!data}>
            Hide
          </Button>
          {/* <Button color="link" className="mr-2 text-callan-blue border-blue-80 btn-thin" onClick={unHideSelectedJobs} disabled={!data}>
            Unhide
          </Button> */}
      </div>
      </div>
    </>
  )

  if ((loading) || userLoading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (user === null) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <UncontrolledAlert color="danger">
                <h4>Invalid User</h4>
              </UncontrolledAlert>
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (!data && (error || userError)) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane pane-table'>
              <p>{error?.message}</p>
              <p>{userError?.message}</p>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!loading && !!data && user) {
    let colDef = PlanActivitiesColumnDef(planId)
    let masterDetailsColDef = PlanActivitiesDetailCellRendererParams(getJobStatusDetails)
    const onReady = (event: GridReadyEvent) => {
      setGridApi(event.api)
      if(event?.columnApi) {
        event.columnApi.applyColumnState({ state: defaultSortModel })
      }
      event?.api?.setFilterModel({
        "hide": {
          filterType: "set",
          values: ["false"],
        },
      })
    }

    const result = data?.plan?.clientPortfolios
    const flattenedResult: JobStatusBasicFragment[] = compact(result?.map((portfolio) => portfolio?.jobStatus).flat()) || []
    return (
      <Container fluid className="d-flex flex-grow-1 flex-direction-column master-detail-grid">
        {heading}
        <SortableTable
          loading={loading}
          filterText={search}
          columnDefs={colDef}
          tableData={flattenedResult}
          rowId={"id"}
          rowClassRules={{
            "job-detail-row": (params:any) => params?.node?.detail
          }}
          onReady={onReady}
          onFilterChanged={() => setFilterUpdates(filterUpdates + 1)}
          masterDetail={true}
          masterDetailSetting={{
            detailCellRendererParams: masterDetailsColDef,
          }}
          groupDefaultExpandedOverride={0}
          getRowHeight={getRowHeight}
        />
      </Container>
    )
  }
  return <div>data doesn't exist.</div>
}



export default PlanActivitiesList
