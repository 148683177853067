import gql from "graphql-tag"
import {
  REPORT_SETTINGS_LIST_FRAGMENT,
} from "./ListFragments"

export const REPORT_COLUMN_DATA_FRAGMENT = gql`
  fragment ReportColumnDataFragment on DataPoint {
    rawValue
    formattedValue
    status
    additionalAttributes {
      type
      intValue
      floatValue
      stringValue
    }
  }
`

export const REPORT_MANAGER_PERFORMANCE_FETCH_FRAGMENT = gql`
  fragment ReportManagerPerformanceFetchFragment on ManagerPerformance {
    list {
      ...ReportSettingsListFragment
    }
    date
    performancePeriodicity
    actualPerformance
    performanceType
    managerPerformancePeriod: period {
      lastMonth
      lastQuarter
      yearToDate
      last1Year
      last2Years
      last3Years
      last5Years
      last7Years
      last10Years
      last15Years
      last20Years
      sinceInception
      fiscalYear
    }
    showFootnote
    showTargetFootnote
    totalFundPid
    monthlyOptions {
      show
      dates
    }
    fiscalYear
    rankings
    groupMedians
    reportingPerformancePeriod
    numberOfYearsToInclude
  }
  ${REPORT_SETTINGS_LIST_FRAGMENT}
`

export const REPORT_MANAGER_PERFORMANCE_BASE_DATA_ROW_FRAGMENT = gql`
  fragment ReportManagerPerformanceBaseDataRowFragment on ManagerPerformanceRow {
    rowId: id
    name
    total
    feeType
    showFeeBadge
    productId
    group
    header
    warnings {
      period
      messages
    }
    errors {
      period
      messages
    }
    footnote {
      name
      note
    }
    columnData {
      ...ReportColumnDataFragment
    }
  }
  ${REPORT_COLUMN_DATA_FRAGMENT}
`

export const REPORT_MANAGER_PERFORMANCE_DATA_ROW_FRAGMENT = gql`
  fragment ReportManagerPerformanceDataRowFragment on ManagerPerformanceRow {
    ...ReportManagerPerformanceBaseDataRowFragment
    benchmarks {
      ...ReportManagerPerformanceBaseDataRowFragment
    }
  }
  ${REPORT_MANAGER_PERFORMANCE_BASE_DATA_ROW_FRAGMENT}
`

export const REPORT_MANAGER_PERFORMANCE_DATA_FRAGMENT = gql`
  fragment ReportManagerPerformanceDataFragment on ManagerPerformanceData {
    orderId
    columns {
      text
      tooltip
    }
    rows {
      ...ReportManagerPerformanceDataRowFragment
      children {
        ...ReportManagerPerformanceDataRowFragment
        children {
          ...ReportManagerPerformanceDataRowFragment
          children {
            ...ReportManagerPerformanceDataRowFragment
          }
        }
      }
    }
  }
  ${REPORT_MANAGER_PERFORMANCE_DATA_ROW_FRAGMENT}
`
