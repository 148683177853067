import gql, { disableFragmentWarnings } from 'graphql-tag'
import { CLIENT_PORTFOLIO_BASIC_FRAGMENT } from './ClientPortfolioFragments'
import { FOOTNOTE_DETAIL_FRAGMENT } from './FootnoteFragments'
import { FILE_FULL_DETAILS_FRAGMENT } from './Fragments'
import { CASH_FLOW_TRANSACTION_FRAGMENT } from './ReportFragments'

disableFragmentWarnings()

export const PLAN_SIMPLE_FRAGMENT = gql`
  fragment PlanSimpleFragment on Plan {
    id
    name
    client {
      id
      name
    }
    fundType {
      code
      value
    }
    fundSubtype {
      code
      value
    }
    isComposite
    consultant {
      id
      firstName
      lastName
    }
    assets {
      date
      value
    }
    sponsorType {
      code
      value
    }
    accountType {
      code
      value
    }
  }
`

export const PLAN_DOCUMENTS_FRAGMENT = gql`
  fragment PlanDocumentsFragment on Plan {
    id
    documents {
      ...FileFullDetailsFragment
    }
  }
  ${FILE_FULL_DETAILS_FRAGMENT}
`

export const PLAN_BASICS_FRAGMENT = gql`
  fragment PlanBasicsFragment on Plan {
    id
    name
  }
`

export const PLAN_CLIENT_PORTFOLIO_FRAGMENT = gql`
  fragment PlanClientPortfolioFragment on ClientPortfolio {
    id
    name
    isActive
    dataType {
      code
      value
    }
  }
`

export const PLAN_DETAILS_FRAGMENT = gql`
  fragment PlanDetailsFragment on Plan {
    id
    name
    shortName
    revalueTolerance
    fundType {
      code
      value
    }
    fundSubtype {
      code
      value
    }
    fiscalMonth
    custodian {
      id
      name
    }
    recordKeeper {
      id
      name
    }
    isComposite
    plans {
      id
      name
      isComposite
    }
    consultant {
      id
      firstName
      lastName
    }
    backupConsultant {
      id
      firstName
      lastName
    }
    client {
      id
      name
      address {
        street
        city
        state
        zip
      }
      ... on Client {
        plans {
          id
          name
          shortName
        }
      }
    }
    serviceInfo {
      serviceStartDate
      lengthOfService
      relationship
      terminationDate
      reasonForTermination
    }
    confidential
    discretionary {
      code
      value
    }
    footnotes {
      ...FootnoteDetailFragment
    }
    accountType {
      code
    }
    report {
      id
      draftList {
        id
      }
    }
  }
  ${FOOTNOTE_DETAIL_FRAGMENT}
`

// For baseUrl/plans/portfolios only
export const PLAN_PORTFOLIO_LIST_FRAGMENT = gql`
  fragment PlanPortfolioListFragment on Plan {
    id
    name
    clientPortfolios {
      ...ClientPortfolioBasicFragment
    }
  }
  ${CLIENT_PORTFOLIO_BASIC_FRAGMENT}
`

export const PLAN_PORTFOLIOS_FRAGMENT = gql`
  fragment PlanPortfoliosFragment on Plan {
    id
    name
    shortName
    clientPortfolios {
      ...PlanClientPortfolioFragment
    }
    clientPortfolios @include(if: $includeTargetsAndGroup) {
      performanceTargetMap {
        target {
          targetId: id
          name
        }
      }
      styleGroup {
        id
        shortName
      }
      plan {
        id
      }
    }
  }
  ${PLAN_CLIENT_PORTFOLIO_FRAGMENT}
`

const MANAGER_DOCUMENT_FRAGMENT = gql`
  fragment ManagerDocumentFragment on File {
    ...FileFullDetailsFragment
    managers {
      id
      name
    }
    products {
      product {
        id
        name
      }
    }
  }
  ${FILE_FULL_DETAILS_FRAGMENT}
`

export const PLAN_MANAGER_DOCUMENTS_FRAGMENT = gql`
  fragment PlanManagerDocumentsFragment on Plan {
    id
    managerDocuments {
      ...ManagerDocumentFragment
    }
  }
  ${MANAGER_DOCUMENT_FRAGMENT}
`

export const PLAN_PERFORMANCE_LIST_FRAGMENT = gql`
  fragment PlanPerformanceListFragment on List {
    id
    items {
      group
      order
      item {
        ... on ListGroup {
          id
        }
        ... on ListHeader {
          id
          text
        }
        ... on ClientPortfolio {
          id
          name
        }
      }
    }
  }
`

export const CLIENT_PORTFOLIO_FINANCIAL_SUMMARY_FRAGMENT = gql`
  fragment ClientPortfolioFinancialSummaryFragment on ClientPortfolioFinancialSummary {
    assets
    date
    editable
    fee
    income
    purchases
    returnPercent
    sales
    type {
      code
      value
    }
    origin {
      code
      value
    }
    updated
    timingFactorPurchases
    timingFactorSales
    wealthsAvailable
  }
`

export const PLAN_PERFORMANCE_RETURNS_FRAGMENT = gql`
  fragment PlanPerformanceReturnsFragment on ClientPortfolio {
    id
    name
    plan {
      id
    }
    quarterlyGrossPerformance: performance(
      filters: {
        actual: true
        period: { type: Quarterly }
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
      }
    ) {
      endDate
      value {
        active
      }
    }
    quarterlyNetPerformance: performance(
      filters: {
        actual: true
        period: { type: Quarterly }
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
      }
    ) {
      endDate
      value {
        active
      }
    }
    monthlyGrossPerformance: performance(
      filters: {
        actual: true
        period: { type: Monthly }
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALL }
      }
    ) {
      endDate
      value {
        active
      }
    }
    monthlyNetPerformance: performance(
      filters: {
        actual: true
        period: { type: Monthly }
        assetClassGroupFilter: { assetClassGroupAbbreviation: ALLN }
      }
    ) {
      endDate
      value {
        active
      }
    }
    financialSummaryMonthly: financialSummary(filters: { period: Monthly }) {
      ...ClientPortfolioFinancialSummaryFragment
    }
    financialSummaryQuarterly: financialSummary(
      filters: { period: Quarterly }
    ) {
      ...ClientPortfolioFinancialSummaryFragment
    }
  }
  ${CLIENT_PORTFOLIO_FINANCIAL_SUMMARY_FRAGMENT}
`

export const PLAN_PERFORMANCE_FILTERED_CASH_FLOWS_FRAGMENT = gql`
  fragment PlanPerformanceFilteredCashFlowsFragment on CashFlows {
    endingNetAssetValue {
      amount
      date
    }
    beginningNetAssetValue {
      amount
      date
    }
    cashFlowTransactionTypes {
      netValue
      transactionType {
        code
        value
      }
      transactions {
        ...CashFlowTransactionFragment
      }
    }
    marketValues {
      amount
      accruedInterests
      date
      assetClassAbbreviation
      updateDate
      originName
      country {
        code
        value
      }
      currency {
        code
        value
      }
    }
  }
  ${CASH_FLOW_TRANSACTION_FRAGMENT}
`

export const PLAN_PERFORMANCE_CASH_FLOW_FRAGMENT = gql`
  fragment PlanPerformanceCashFlowFragment on ClientPortfolio {
    id
    name
    baseCurrency {
      code
    }
    dataType {
      code
    }
    monthCashFlows: filteredCashFlows(
      filter: {
        period: {
          dateToDateEnd: $date
          type: TrailingNumberOfMonths
          trailingNumberOfMonths: 1
        }
        useCurrency: true
      }
    ) @include(if: $showMonth) {
      ...PlanPerformanceFilteredCashFlowsFragment
    }
    quarterCashFlows: filteredCashFlows(
      filter: {
        period: {
          dateToDateEnd: $date
          type: TrailingNumberOfQuarters
          trailingNumberOfQuarters: 1
        }
        useCurrency: true
      }
    ) @include(if: $showQuarter) {
      ...PlanPerformanceFilteredCashFlowsFragment
    }
    historicalCashFlows: filteredCashFlows(
      filter: {
        period: {
          dateToDateEnd: $date
          type: SinceInception
        }
        useCurrency: true
      }
    ) @include(if: $showHistorical) {
      ...PlanPerformanceFilteredCashFlowsFragment
    }
    cashFlowDefaultKeys {
      assetClassAbbreviation
      country
      currency
    }
  }
  ${PLAN_PERFORMANCE_FILTERED_CASH_FLOWS_FRAGMENT}
`

export const PLAN_PERFORMANCE_DAILY_WEALTH_FRAGMENT = gql`
  fragment PlanPerformanceDailyWealthFragment on DailyWealth {
    cashFlow
    closeMarketValue
    cumulativeReturn
    dailyReturn
    date
    hideDate
    openMarketValue
    tradeDay
    weightedCashFlow
  }
`

export const PLAN_PERFORMANCE_WEALTH_FRAGMENT = gql`
  fragment PlanPerformanceWealthFragment on ClientPortfolio {
    id
    name
    dailyWealths(filters: {
      period: $period
      periodDate: $date
      returnType: $returnType
    }) {
      ...PlanPerformanceDailyWealthFragment
    }
  }
  ${PLAN_PERFORMANCE_DAILY_WEALTH_FRAGMENT}
`