import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classnames from "classnames"
import _, { cloneDeep, find, first, get, isNull, isNumber, some, sortBy, uniq } from "lodash"
import moment from "moment"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Button, ButtonDropdown, Col, Container, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import Auth from "../../../Auth/Auth"
import { appDate } from "../../../Context/CalendarContext"
import { TemporaryAlertContext } from "../../../Context/TemporaryAlertContext"
import { DATE_API_FORMAT, FormInputField } from "../../../helpers/constant"
import { getNewStateObject } from "../../../helpers/helpers"
import { AvailableJobFragment, CreateSavedJobInput, Exact, JobName, Maybe, PortfolioJobAdditionalArgFragment, PortfolioJobsSettingsQuery, PortfolioPeriod, RunAdHocJobInput, SavedJob, SavedJobAdditionalArgFragment, SavedJobDataType, SavedJobFragment, SavedJobSideBarFragment, UpdateSavedJob, UpdateSavedJobInput, useAvailableJobsQueryLazyQuery, useCreateSavedJobMutation, useDeleteSavedJobMutation, usePortfolioJobsSettingsQuery, useRunAdHocJobMutation, useRunSavedJobMutation, useSavedJobDetailsQueryLazyQuery, useUpdateSavedJobMutation } from "../../../__generated__/graphql"
import { MultiFilter } from "../../Org/ManagerInteractions"
import ErrorDisplay from "../../Shared/ErrorDisplay"
import { Filter, MultiplePicker, OptionItem, Picker } from "../../Shared/Picker"
import RouteLeavingGuard from "../../Shared/RouteLeavingGuard"
import EditButtons from "../../ui/EditButtons"
import { FormInput, InfoTipProps } from "../../ui/Forms/FormInput"
import Loading from "../../ui/Loading"
import { GetLookupDataToOptions } from "../../ui/LookupOptions"
import PlaceHolder from "../../ui/PlaceHolder"
import { ApolloQueryResult } from "@apollo/client"

interface JobSettingInputProp extends FormInputField {}

type AdditionalArgState = (SavedJobAdditionalArgFragment & {description?: string, defaultValue?: any}) | PortfolioJobAdditionalArgFragment

type AdditionalArgsState = AdditionalArgState[]

type ArgsComponentProps = {
  state: AdditionalArgsState
  // TODO: test for now
  jobId?: any
  editMode: boolean
  handleChange?: (value: any, field: any) => void
  setState: (value: AdditionalArgsState) => void
  // additional params from parent, for e.g. PORTFOLIO_NUM reference.
  params?: any
  modalStyle?: boolean
  type?: JobName
  defaultAdditionalArgs?: PortfolioJobAdditionalArgFragment[] // used for mutation, get additionalArgs description & default value.
}

const SavedJobDataTypeToInputType = (dataType: SavedJobDataType) => {
  let type = "text"
  let subtype = ""
  let subClasses = {wrapperClasses: "ml-1 pl-2 pb-1", inputWrapperClasses: "col-sm-6 pl-3", inputClasses: "pl-1"}

  let infoTip: any = {
    parentElement: "input",
    placement: "top",
    enabledInViewMode: false
  }

  switch (dataType) {
    case "INT":
      type = "number"
      subClasses = {...subClasses, inputClasses: "pl-1 text-left"}
      break
    case "FLOAT":
      type = "float"
      subClasses = {...subClasses, inputWrapperClasses: "col-sm-6 pl-3 text-left"}
      break
    case "DATE":
      type = "date"
      subClasses = {...subClasses, inputClasses: "text-nowrap"}
      break
    case "BOOLEAN":
      type = "checkbox"
      subtype = "boolean"
      infoTip.parentElement = "div"
      infoTip.placement= "right"
      subClasses = {...subClasses, inputWrapperClasses: "col-sm-6 pl-1 ml-1", inputClasses: ""}
      break
    case "STRING":
    case "INDEX_ID":
    case "VEHICLE_ID":
      type = "text"
      subClasses = {...subClasses, inputWrapperClasses: "col-sm-6 pl-3 pr-1"}
      break
    case "PORTFOLIO_ID":
      type = "number"
      subClasses = {...subClasses, inputClasses: "pl-1 text-left"}
      break
    default:
      subClasses = {...subClasses, inputWrapperClasses: "col-sm-6 pl-3 pr-1"}
      break
  }
  return {type, subtype, subClasses, infoTip}
}

// argType, dataType, name, value
const getInitialArgs = (state: AdditionalArgsState, params: any, defaultAdditionalArgs: PortfolioJobAdditionalArgFragment[] = [], type?: JobName) => {
  // make sure the order doesn't change with value change.
  let result: AdditionalArgsState = state
  if(!defaultAdditionalArgs?.length){
    return result
  }

  let args: AdditionalArgsState = defaultAdditionalArgs.map(arg => {
    let stateArg = state.find((stateArg) => stateArg.argType === arg.argType)
    let defaultValue = arg?.defaultValue || ""
    if(type) {
      let findDefault = DEFAULT_PARAMS.find(param => param.argType === stateArg?.argType && param.type.includes(type))
    // hard-code. https://callanllc.atlassian.net/browse/CAL-2737
      defaultValue = findDefault?.defaultValue.replace("<PORTFOLIO_NUM>", params?.portfolioId?.toString()) || defaultValue
    }
    if(stateArg) {
      return {...arg, defaultValue: defaultValue, description: arg?.description || "", value: stateArg?.value}
    }else {
      return {...arg, defaultValue: defaultValue, description: arg?.description || "", value: null}
    }
  })
  return args
}

// additional args, unique to each job type.
const ArgsComponent : React.FC<ArgsComponentProps>= (props) => {
  let { state, editMode, defaultAdditionalArgs = [], setState, params, modalStyle = false, type } = props
  const [args, setArgs] = useState<AdditionalArgsState>(() => getInitialArgs(state, params, defaultAdditionalArgs, type))

  useEffect(() => {
    let newArgs = getInitialArgs(state, params, defaultAdditionalArgs, type)
    if(!editMode) {
      newArgs = getInitialArgs(state, params, [], type)
    }
    setArgs(newArgs)
  }, [editMode, state, params, defaultAdditionalArgs])

  // format value for storage
  const onChangeByDataType = (value: any, argType: any, dataType: SavedJobDataType) => {
    let result = value
    switch (dataType) {
      case "BOOLEAN":
        result = (value === "true" || value === true) ? "Y": "N"
        break
      case "STRING":
      case "INT":
      case "FLOAT":
      case "DATE":
      default:
        if(result === "") {
          result = null
        }
        break
    }
    return result
  }

  // format value for display
  const formatValueByDataType = (value: any, argType: any, dataType: SavedJobDataType) => {
    let result = value
    switch (dataType) {
      case "BOOLEAN":
        result = (value === "Y") ? "true": (value === "N")? "false": null
        break
      case "INT":
      case "FLOAT":
      case "DATE":
      case "STRING":
      default:
        break
    }
    return result
  }
  return (
    <div className="pt-1 mt-1">
      {args?.map((arg, idx) => {
        let {argType, dataType = SavedJobDataType.STRING, name, value, description} = arg
        let {type, subtype, subClasses, infoTip: infoTipStyle} = SavedJobDataTypeToInputType(dataType as SavedJobDataType)
        let readonly = false
        let required = false

        let infoTip: InfoTipProps| undefined = description? {title: description, ...infoTipStyle}: undefined

        let formattedValue = formatValueByDataType(value, argType, dataType as SavedJobDataType)
        let formattedDefaultValue = formatValueByDataType(arg?.defaultValue, argType, dataType as SavedJobDataType)
        let onChangeCallback = (value: any) => {
          let formattedVal = onChangeByDataType(value, argType, dataType as SavedJobDataType)

          // keep the order as it is.
          let newArgs = args?.map((newArg, newArgIdx) => {
            if(newArg?.argType === argType && newArgIdx === idx) {
              return {...newArg, value: formattedVal}
            }
            return newArg
          })
          setState(newArgs)
        }

        // https://callanllc.atlassian.net/browse/CAL-2737?focusedCommentId=89609
        if(props?.type === "COMPOST") {
          if(argType && ["LIST_ID", "FUND_NUM"].includes(argType)){
            readonly = true
          }else if(argType === "STORE") {
            required = true
          }
        }
        return (
          <FormInput
            key={`${argType}-${dataType}`}
            property={`${argType}-${dataType}` || ""}
            // temp fix for NET_DOWN, null name for some args.
            displayName={name || argType || ""}
            placeholder={formattedDefaultValue}
            type={type}
            subtype={subtype}
            idx={`runtime-job-arg-${idx}-${modalStyle}`}
            editMode={editMode}
            propertyVal={formattedValue}
            updateValue={onChangeCallback}
            subClasses={subClasses}
            infoTip={infoTip}
            noFormat={type ==="number" || false}
            readonly={readonly}
            required={required}
          />
        )
      })}
    </div>
  )
}

type PeriodComponentProps = {
  setState: React.Dispatch<React.SetStateAction<RunTimeModalState>>
  state: RunTimeModalState
  type: SavedOrAdHoc
  periodTypeEditable?: boolean
  settings?: {[key: string]: boolean}
  editMode?: boolean
  modalStyle?: boolean
}

const isShiftEndDateEnabled = (state: RunTimeModalState) => {
  const shiftEndDateEnabled = !!state?.lagPeriods
  return shiftEndDateEnabled
}

const PeriodComponent: React.FC<PeriodComponentProps> = (props) => {
  let { state, setState, type, settings, periodTypeEditable = false, editMode = false, modalStyle = false } = props
  const [isMonth, setIsMonthFlag] = useState(() => isMonthType(state?.periodType))

  useEffect(() => {
    setIsMonthFlag(isMonthType(state?.periodType))
  }, [state?.periodType])

  let isTrail = !state?.condition?.code || state?.condition?.code === "1"
  let text = isTrail? isMonth? "months from": "quarters from": " end on"
  const showEndDate = !settings?.sumSheetRules && type === SavedOrAdHoc.adhoc
  if(!showEndDate) {
    if(isTrail) {
      text = isMonth? "months": "quarters"
    }else {
      text = ""
    }
  }
  const showFootnote = !settings?.sumSheetRules && settings?.showFootNote !== false
  let periodCount = state?.periodCount || 0
  let startDate = isTrail? (moment(state?.endDate).subtract(periodCount * (isMonth? 1: 3), "month")): moment(state?.startDate)
  // if trail, use endDate and periodCount, if start, use startDate and endDate.
  const startDateDisplay = moment(startDate).format(isMonth? monthFormat: quarterFormat)

  const endDateDisplay =  moment(state?.endDate).format(isMonth? monthFormat: quarterFormat)
  const footNote = isTrail? (<>Job will trail {periodCount || 0} {isMonth? "month(s)": "quarter(s)"} including {endDateDisplay}</>): (<>Job will start on {startDateDisplay} (and include) {endDateDisplay}</>)

  const periodTypeComponent = () => {
    const periodTypeInput =(
      <FormInput
        key={"periodType"}
        property={"periodType"}
        displayName={""}
        type={"select"}
        subtype={"single"}
        idx={`runtime-job-periodType-${modalStyle}`}
        editMode={editMode && periodTypeEditable}
        propertyVal={get(state, "periodType", "")}
        updateValue={(value: any) => {
          setState({...state, periodType: value})
        }}
        options={PeriodTypeOptions()}
        subClasses={{inputClasses: "fw-bold", wrapperClasses: "pl-0 pr-2"}}
      />
    )
    
    return periodTypeEditable ? periodTypeInput: (<b className="px-1">{(state?.periodType || "Unknown period type").toLocaleLowerCase()}</b>)
  }
  const shiftComponent = () => {
    const isShiftEnabled = isShiftEndDateEnabled(state)
    return (<div key="run-time-shift" className={classnames("d-flex align-items-center mt-1 pt-1 mb-1", {"col-sm-9 pl-0": !modalStyle})}>
      {/* isShiftEnabled */}
      <FormInput
        key={"shift"}
        property={"lagPeriods.isNotZero"}
        displayName={""}
        type={"checkbox"}
        subtype={"boolean"}
        idx={`runtime-job-shift-Enabled-${modalStyle}`}
        editMode={editMode}
        propertyVal={isShiftEnabled}
        updateValue={(value: any) => {
          if(value) {
            if(!state?.lagPeriods) {
              setState({...state, lagPeriods: 1})
            }
          }else {
            setState({...state, lagPeriods: null})
          }
        }}
        subClasses={{wrapperClasses: "pl-0", inputWrapperClasses: "col-sm-12", inputClasses: "ml-0"}}
      />
      {"Shift end date back "}
      {isShiftEnabled && "by "}
      {isShiftEnabled &&
        <FormInput
          key={"lagPeriods"}
          property={"lagPeriods"}
          displayName={""}
          type={"number"}
          idx={`runtime-job-lagPeriods-${modalStyle}`}
          editMode={editMode}
          propertyVal={get(state, "lagPeriods", 0)
          }
          updateValue={(value: any) => {
            setState({...state, lagPeriods: value})
            }
          }
          subClasses={{wrapperClasses: "pl-2 pr-2", inputClasses: "text-center narrow-number", inputWrapperClasses: "d-inline w-auto px-1 ml-1"}}
        />
      }
      {isShiftEnabled && <div className="pl-1">{` `}{isMonth? "months": "quarters"}</div>}
    </div>)
  }
  const frontText = !settings?.sumSheetRules? <>Calculate {periodTypeComponent()} totals with the following conditions:</>: <>When running from the Sumsheet, use the following conditions:</>

  const showShiftComponent = !!settings?.sumSheetRules || type === SavedOrAdHoc.saved

  return (
  <div className="pl-2 ml-1 pt-2">
    <div className="d-flex align-items-center mb-1">
      {frontText}
    </div>
    <div key="run-time-period" className={classnames("d-flex align-items-center mt-1 pt-1 mb-1", {"col-sm-9 pl-0": !modalStyle})}>
      {/* trail/ start on */}
      <FormInput
        key={"condition"}
        property={"condition.code"}
        displayName={""}
        type={"select"}
        subtype={"single"}
        idx={`runtime-job-condition-${modalStyle}`}
        editMode={editMode}
        propertyVal={get(state, "condition.code", "")}
        updateValue={(value: any) => {
          // trail
          if(value === "1") {
            setState({...state, condition: {code: value}, periodCount: state?.periodCount || 1, startDate: null})
          }else {
            setState({...state, condition: {code: value}, periodCount: null, startDate: moment(appDate).format(DATE_API_FORMAT)})
          
          }
        }}
        options={ConditionOptions()}
        subClasses={{wrapperClasses: "pr-0 mr-2", inputWrapperClasses: "col-sm-12 pl-1 ml-2 pr-1 mr-1", inputClasses: "ml-1 pl-0"}}
      />
      {!isTrail &&
        <FormInput
          key={"startDate"}
          property={"startDate"}
          displayName={""}
          type={"date"}
          subtype={isMonth ? "monthText": "quarter"}
          idx={`runtime-job-startDate-${modalStyle}`}
          editMode={editMode}
          propertyVal={get(state, "startDate", "")
          }
          updateValue={(value: any) => {
            setState({...state, startDate: value})
          }}
          subClasses={{inputClasses: "force-zindex", wrapperClasses: "no-gutters ml-2 mr-0"}}
        />
      }
      {isTrail && <FormInput
        key={"number"}
        property={"trail-periodCount"}
        displayName={""}
        type={"number"}
        noFormat={true}
        nonNegative={true}
        idx={`runtime-job-periodCount-${modalStyle}`}
        editMode={editMode}
        required={true}
        propertyVal={get(state, "periodCount", 0) || 0
        }
        fieldValidations={{ min: 0}}
        updateValue={(value: any) => {
          setState({...state, periodCount: value})
        }}
        subClasses={{wrapperClasses: "pl-0 pr-2 ml-0", inputClasses: "text-center narrow-number", inputWrapperClasses: "d-inline w-auto pl-0 ml-0"}}
      />}
      <div className="pl-1 text-nowrap">{text}</div>
      {showEndDate && <FormInput
        key={"endDate"}
        property={"endDate"}
        displayName={""}
        type={"date"}
        subtype={isMonth ? "monthText": "quarter"}
        idx={`runtime-job-endDate-${modalStyle}`}
        editMode={editMode}
        propertyVal={get(state, "endDate", "")
        }
        updateValue={(value: any) => {
          setState({...state, endDate: value})
        }}
        subClasses={{inputClasses: "force-zindex", wrapperClasses: "no-gutters px-1"}}
      />}
    </div>
    {showFootnote && <div className={classnames("d-flex mt-2 footnote-description")}>{footNote}</div>}
    {showShiftComponent && shiftComponent()}
  </div>
  )
}

const PeriodTypeBooleanOptions = [{
  code: PortfolioPeriod.Monthly,
  value: "Monthly",
},
{
  code: PortfolioPeriod.Quarterly,
  value: "Quarterly",
},]

const SectionHeaderClasses = {
  wrapperClasses: "row headline gray-underline underline text-uppercase justify-content-between mx-1 my-2 mb-0 pb-0",
}

const ModalHeaderClasses = {
  wrapperClasses: "row headline gray-underline underline text-uppercase justify-content-between mx-1 mb-0 pb-0",
}

const SubSectionHeaderClasses = "mt-1 py-1 pl-2"

const JobSettingInput: JobSettingInputProp[] = [
  {
    property: "",
    label: "General",
    type: "h5",
    subClasses: {
      ...SectionHeaderClasses
    },
  },
  {
    property: "name",
    label: "Name",
    type: "text",
    subClasses: {
      wrapperClasses: "ml-1 pl-2 pt-2",
      inputWrapperClasses: "col-sm-6 pl-3",
      inputClasses: "ml-1 pl-1",
    },
  },
  {
    property: "type.value",
    label: "Type",
    type: "text",
    readonly: true,
    subClasses: {
      wrapperClasses: "ml-1 pl-2",
      inputWrapperClasses: "col-sm-8 pl-3",
      inputClasses: "pl-1",
    },
  },
  {
    property: "periodType",
    label: "Reporting period",
    type: "radio",
    subtype: "single",
    options: PeriodTypeBooleanOptions,
    subClasses: {
      wrapperClasses: "ml-1 pl-2",
      inputWrapperClasses: "col-sm-4 pl-3 ml-1"
    },
  },
  {
    property: "",
    label: "Job Settings",
    type: "h5",
    subClasses: {
      ...SectionHeaderClasses
    },
  },
  {
    property: "Job Settings",
    label: "",
    type: "table",
    subClasses: {
      ...SectionHeaderClasses
    },
    Component: ArgsComponent
  },
  {
    property: "",
    label: "Sumsheet Rules",
    type: "h5",
    subClasses: {
      ...SectionHeaderClasses
    },
  },
  {
    property: "period",
    label: "",
    type: "table",
    Component: PeriodComponent,
  },
]

type SingleJobSettingProps = {
  job: SavedJobFragment // initial query data
  jobId?: number
  editMode?: boolean
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
  saving: boolean // saving status for all jobs/edit button.
  refetch: () => void
  setSaving: (saving: boolean) => void
  defaultAdditionalArgs?: PortfolioJobAdditionalArgFragment[]
}

const DefaultSavedJobArgs = {
  id: null,
  periodType: 'Quarterly',
  periodCount: 0,
  lagPeriods: null,
  startDate: moment(appDate).format(DATE_API_FORMAT),
  endDate: moment(appDate).format(DATE_API_FORMAT),
  // 1 for trail, set periodCount
  // 2 for start on, set startDate
  condition: {code: "1"},
  __typename: "SavedJob"
}

// generate periodType based off of COMPOST args.
const generatePeriodTypeForCompostJob = (additionalArgs: AdditionalArgsState) => {
  const storeValue = additionalArgs?.find(arg => arg.argType === "STORE")?.value
  return (storeValue && storeValue === "Q") ? "Quarterly": "Monthly"
}

const generateUpdateSavedJobInput = (state: RunTimeModalState) => {
  let {id, periodType, periodCount, startDate, additionalArgs, name, lagPeriods} = state
  let formattedAdditionalArgs = (additionalArgs || [])?.map(({argType: type, dataType, value}) => {
    // discard dataType, name, description.
    const formattedValue = (["INT", "PORTFOLIO_ID"].includes(dataType as string) && isNumber(value))? (value as number)?.toString(): value
    return {type, value: formattedValue}
  })
  if(state?.type?.code === "COMPOST") {
    // generate periodType based off of COMPOST additionalArgs.
    periodType = generatePeriodTypeForCompostJob(additionalArgs)
  }
  let commonInput = {lagPeriods: lagPeriods|| 0, name, periodType, additionalArgs: formattedAdditionalArgs}
  let patch = {}
  let isTrail = state?.condition?.code === "1"
  if(isTrail) {
    let formattedPeriodCount = (isNull(periodCount) || periodCount === 0)? 1: periodCount
    patch = {...commonInput, periodCount: formattedPeriodCount} as UpdateSavedJob
  }else {
    patch = {...commonInput, startDate, periodCount: null} as UpdateSavedJob
  }
  return {id, patch} as UpdateSavedJobInput
}

const getCurrentCondition = (savedJob: SavedJobFragment, defaultArgs: any) => {
  let {periodCount, startDate} = savedJob?.args || {}
  if (startDate) {
    return {code: "2"}
  }else if(periodCount){
    return {code: "1"}
  }else {
    return defaultArgs.condition
  }
}

const getInitialSavedJobArgs = (savedJob: SavedJobFragment, defaultArgs: any) => {
  let {id, args, name } = savedJob
  const {additionalArgs, periodCount, startDate} = args || {}
  let formattedAdditionalArgs = (additionalArgs || [])?.map(({argType, dataType, name, value}) => {
    return { argType, value, name, dataType}
  })
  let {periodType, lagPeriods = null} = args || defaultArgs

  const condition = getCurrentCondition(savedJob, defaultArgs)
  const {endDate} = defaultArgs

  if(savedJob?.type?.code === "COMPOST") {
    // generate periodType based off of COMPOST additionalArgs.
    periodType = generatePeriodTypeForCompostJob(formattedAdditionalArgs as AdditionalArgsState)
  }
  // add a default periodType if old saved job doesn't have one.
  if(!periodType) {
    periodType = defaultArgs.periodType
  }

  return {id, periodType, periodCount: condition?.code === "1"? periodCount: null, condition, startDate, endDate, portfolio: savedJob?.clientPortfolio?.id, type: savedJob?.type, additionalArgs: formattedAdditionalArgs, name, lagPeriods} as RunTimeModalState
}

const SingleJobSetting: React.FC<SingleJobSettingProps> = (props) => {
  let { job, editMode, defaultAdditionalArgs, saving, setEditMode, refetch, setSaving } = props
  const [state, setState] = useState<RunTimeModalState>(() => getInitialSavedJobArgs(job, DefaultSavedJobArgs))

  // local saving status for current job
  const [onSaving, setOnSaving] = useState(false)
  const [updateSavedJob, {data, loading, error,}] = useUpdateSavedJobMutation()
  // const [errorMessages, setErrorMessage] = useState<string[]>([])

  let jobId = job?.id || props.jobId

  useEffect(() => {
    if(saving) {
      // console.log("on saving", {initial: job, state})
      setOnSaving(true)
      let input = generateUpdateSavedJobInput(state)
      // TODO: update job.
      console.log("updated saved job", {input, state, initial: job })
      updateSavedJob({variables: {input}}).then((result) => {
        console.log(160, {result})
        setEditMode(false)
        refetch()
      }).catch((err) => {
        console.error("Error updating SavedJob:", {id: jobId, message: err.message})
      }).finally(() => {
        setOnSaving(false)
        setSaving(false)
      })
    }else {
      if(onSaving) {
        setOnSaving(false)
      }
    }
  }, [saving])

  useEffect(() => {
    if(!editMode && !saving) {
      setState(getInitialSavedJobArgs(job, DefaultSavedJobArgs))
    }
  }, [job])

  useEffect(() => {
    if(!editMode && !saving) {
      setState(getInitialSavedJobArgs(job, DefaultSavedJobArgs))
    }
  }, [editMode])

  const handleGeneralInputChange = (value: any, property: any) => {
    let newState = getNewStateObject({
      state: cloneDeep(state) ,
      newValue: value,
      property,
    }) as RunTimeModalState
    setState(newState)
  }

  if(onSaving) {
    return <Loading />
  }
  return (
    <div key={jobId}>
      <div className={"form-section-title headline underline small-font-size pt-2 pb-1 mb-1 ml-1"} key="row-0">
        {job.name || job?.type?.value || "Unnamed Job"}
      </div>
      <Row>
        <Col md="8">
          {JobSettingInput.map(({ property, label, type, subtype, placeholder, subClasses, Component, options, readonly }, idx) => {
          let propertyVal = get(state, property, "")
          let onChangeCallback = (value: any) => {
            if (value === "true" || value === "false") {
              value = JSON.parse(value)
            }
            handleGeneralInputChange(value, property)
          }

          if (type === "h5") {
            // title with on checkbox.
            return (
              <h5 className={subClasses?.wrapperClasses} key={idx}>
                <div className={SubSectionHeaderClasses}>{label}</div>
              </h5>
            )
          }

          if(property === "periodType" && state?.type?.code === "COMPOST") {
            // Hide periodType for COMPOST. CAL-3380
            return <React.Fragment key={idx}></React.Fragment>
          }

          switch (Component) {
            case ArgsComponent:
              return (
                <Component
                  key={idx}
                  state={state?.additionalArgs || []}
                  setState={(newArgs: AdditionalArgsState) => setState({...state, additionalArgs: newArgs})}
                  editMode={editMode}
                  defaultAdditionalArgs={defaultAdditionalArgs}
                  type={job?.type?.code}
                />
              )
            case PeriodComponent:
              return (
                <Component
                  key={idx}
                  state={state}
                  setState={setState}
                  type={SavedOrAdHoc.adhoc}
                  periodTypeEditable={false}
                  editMode={editMode}
                  settings={{sumSheetRules: true}}
                />
              )
            default:
              break
          }
          return (
            <FormInput
              key={idx}
              property={property}
              displayName={label}
              subClasses={subClasses}
              type={type}
              subtype={subtype}
              placeholder={placeholder}
              idx={`${jobId}-${idx}-${property}`}
              editMode={!!editMode}
              propertyVal={propertyVal}
              readonly={readonly}
              updateValue={onChangeCallback}
              options={options}
            />
          )
        })}
        </Col>
      </Row>
    </div>
  )
}

interface SavedJobsProps {
  savedJobs: SavedJobSideBarFragment[]
  selectedSavedJobId: Maybe<number>
  selectSavedJob: (id:number) => void
  isLoading: boolean
}

const SavedJobsSidebar:React.FC<SavedJobsProps> = (props:SavedJobsProps) => {
  const [sortBy, setSortBy] = useState(1)
  const [activeFilters, setActiveFilters] = useState<number[]>([])
  let { savedJobs, isLoading } = props

  let pickerFilters: OptionItem[] = []
  pickerFilters = []
  // Add the possible options to the
  let types = uniq(props.savedJobs.map((savedJob:SavedJob) => get(savedJob, "type.value", ""))).sort()
  types.map((type:string) => { pickerFilters.push({id: pickerFilters.length+1, name: type})})

  const sortOptions:OptionItem[] = [
    {
      id: 1,
      name: "Type (A to Z)",
    },
    {
      id: 2,
      name: "Type (Z to A)",
    }
  ]

  const onFilter = (filterId: number) => {
    let newActiveFilters = cloneDeep(activeFilters)
    const index = newActiveFilters.indexOf(filterId)
    if (index === -1) {
      newActiveFilters.push(filterId)
    } else {
      newActiveFilters.splice(index, 1)
    }
    setActiveFilters(newActiveFilters)
  }

  const onSort = (sortId: number) => {
    setSortBy(sortId)
  }


  const objectListItem = (savedJob: SavedJob, idx: number) => {
    const type = get(savedJob, "type.value")
    return (
      <ListGroupItem
        tag="a"
        key={idx}
        className={classnames({
          active: savedJob.id === props.selectedSavedJobId,
          "picker-list-item-characteristics": true,
        })}
        disabled={isLoading}
        onClick={() => { props.selectSavedJob(savedJob?.id || -1) }}
      >
        <h5>
          {savedJob.name || savedJob.shortDescription || "Unnamed Saved Job"}
        </h5>
        <dl>
          <dt>Type: </dt>
          <dd>{type}</dd>
          <dt>Job Id: </dt>
          <dd>{savedJob?.id || ""}</dd>
        </dl>
      </ListGroupItem>
    )
  }

  const emptyMessage = () => {
    return `There are no saved jobs associated with this clientPortfolio.`
  }

  savedJobs = savedJobs
  .filter((savedJob: SavedJobSideBarFragment) => {
    if (activeFilters.length > 0) {
      return some(activeFilters, (id:number) => {
        const matchingFilter = find(pickerFilters, {id: id})
        return matchingFilter?.name === savedJob.type?.value
      })

    }
    return true
  })
  .sort((savedJobA: SavedJobSideBarFragment, savedJobB: SavedJobSideBarFragment) => {
    const nameA = (savedJobA?.type?.value || "").toLocaleLowerCase().trim()
    const nameB = (savedJobB?.type?.value || "").toLocaleLowerCase().trim()
    switch (sortBy) {
      case 1:
        return nameA.localeCompare(nameB)
      case 2:
        return nameB.localeCompare(nameA)
      default:
        throw new Error(`unrecognized sort choice ${sortBy}`)
    }
  })

  return (
    <Picker
      filters={pickerFilters}
      activeFilters={activeFilters}
      sortOptions={sortOptions}
      activeSort={sortBy}
      onFilter={onFilter}
      onSort={onSort}
      searching={false}
      listClass="under-edit-toolbar-dropdown"
      toolbarClass="under-edit-toolbar"
    >
      {savedJobs.length > 0 && savedJobs.map((o, idx) => objectListItem(o, idx))}
      {savedJobs.length == 0 &&
        <div className="p-3 text-gray-70">
          {emptyMessage()}
        </div>
      }
    </Picker>
  )
}


type idProps = {
  clientportfolioId: number
  planId: Maybe<number> | undefined
  // history?: History
  // location?: Location
  auth: Auth
}

enum SavingStatus{
  Initial = 1,
  UpdateJobParameters = 2,
  Error = 3,
}

const isSaving = (savingStatus: SavingStatus) => {
  return ![SavingStatus.Initial, SavingStatus.Error].includes(savingStatus)
}

const allowedJobTypes = ["DNA_SUMWEALTH"]

interface SavedJobDeleteModalProps {
  isOpen: boolean
  toggle: () => void
  savedJobId: number
  deleteData: (id: number) => void
}
const SavedJobDeleteModal = (props: SavedJobDeleteModalProps) => {
  const {toggle, isOpen, savedJobId, deleteData} = props

  const deleteAction = () => {
    deleteData(savedJobId)
    toggle()
  }
  return (
    <Modal size="md" className="mt-5" isOpen={isOpen} toggle={toggle} zIndex={1500}>
      <ModalHeader className="fee-modal-header full-width-header">
        <div className="d-flex justify-content-between w-100 text-danger">
          <div>
            Permanently Delete Saved Job
          </div>
          <div onClick={toggle}>
            <FontAwesomeIcon
              icon="times"
              className="ml-auto"
            />
          </div>
        </div>
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={3}>
            <div className="d-flex justify-content-center">
              <FontAwesomeIcon icon="exclamation-triangle" size={"4x"} className="text-danger unlock-icon-height"/>
            </div>
          </Col>
          <Col xs={9}>
            Are you sure you want to permanently delete this saved job?
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={toggle} color="secondary" className="mr-1 ml-auto px-2">Cancel</Button>
        <Button onClick={deleteAction} color="danger" className="mr-1 px-2">Permanently Delete Saved Job</Button>
      </ModalFooter>
    </Modal>
  )

}

interface RunTimeModalProps {
  isOpen: boolean
  toggle: () => void
  savedJob: SavedJobFragment
  planId: Maybe<number> | undefined
}

const isMonthType = (periodType: string) => periodType === "Monthly"

const monthFormat = "MMM YYYY"
const quarterFormat = "[Q]Q YYYY"

const defaultMaxDate = moment(appDate).add(12, "years")
const defaultMinDate = moment('1900-01-01')

const generateAdhocInputs = (state: RunTimeModalState) => {
  let {condition, periodCount, startDate, endDate, periodType, portfolio, type, additionalArgs } = state
  let formattedAdditionalArgs = (additionalArgs || [])?.map(({argType: type, dataType, value}) => {
    // discard dataType, name, description.
    // convert to string
    const formattedValue = (["INT", "PORTFOLIO_ID"].includes(dataType as string) && isNumber(value))? (value as number)?.toString(): value
    return {type, value: formattedValue}
  }).filter(({type, value}) => !isNull(value))
  if(state?.type?.code === "COMPOST") {
    // generate periodType based off of COMPOST additionalArgs.
    periodType = generatePeriodTypeForCompostJob(additionalArgs)
  }
  let commonInput = {periodType, portfolio, type: type?.code, additionalArgs: formattedAdditionalArgs || null}
  let isTrail = condition?.code === "1"
  if(isTrail) {
    let formattedPeriodCount = (isNull(periodCount) || periodCount === 0)? 1: periodCount
    return {...commonInput, periodCount: formattedPeriodCount, date: endDate} as RunAdHocJobInput
  }else {
    return {...commonInput, startDate, date: endDate, periodCount: null} as RunAdHocJobInput
  }
}

interface RunTimeModalState {
  id?: Maybe<number> | undefined
  periodType: string
  periodCount: Maybe<number> | undefined
  startDate: Maybe<string>
  endDate: Maybe<string>
  condition: {code: string}
  portfolio: number | undefined
  type: Maybe<{code: any, value: any}>
  additionalArgs: AdditionalArgsState
  name?: string | undefined
  lagPeriods?: Maybe<number>
}

const ConditionOptions = () => GetLookupDataToOptions({
  data: [
    {
      // periodCount > 0, no startDate needed.
      code: "1",
      value: "Trail",
    },
    {
      // periodCount = 0, startDate needed.
      code: "2",
      value: "Start On",
    },
  ],
  multiple: true
})

const PeriodTypeOptions = () => GetLookupDataToOptions({
  data: [
    {
      code: PortfolioPeriod.Monthly,
      value: "monthly",
    },
    {
      code: PortfolioPeriod.Quarterly,
      value: "quarterly",
    },
  ],
  multiple: true
})

const getRunTimeModalArgs = (savedJob: SavedJobFragment, defaultArgs: any) => {
  let args = getInitialSavedJobArgs(savedJob, defaultArgs)
  let {periodType} = args
  if(savedJob?.type?.code === "COMPOST") {
    periodType = generatePeriodTypeForCompostJob(args?.additionalArgs)
  }
  // add a default periodType if old saved job doesn't have one.
  if(!periodType) {
    periodType = "Monthly"
  }
  let result = {...args, periodType, startDate: defaultArgs?.startDate, condition: {code: "2"}, periodCount: null}
  return result as RunTimeModalState
}

// run button triggers runAdHocJob mutation.
const RunTimeModal: React.FC<RunTimeModalProps> = (props) => {
  const {toggle, isOpen, savedJob, planId} = props

  const [state, setState] = useState<RunTimeModalState>(() =>getRunTimeModalArgs(savedJob, DefaultNewJobArgs))

  const [saving, setSaving] = useState(false)
  const { addAlert } = useContext(TemporaryAlertContext)

  const [runAdHocJob] = useRunAdHocJobMutation()

  useEffect(() => {
    if(isOpen) {
      let newState = getRunTimeModalArgs(savedJob || {id: null, args: null}, DefaultNewJobArgs)
      if(!newState?.startDate && !newState?.periodCount) {
        newState = {...newState, periodCount: 1}
      }
      setState(newState)
    }
  }, [savedJob.id, isOpen])

  const closeModal = () => {
    toggle()
  }

  const onSubmit = () => {
    setSaving(true)
    let input = generateAdhocInputs(state)
    console.log("submit", {input})
    runAdHocJob({variables: {
      input}}).then((result) => {
        let data = result?.data?.runAdHocJob
      if(data) {
        console.log("Success running job", {data})
        addAlert({title: "Success | Job Kicked Off", message: <>Job is running. View job status in the <span className="cursor-pointer text-decoration-underline" onClick={() => window.open(`/plans/${planId}/activities` ,'_blank')}>Plan's Activities.</span></>, color: "userSuccess", timeout: 3000})
      }
    }).catch((error) => {
      console.error("Error running job", {message: error.message})
    }).finally(() => {
      setSaving(false)
      toggle()
    })
  }

  return (
    <Modal size="md" className="mt-5 job-settings" isOpen={isOpen} toggle={toggle} zIndex={1500}>
      <ModalHeader className="fee-modal-header full-width-header">
        <div className="d-flex justify-content-between w-100">
          <div>
          Run {savedJob?.name || ""}
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="mt-1 pt-1">
        {PeriodComponent({state, setState, type: SavedOrAdHoc.adhoc, periodTypeEditable: false, editMode: true, modalStyle: true, settings:{showFootNote: true}})}
      </ModalBody>
      <ModalFooter>
        <EditButtons className="mr-2" key={1} editMode={true} setEditMode={() => true} cancelEdit={() => closeModal()} saving={saving} onSubmit={onSubmit} disableOnError={true} saveText="Run Job"/>
      </ModalFooter>
     </Modal>
  )
}

enum SavedOrAdHoc {
  saved = "saved",
  adhoc = "adhoc"
}
interface CreateNewJobDropdownModalProps {
  args: AvailableJobFragment & {portfolioId: number}
  toggle: () => void
  isOpen: boolean
  type: SavedOrAdHoc
  selectSavedJob: (id: number) => void
  refreshAllJobs: (variables?: Partial<Exact<{id: any}>> | undefined) => Promise<ApolloQueryResult<PortfolioJobsSettingsQuery>>
  planId: Maybe<number> | undefined
}

type DefaultParamsType = {
  type: JobName[];
  argType: string;
  dataType: string;
  defaultValue: string;
}
// hardcode
const DEFAULT_PARAMS: DefaultParamsType[] = [
  {
    type: [ JobName.ASSET_ATTRIB, JobName.CIA, JobName.UMA_ASSET_ATTRIB ],
    argType: "TARGET_PID",
    dataType: "PORTFOLIO_ID",
    defaultValue: "<PORTFOLIO_NUM>",
  }
]

// get default params from available job args. convert default value if needed.
const getDefaultParams = (props: {args: AvailableJobFragment & {portfolioId: number}}) => {
  let { args } = props
  let {additionalArgs, portfolioId} = args
  let type = args?.type?.code

  let argsAfterDefault = (additionalArgs || [])?.map(arg => {
    let {argType, dataType, defaultValue, description, name} = arg
    let findDefault = DEFAULT_PARAMS.find(param => param.argType === argType && param.type.includes(type))
    // hard-code. https://callanllc.atlassian.net/browse/CAL-2737
    let value = findDefault?.defaultValue.replace("<PORTFOLIO_NUM>", portfolioId.toString()) || defaultValue
    return { ...arg, value }
  })
  return argsAfterDefault as PortfolioJobAdditionalArgFragment[]
}

// general args, no additionalArgs included.
const DefaultNewJobArgs = {
  id: null,
  periodType: 'Quarterly',
  periodCount: 1,
  lagPeriods: null,
  startDate: moment(appDate).format(DATE_API_FORMAT),
  endDate: moment(appDate).format(DATE_API_FORMAT),
  condition: {code: "1"},
}

const getInitialNewJobArgs = (props: CreateNewJobDropdownModalProps, defaultArgs: any) => {
  const { args } = props
  const {portfolioId: portfolio, fullDescription, shortDescription, type} = args || {}
  const additionalArgs = getDefaultParams(props)
  let result = {type, additionalArgs, portfolio, ...defaultArgs} as RunTimeModalState
  return result
}

// common modal for new saved job & adhoc job.(then run job)
const CreateNewJobDropdownModal: React.FC<CreateNewJobDropdownModalProps> = (props) => {
  const {args, toggle, isOpen, planId, type} = props
  const fromSavedJob = type === "saved"
  const defaultNewJobArgs = useMemo(() => {
    const periodType = args?.type?.code === "COMPOST"? "Monthly": "Quarterly"
    if(fromSavedJob) {
      return {...DefaultNewJobArgs, periodType}
    }else {
      return {...DefaultNewJobArgs, condition: {code: "2"}, periodCount: null, periodType}
    }
  }, [type, args?.type?.code])

  const [state, setState] = useState<RunTimeModalState>(() => getInitialNewJobArgs(props, defaultNewJobArgs))

  const [runAdHocJob] = useRunAdHocJobMutation()
  const [createSavedJob] = useCreateSavedJobMutation()
  // const [runSavedJob] = useRunSavedJobMutation()
  const [savingStatus, setSavingStatus] = useState(SavingStatus.Initial)
  const { addAlert } = useContext(TemporaryAlertContext)

  useEffect(() => {
    if(isOpen && !isSaving(savingStatus)) {
      // console.log("props updated on modal open.")
      setState(getInitialNewJobArgs(props, defaultNewJobArgs))
    }else {
      // console.log("props updated on modal closed.")
    }
  }, [props])

  const closeModal = () => {
    toggle()
  }

  const onSubmit = () => {
    // if saved, createSavedJob then runSavedJob, if adhoc, runAdhocJob.
    console.log("submitting start", {state, type})
    setSavingStatus(SavingStatus.UpdateJobParameters)
    if(fromSavedJob) {
      // date is not saved in saved job, but needed when runSavedJob.
      let adHocInput = generateAdhocInputs(state)
      let {date, ...usefulInput} = adHocInput
      let {name, lagPeriods = null} = state
      const input = {...usefulInput, name: name || state?.type?.value, lagPeriods} as CreateSavedJobInput
      console.log("submit new saved", {input, state})
      let newId: number
      createSavedJob({variables: {input}})
      .then((result) => {
        let data = result?.data?.createSavedJob
        if(data?.id) {
          newId = data?.id
          console.log("Success create saved job", {id: newId})
          addAlert({title: "Success | Saved Job Created", message: "", color: "userSuccess", timeout: 1000})
          props.refreshAllJobs().then(() => {
            props.selectSavedJob(newId)
          })
        }
      }).catch((error) => {
        console.error("Error create saved job", {message: error.message})
      }).finally(() => {
        toggle()
        setSavingStatus(SavingStatus.Initial)
      })
    } else {
      let input = generateAdhocInputs(state)
      console.log("submit new adhoc", input, state)
      runAdHocJob({variables: {input}})
      .then((result) => {
        let data = result?.data?.runAdHocJob
        console.log("runAdHocJob result", {data})
        if(data) {
          console.log("Success running job", {data})
          addAlert({title: "Success | Job Kicked Off", message: <>Job is running. View job status in the <span className="cursor-pointer text-decoration-underline" onClick={() => window.open(`/plans/${planId}/activities` ,'_blank')}>Plan's Activities.</span></>, color: "userSuccess", timeout: 3000})
          }
      }).catch((error) => {
        console.error("Error running job", {message: error.message})
      }).finally(() => {
        setSavingStatus(SavingStatus.Initial)
        toggle()
      })
    }
  
  }

  const type_text = fromSavedJob? "Saved": "Ad Hoc"
  const periodTypeEditable = state?.type?.code === "COMPOST"? false: true

  return (
    <Modal size="md" className="mt-5 job-settings" isOpen={isOpen} toggle={toggle} zIndex={1500}>
      <ModalHeader className="fee-modal-header full-width-header">
        <div className="d-flex justify-content-between w-100">
          <div>
            Create New {type_text} Job: {args?.type?.value || ""}
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="pt-1">
        <div className={"pt-1"}>
          {fromSavedJob && 
          <>
            <h5 className={ModalHeaderClasses.wrapperClasses}>
              <div className={SubSectionHeaderClasses}>General</div>
            </h5>
            <FormInput
              key={"modal-job-name"}
              property={"state.name" || ""}
              displayName={"Name"}
              type={"text"}
              idx={`runtime-job-arg-name-modal`}
              editMode={true}
              propertyVal={get(state, "name")}
              updateValue={value => setState({...state, name: value})}
              subClasses={{
                labelClasses: "col-sm-4 font-weight-bold",
                wrapperClasses: "ml-1 pl-2 pt-2",
                inputWrapperClasses: "col-sm-6 pl-3",
                inputClasses: "ml-1 pl-1",
              }}
              />
            </>}
          </div>
        <h5 className={ModalHeaderClasses.wrapperClasses}>
          <div className={SubSectionHeaderClasses}>Reporting Period</div>
        </h5>
        {PeriodComponent({state, setState, type, periodTypeEditable, editMode: true, modalStyle: true, settings: {sumSheetRules: false}})}
        <h5 className={ModalHeaderClasses.wrapperClasses}>
          <div className={SubSectionHeaderClasses}>Settings</div>
        </h5>
        <ArgsComponent
          state={state?.additionalArgs || []}
          setState={(newArgs: AdditionalArgsState) => {
            let {periodType, ...rest} = state
            if(args?.type?.code === "COMPOST") {
              // generate periodType based off of COMPOST additionalArgs.
              periodType = generatePeriodTypeForCompostJob(newArgs)
            }
            setState({...rest, periodType, additionalArgs: newArgs})}}
          editMode={true}
          modalStyle={true}
          type={args?.type?.code}
          jobId={"new"}
        />
      </ModalBody>
      <ModalFooter>
        <EditButtons className="mr-2" key={1} editMode={true} setEditMode={() => true} cancelEdit={() => closeModal()} saving={isSaving(savingStatus)} onSubmit={onSubmit} disableOnError={true} saveText={fromSavedJob? "Save Job": "Run Job"}/>
      </ModalFooter>
     </Modal>
  )
}

const PortfolioJobSettings: React.FC<idProps> = ({ clientportfolioId, planId, auth }) => {
  const [editMode, setEditMode] = useState(false)
  const [saving, setSaving] = useState(false)
  const history = useHistory()

  let {id} = useParams() as {id: any} // job id, string format.
  const getInitialJobId = (id:string) => parseInt(id) || 0

  // selected saved job, matched jobId.
  const [selectedJob, setSelectedJob] = useState<Maybe<SavedJobFragment>>(null)

  const [isAddSavedJobOpen, setAddSavedJobOpen] = useState(false)

  const fetchAvailableJobsIfNeeded = () => {
    if((!availableJobsLoading) && !availableJobsData) {
      fetchAvailableJobs()
    }
  }
  const toggleAddSavedJobDropdown = () => {
    fetchAvailableJobsIfNeeded()
    setAddSavedJobOpen(!isAddSavedJobOpen)
  }

  // fetch available jobs, used in add saved job dropdown.
  const [fetchAvailableJobs, {loading: availableJobsLoading, error: availableJobsError, data: availableJobsData}] = useAvailableJobsQueryLazyQuery({
    variables: {id: clientportfolioId},
    fetchPolicy: "network-only"
  })

  const [availableJobs, setAvailableJobs] = useState([] as AvailableJobFragment[])

  const [isAddAdHocJobOpen, setAddAdHocJobOpen] = useState(false)
  const toggleAddAdHocJobDropdown = () => {
    fetchAvailableJobsIfNeeded()
    setAddAdHocJobOpen(!isAddAdHocJobOpen)
  }

  // from which button
  const [newJobSourceType, setNewJobSavedOrAdHoc] = useState(SavedOrAdHoc.saved)
  // which availableJob is selected from Add Saved Job/ Ad Hoc Job, contains additional args with description & default value.
  const [selectedAvailableNewJob, setSelectedAvailableNewJob] = useState<Maybe<AvailableJobFragment>>(null)
  const [createNewJobModalOpen, setCreateNewJobModalOpen] = useState(false)

  // To get additional args for saved job mutation.
  const [matchedAvailableSavedJob, setMatchedAvailableSavedJob] = useState<Maybe<AvailableJobFragment>>(null)
  const { addAlert } = useContext(TemporaryAlertContext)

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteSavedJobMutation, { data: deleteResult, loading: deleteLoading, error: deleteError }] = useDeleteSavedJobMutation()

  // Run job
  const [runTimeModalOpen, setRunTimeModalOpen] = useState(false)

  const { loading, error, data, refetch } = usePortfolioJobsSettingsQuery({
    fetchPolicy: "network-only",
    variables: { id: clientportfolioId },
  })

  const [useQuerySavedJob, {loading: savedJobLoading , error: savedJobError , data: savedJobData , refetch: savedJobRefetch }] = useSavedJobDetailsQueryLazyQuery({
    fetchPolicy: "network-only"
  })

  const onDelete = () => {
    console.log("deleted process starts")
    setDeleteModalOpen(true)
  }

  const deleteJob = (jobId: number) => {
    console.log("delete job")
    deleteSavedJobMutation({variables: {id: jobId}}).then((result) => {
      let data = result?.data?.deleteSavedJob
      if(data?.status === "Success"){
        console.log("deleted job", {result})
        addAlert({title: "Success | Saved Job deleted.", message: "", color: "userSuccess", timeout: 3000})
        setTimeout(() => {
          refetch().then().catch((err) => {
            console.error("Error refetching SavedJobs", {portfolio: clientportfolioId, message: err.message})
          }).finally(() => {
            history.push(`/clientportfolios/${clientportfolioId}/jobsettings`)
          })
        }, 3400);
      }else {
        console.log(data?.message, data?.status)
        const errorMessage = `${data?.message} status: ${data?.status}`
        addAlert({title: "Error | Saved Job deleted.", message: errorMessage, color: "error", timeout: 3000})
      }
    }).finally(() => {setDeleteModalOpen(false)})
  }

  const runJob = () => {
    console.log("run job")
    // modal popup
    setRunTimeModalOpen(true)
  }

  const handleSubmit = () => {
    console.log("submitting")
    setSaving(true)
    // const cnt = data?.clientPortfolio?.savedJobs?.length || 0
  }

  const selectJobId = (newId: number) => {
    if(parseInt(id || 0) !== newId) {
      history.push(`/clientportfolios/${clientportfolioId}/jobsettings/${newId}`)
    }
  }

  const fetchSavedJob = (jobId: number) => {
    // query single saved job details and set selectedJob.
    useQuerySavedJob({variables: {id: jobId}}).then(result => {
      let savedJob = result?.data?.savedJob
      if(savedJob) {
        setSelectedJob(savedJob)
      }
    }).catch((error) => {
      console.log(error.message)
    })
  }

  const triggerCreateNewJobModal = (job: AvailableJobFragment, type: SavedOrAdHoc) => {
    setNewJobSavedOrAdHoc(type)
    setSelectedAvailableNewJob(job)
    setTimeout(() => {
      setCreateNewJobModalOpen(true)
    }, 300)
  }

  useEffect(() => {
    let newId = getInitialJobId(id ||"")
    if(!loading && data?.clientPortfolio?.savedJobs?.length) {
      let firstJobId = first(data?.clientPortfolio?.savedJobs)?.id || 0
      let findJob = data?.clientPortfolio?.savedJobs?.some((job) => job?.id === newId)
      if(newId) {
        if(findJob && (selectedJob?.id !== newId)) {
          fetchSavedJob(newId)
        }else if(firstJobId && !findJob){
          history.replace(`/clientportfolios/${clientportfolioId}/jobsettings/${firstJobId}`)
        }else if(!findJob && !firstJobId) {
          history.replace(`/clientportfolios/${clientportfolioId}/jobsettings`)
        }
      }else if(firstJobId) {
        history.replace(`/clientportfolios/${clientportfolioId}/jobsettings/${firstJobId}`)
      }
    }
  }, [id, loading])

  useEffect(() => {
    const data = availableJobsData?.clientPortfolio?.availableJobs
    let jobs: AvailableJobFragment[] = []
    if(data?.length && !availableJobs?.length) {
      jobs = sortBy(data, "type.value") as AvailableJobFragment[]
      setAvailableJobs(jobs)
    }
    if(editMode) {
      let matchedAvailableJob = jobs.find(job => job.type.code === selectedJob?.type?.code)
      // for saved job, get additional args.
      if(matchedAvailableJob && !matchedAvailableSavedJob){
        setMatchedAvailableSavedJob(matchedAvailableJob)
      }
    }
  }, [availableJobsData])

  useEffect(() => {
    if(editMode) {
      fetchAvailableJobsIfNeeded()
      if(editMode) {
        let matchedAvailableJob = availableJobs.find(job => job.type.code === selectedJob?.type?.code)
        // for saved job, get additional args.
        if(matchedAvailableJob && !matchedAvailableSavedJob){
          setMatchedAvailableSavedJob(matchedAvailableJob)
        }
      }
    }
  }, [editMode])

  useEffect(() => {
    if(!editMode) {
      setAvailableJobs([])
    }
  }, [clientportfolioId])

  useEffect(() => {
    // for routing to another savedJob in editMode.
    if(matchedAvailableSavedJob?.type?.code !== selectedJob?.type?.code) {
      let matchedAvailableJob = availableJobs?.find(job => job.type.code === selectedJob?.type?.code)
      // job type change, use new available job for args.
      if(matchedAvailableJob){
        setMatchedAvailableSavedJob(matchedAvailableJob)
      }
    }
  }, [selectedJob?.id])

  const cancelEditMode = () => {
    setEditMode(false)
  }

  const heading = (
    <>
      <RouteLeavingGuard when={editMode} navigate={(path) => history.push(path)} />
      <div className='pane pane-toolbar sticky-top client-portfolio'>
        <div className="add-to-composite-wrapper border-right pl-1 mr-1">
          <ButtonDropdown isOpen={isAddSavedJobOpen} toggle={toggleAddSavedJobDropdown} className={"headline-dropdown add-to-composite-picker-dropdown border-blue-80 pr-1 text-callan-blue"} disabled={editMode}>
            <DropdownToggle caret={true} className="mr-2 text-callan-blue border-blue-80 btn-thin" color="link" disabled={editMode}>
              {"Add Saved Job"}
            </DropdownToggle>
            <DropdownMenu onClick={(event) => event.stopPropagation()}>
              <ListGroup className="headline-dropdown-links">
                {availableJobsLoading && <Loading />}
                {availableJobsError && <ErrorDisplay error={availableJobsError} />}
                {!availableJobsLoading && !availableJobsError && availableJobs && availableJobs.map((job) => {
                  return (
                    <ListGroupItem
                      tag="a"
                      key={`job-${job?.type?.code}`}
                      onClick={(event: React.MouseEvent<any, globalThis.MouseEvent>) => {
                        event.stopPropagation()
                        setAddSavedJobOpen(false)
                        triggerCreateNewJobModal(job, SavedOrAdHoc.saved)
                      }}
                    >
                      {job?.type?.value}
                    </ListGroupItem>
                  )
                })}
              </ListGroup>
            </DropdownMenu>
          </ButtonDropdown>
          <Button color="link" className="mr-2 text-callan-blue border-blue-80 btn-thin" onClick={onDelete} disabled={editMode || deleteLoading}>
            Delete Job <FontAwesomeIcon icon={["far", "trash-alt"]} className="ml-1 text-blue-100" />
          </Button>
        </div>
        <div className="pl-1 mr-1">
          {/* run button only works for saved job only */}
          <Button color="link" className="text-callan-blue border-blue-80 btn-thin" onClick={runJob}disabled={!!editMode || !id}>
            Run
          </Button>
          <ButtonDropdown isOpen={isAddAdHocJobOpen} toggle={toggleAddAdHocJobDropdown} className={"headline-dropdown add-to-composite-picker-dropdown border-blue-80 text-callan-blue px-2 mx-1"} disabled={editMode}>
            <DropdownToggle caret={true} className="mr-2 text-callan-blue border-blue-80 btn-thin" color="link" disabled={editMode}>
              {"Ad Hoc Job"}
            </DropdownToggle>
            <DropdownMenu onClick={(event) => event.stopPropagation()}>
              <ListGroup className="headline-dropdown-links">
                {availableJobsLoading && <Loading />}
                {availableJobsError && <ErrorDisplay error={availableJobsError} />}
                {!availableJobsLoading && !availableJobsError && availableJobs && availableJobs.map((job, idx) => {
                  return (
                    <ListGroupItem
                      tag="a"
                      key={`job-${idx}`}
                      onClick={(event: React.MouseEvent<any, globalThis.MouseEvent>) => {
                        event.stopPropagation()
                        console.log({job})
                        setAddAdHocJobOpen(false)
                        triggerCreateNewJobModal(job, SavedOrAdHoc.adhoc)
                      }}
                    >
                      {job?.type?.value}
                    </ListGroupItem>
                  )
                })}
              </ListGroup>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
        <EditButtons
          editMode={editMode}
          setEditMode={setEditMode}
          saving={saving}
          onSubmit={handleSubmit}
          cancelEdit={cancelEditMode}
        />
        <SavedJobDeleteModal
          isOpen={deleteModalOpen}
          toggle={() => setDeleteModalOpen(!deleteModalOpen)}
          savedJobId={parseInt(id || 0)}
          deleteData={deleteJob}
        />
        {!!selectedJob && 
          <RunTimeModal
            isOpen={runTimeModalOpen}
            toggle={() => setRunTimeModalOpen(!runTimeModalOpen)}
            savedJob={selectedJob}
            planId={planId}
          />
        }
        {!!selectedAvailableNewJob && 
          <CreateNewJobDropdownModal
            key={`${selectedAvailableNewJob?.type?.code}-${newJobSourceType}`}
            args={{...selectedAvailableNewJob, portfolioId: clientportfolioId}} // args from parent
            isOpen={createNewJobModalOpen}
            toggle={() => setCreateNewJobModalOpen(!createNewJobModalOpen)}
            // not in use.
            type={newJobSourceType}
            selectSavedJob={selectJobId}
            refreshAllJobs={refetch}
            planId={planId}
          />}
      </div>
    </>
  )

  if (loading) {
    return (
      <Container fluid>
        {heading}
        <Row>
          <Col>
            <div className='pane'>
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  } else if (error) {
    return (
      <Container fluid>
        {heading}
        <ErrorDisplay error={error} />
      </Container>
    )
  } else if (data) {
    const jobs = data?.clientPortfolio?.savedJobs?.filter((job) => allowedJobTypes.includes(job?.type?.code || "") || true) || []
    const idNotInJobs = !jobs.some((job) => job?.id === parseInt(id || 0))
    return (
      <Container fluid>
        {heading}
        <Row className={"job-settings"}>
          <SavedJobsSidebar
            savedJobs={jobs as SavedJob[]}
            selectSavedJob={(id:number) => selectJobId(id)}
            selectedSavedJobId={idNotInJobs ? null : parseInt(id || 0)}
            isLoading={savedJobLoading}
          />
          <Col md="8" lg="9" className="pl-md-1">
            <div className="pane pl-3">
              {!id &&
                (<Col md="8" lg="9" className="pl-1">
                  <div className="pane">
                    <Row>
                      <Col>
                        <h3>No Saved Job Selected</h3>
                      </Col>
                    </Row>
                  </div>
                </Col>)}
              {id && savedJobLoading && <PlaceHolder/>}
              {id && savedJobError && <ErrorDisplay error={savedJobError} />}
              {id && id == selectedJob?.id && !savedJobLoading && !savedJobError && <SingleJobSetting editMode={editMode} jobId={id} job={savedJobData?.savedJob as SavedJobFragment} refetch={() => fetchSavedJob(parseInt(id|| 0))} saving={saving} setSaving={setSaving} setEditMode={setEditMode} defaultAdditionalArgs={matchedAvailableSavedJob?.additionalArgs || []}/>}
            </div>
          </Col>
        </Row>
      </Container>
    )
  } else {
    return (
      <Container fluid>
        {heading}
        <div>No data</div>
      </Container>
    )
  }
}

export default PortfolioJobSettings
