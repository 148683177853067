import { disableFragmentWarnings, gql } from "@apollo/client"

import { CHARACTERISTICS_DEFAULT_FRAGMENT, CHARACTERISTICS_FRAGMENT, MISC_PORTFOLIO_INFO_FRAGMENT, PORTFOLIO_CHARACTERISTICS_FRAGMENT } from "./Fragments"

import {
  CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOWS_NET_ASSET_FRAGMENT,
  CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOW_FRAGMENT,
  CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOW_ONLY_FRAGMENT,
} from "./PortfolioFragments"
import { SUM_SHEET_SAVED_JOB_FRAGMENT } from "./ReportFragments"

disableFragmentWarnings()

export const CLIENT_PORTFOLIO_QUERY = gql`
  query ClientPortfolios($offset: Int, $limit: Int) {
    portfolios: clientPortfolios(filters: { offset: $offset, limit: $limit }) {
      id
      name
      manager {
        name
      }
      client {
        name
      }
      bank {
        name
      }
      assetClass {
        id
        fullName
        shortName
      }
    }
  }
`

export const CLIENT_PORTFOLIO_CHARACTERISTICS_QUERY = gql`
  query ClientPortfolioCharacteristics(
    $id: Int!
    $startDate: Date!
    $endDate: Date!
    $filters: CharacteristicsFilters!
  ) {
    portfolio: clientPortfolio(id: $id) {
      id
      name
      characteristics (filters: $filters) {
        ...MiscPortfolioInfoFragment
        ...PortfolioCharacteristicsFragment
        ...CharacteristicsDefaultFragment
      }
      weightedExposures {
        ...CharacteristicsFragment
      }
      clientSpecificDataCollectionFields {
        questionnaireHoldingsRequired
      }
      holdingStatus {
        quarterDate
        unlock
        submittedDate
        person {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${CHARACTERISTICS_DEFAULT_FRAGMENT}
  ${MISC_PORTFOLIO_INFO_FRAGMENT}
  ${PORTFOLIO_CHARACTERISTICS_FRAGMENT}
  ${CHARACTERISTICS_FRAGMENT}
`

export const UPDATE_CLIENT_PORTFOLIO_MUTATION = gql`
  mutation UpdateClientPortfolio(
    $input: UpdateClientPortfolioInput!
    $startDate: Date!
    $endDate: Date!
    $filters: CharacteristicsFilters!
  ) {
    portfolio: updateClientPortfolio(input: $input) {
      portfolio {
        id
        name
        characteristics (filters: $filters) {
          ...MiscPortfolioInfoFragment
          ...PortfolioCharacteristicsFragment
          ...CharacteristicsDefaultFragment
        }
        weightedExposures {
          ...CharacteristicsFragment
        }
        clientSpecificDataCollectionFields {
          questionnaireHoldingsRequired
        }
      }
    }
  }
  ${CHARACTERISTICS_DEFAULT_FRAGMENT}
  ${MISC_PORTFOLIO_INFO_FRAGMENT}
  ${PORTFOLIO_CHARACTERISTICS_FRAGMENT}
  ${CHARACTERISTICS_FRAGMENT}
`


export const CLIENT_PORTFOLIOS_PERFORMANCE = gql`
  query ClientPortfoliosPerformance($id: Int!) {
    product(id: $id) {
      product {
        name
        vehicles {
          vehicle {
            id: fundid
            relatedClientPortfolioManagerDataCollect {
              name
              client {
                name
              }
              clientSpecificDataCollectionFields {
                questionnaireCharacteristicsRequired
                questionnaireCashFlowRequired
              }
            }
          }
        }
      }
    }
  }
`

export const UPDATE_CLIENT_PORTFOLIO_PERFORMANCE_BARE = gql`
  mutation UpdateClientPortfolioPerformanceBare(
    $input: UpdateClientPortfolioInput!
    $date: Date!
  ) {
    portfolio: updateClientPortfolio(input: $input) {
      portfolio {
        ...ClientPortfolioPerformanceCashflowFragment
      }
    }
  }
  ${CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOW_FRAGMENT}
`

export const CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOWS = gql`
  query ClientPortfolioPerformanceCashflow($id: Int!, $date: Date!) {
    portfolio_cashflows: clientPortfolio(id: $id) {
      ...ClientPortfolioPerformanceCashflowFragment
    }
    assetClassMaps {
      uniqueTransactionTypeCodeAssetClassMap {
        code
        assetMixNum
      }
    }
  }
  ${CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOW_FRAGMENT}
`

export const DELETE_CLIENT_PORTFOLIO_CASHFLOW_TRANSACTION = gql`
  mutation DeleteClientPortfolioCashflowTransaction(
    $input: DeleteClientPortfolioCashFlowTransactionInput!
  ) {
    portfolio: deleteClientPortfolioCashFlowTransactions(input: $input) {
      status
      message
    }
  }
`

export const CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOWS_PERFORMANCE = gql`
  query ClientPortfolioPerformanceCashflowPerformance(
    $id: Int!
    $date: Date!
    $performanceInput: CashFlowPerformanceParams!
  ) {
    portfolio_cashflow_performance: clientPortfolio(id: $id) {
      id
      cashFlowsPerformance(date: $date, input: $performanceInput) {
        net
        tvpi
        rvpi
        dpi
        irr
        committedCapital
        uncalledCapital
        calledCapital
        distributedCapital
        irrMessage
        startDate
      }
    }
  }
`

export const CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOW_HISTORIC = gql`
  query ClientPortfolioPerformanceCashflowHistoric($id: Int!) {
    clientPortfolio(id: $id) {
      id
      name
      cashFlowsHistory {
        signedURL
      }
    }
  }
`

export const CLIENT_PORTFOLIO_PERFORMANCE_CASHFLOW_SUMMARY = gql`
  query ClientPortfolioPerformanceCashflowSummary($id: Int!, $date: Date!) {
    portfolio_cashflow_summary: clientPortfolio(id: $id) {
      id
      __typename
      cashFlowsSummary(date: $date) {
        cashFlowSummary {
          periodEndDate
          currency {
            code
            value
          }
          totalPartnerships
          activePartnerships
          liquidatedPartnerships
          activeCompanies
          committedCapital
          paidInCapital
          uncalledCapital
          distributedCapital
          netAssetValue
          grossIRR
          netIRR
        }
        cashFlowSummaryVintageYear {
          vintageYear
          cashFlowSummary {
            periodEndDate
            currency {
              code
              value
            }
            totalPartnerships
            activePartnerships
            liquidatedPartnerships
            activeCompanies
            committedCapital
            paidInCapital
            uncalledCapital
            distributedCapital
            netAssetValue
            grossIRR
            netIRR
          }
        }
      }
    }
  }
`

export const CLIENT_PORTFOLIO_SAVED_JOBS_QUERY = gql`
  query ClientPortfolioSavedJobs(
    $id: Int!
  ) {
    clientPortfolio(id: $id) {
      id
      name
      savedJobs {
        ...SumSheetSavedJobFragment
      }
    }
  }
  ${SUM_SHEET_SAVED_JOB_FRAGMENT}
`