import { CellClassParams, CellValueChangedEvent, GridApi, GridReadyEvent, IRowNode } from "@ag-grid-community/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { FetchResult, gql } from '@apollo/client'
import { cloneDeep, compact, find, findIndex, first, get, groupBy, isEqual, maxBy, reduce, set, some, sortBy, uniq, uniqBy, uniqueId } from "lodash"
import moment from "moment"
import numbro from "numbro"
import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, ButtonDropdown, Container, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, ModalHeader, Table } from "reactstrap"
import { appDate } from "../../Context/CalendarContext"
import { TemporaryAlertContext } from "../../Context/TemporaryAlertContext"
import { AssetClassAbbreviationCode, BaseCurrencyCode, CashFlowTransactionFragment, CashFlowTransactionTypeInput, CountryCode, DeleteCashFlowTransactionInput, FinancialSummaryPeriod, ListDetailFragment, PortfolioCurrencyCode, PortfolioPeriod, SumSheetSavedJobFragment, SumSheetFragment, SumSheetQuery, UniqueTransactionTypeCode, useDeleteSumSheetCashFlowMutation, useExchangeRatesQuery, useGetLookupQuery, useRunSavedJobMutation, useSumSheetCashFlowQuery, useSumSheetQuery, useUpdateSumSheetCashFlowMutation, SumSheetClientPortfolioFragment, CashFlowNetAssetValueInput, SumSheetCashFlowDocument, CashFlowMarketValueInput, useDeleteClientPortfolioCashFlowMarketValueMutation, usePerformanceOrderIdQuery, useDeletePerformanceOrderMutation, TransactionTypeFragment, useSumSheetHoverFlowLazyQuery } from "../../__generated__/graphql"
import { CashFlowColumnDef, SumSheetColumnDef } from "../../helpers/columnDefs"
import { DATE_API_FORMAT, DATE_TEXT_FORMAT } from "../../helpers/constant"
import { currencySymbolMapping, sumSheetPortfolioLinkRenderer } from "../../helpers/helpers"
import { expandList, listExpanded } from "../../helpers/list"
import { currencyWithMantissaFormat } from "../Report/Shared/ReportComponent"
import ErrorDisplay from "../Shared/ErrorDisplay"
import SortableTable from "../Shared/SortableTable"
import EditButtons from "../ui/EditButtons"
import LoadingOverlay from "../ui/Loading"
import iassign from "immutable-assign"
import stringHash from "string-hash"
import { SUM_SHEET_QUERY } from "../../queries/Report"


type DateOption = {
  period: PortfolioPeriod
  date: string
  text: string
}

interface SumSheetProps {
  auth: any
  listId: number
  reportId: number
  planId: number
}

export const SumSheet: React.FC<SumSheetProps> = (props) => {
  const { auth, reportId, planId, listId} = props
  const [search, setSearch] = useState<string>("")
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [jobDropdownOpen, setJobDropdownOpen] = useState(false)
  const [cashFlowModalOpen, setCashFlowModalOpen] = useState(false)
  const [selectedRows, setSelectedRows] = useState<ReturnColumnDataType[]>([])
  const [jobList, setJobList] = useState<SumSheetSavedJobFragment[]>([])
  const [selectedJobs, setSelectedJobs] = useState<SumSheetSavedJobFragment[] | null>(null)
  const [deletePerformanceOrder] = useDeletePerformanceOrderMutation()
  const [finishedLoad, setFinishedLoad] = useState(false)

  let dateOptions = useMemo(()=> {
    const usedAppDate = moment(appDate)
    let dateOptions = [{
      period: PortfolioPeriod.Quarterly,
      date: moment(usedAppDate).endOf("month").format(DATE_API_FORMAT),
      text: moment(usedAppDate).format("[Q]Q YYYY"),
    }, {
      period: PortfolioPeriod.Quarterly,
      date: moment(usedAppDate).subtract(1, "Q").endOf("month").format(DATE_API_FORMAT),
      text: moment(usedAppDate).subtract(1, "Q").format("[Q]Q YYYY"),
    }]

    let checkDate = moment().subtract(1, "month").endOf("month")
    let endDate = moment(usedAppDate).subtract(1, "Q").startOf("quarter")

    while(checkDate > endDate){
      dateOptions.push({
        period: PortfolioPeriod.Monthly,
        date: checkDate.format(DATE_API_FORMAT),
        text: checkDate.format("MMM YYYY"),
      })
      checkDate = checkDate.subtract(1, "month").endOf("month")
    }

    return dateOptions
  }, [appDate])
  const [selectedDate, setSelectedDate] = useState<DateOption>(dateOptions[0])

  let request = JSON.stringify({
    variables: {
      id: listId,
      showMonth: selectedDate.period === PortfolioPeriod.Monthly,
      showQuarter: selectedDate.period === PortfolioPeriod.Quarterly,
      period: selectedDate.period,
      startDate: moment(selectedDate.date).subtract(1, selectedDate.period === PortfolioPeriod.Monthly ? "months" : "quarters" ).endOf("month").format(DATE_API_FORMAT),
      endDate: selectedDate.date,
    },
    queryHash: stringHash(JSON.stringify(SUM_SHEET_QUERY))
  })

  const {data, refetch} = usePerformanceOrderIdQuery({
    variables: {
      request: request
    },
  })

  useEffect(()=>{
    if(refetch){
      refetch()
    }
  }, [selectedDate, listId])

  const cashFlowActive = selectedRows.length === 1 && selectedRows[0].id >= 0 && selectedRows[0].dataType?.code === "SUM"
  const openCashflowModal = () => {
    if(cashFlowActive){
      setCashFlowModalOpen(true)
    }
  }

  const exportToCsv = () => {
    if(!gridApi) return
    gridApi.exportDataAsCsv()
  }

  const jobTypeList = useMemo(() => {
    let usedJobList = jobList
    if(selectedRows.length > 0){
      usedJobList = (selectedRows.flatMap((row) => row.savedJobs) || []) as SumSheetSavedJobFragment[]
    }
    return groupBy(uniqBy(usedJobList, (job) => job?.id), (job) => job?.type?.code)
  }, [jobList, selectedRows])

  const refetchId = () => {
    // setRefreshing(true)
    deletePerformanceOrder({
      variables: { input: { performanceOrderID: data?.performanceOrderID } },
    })
    .then(() => {
      // refetch()
      //   .then(() => {
          setFinishedLoad(() => {
            return false
          })
        // })
    })
  }
  return (
    <>
      <div className="pane pane-toolbar sticky-top above-picker">
        <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)} className="mr-1 sumsheet-date-picker">
          <DropdownToggle caret className="text-callan-blue border-blue-80">
            {selectedDate?.text || "Select Date"}
          </DropdownToggle>
          <DropdownMenu>
            {dateOptions.map((dateOption, idx) => {
              return(
                <React.Fragment key={idx}>
                  {idx === 2 && <DropdownItem divider className='mx-3'/>}
                  <DropdownItem key={`${dateOption.text}`} onClick={() => setSelectedDate(dateOption)} className="d-flex background-white text-text-color position-relative">
                    {selectedDate.text === dateOption.text && <div className="quartile-check"><FontAwesomeIcon icon="check" /></div>}
                    <span className="ml-2 pl-1">{`${dateOption.text}`}</span>
                  </DropdownItem>
                </React.Fragment>
              )
            })}
          </DropdownMenu>
        </ButtonDropdown>
        <Form className="mr-2 pr-1 border-right">
          <FormGroup row className="relative m-0 mr-1">
            <Input
              className="wide-search"
              type="text"
              placeholder="Search by name or ID"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            <span className="o-88 absolute center-v right-1 pe-none">
              <FontAwesomeIcon icon={["fas", "search"]} size="2x" className="fontawesome-icon dark-icon-color text-gray-50" />
            </span>
          </FormGroup>
        </Form>
        <Button color={cashFlowActive ? "secondary" : "light" } id={"cashflowModal"} className=" mr-2 ml-2" onClick={() => openCashflowModal()}>
          <span className={classNames({"text-gray-70": !cashFlowActive, "text-callan-blue": cashFlowActive})}>
            Cash Flows
            <FontAwesomeIcon icon="pencil" className="ml-2" />
          </span>
        </Button>
        {selectedRows.length === 1 &&
          <CashFlowModal
            isOpen={cashFlowModalOpen}
            toggle={() => setCashFlowModalOpen(!cashFlowModalOpen)}
            selectedRow={selectedRows[0]}
            period={selectedDate.period}
            date={selectedDate.date}
          />
        }
        <ButtonDropdown isOpen={jobDropdownOpen} toggle={() => setJobDropdownOpen(!jobDropdownOpen)} className="ml-1 btn-thin">
          <DropdownToggle caret className="mt-0 text-callan-blue border-blue-80">
            Run Job
          </DropdownToggle>
          <DropdownMenu>
            {Object.keys(jobTypeList).map((jobType) => {
              let job = jobTypeList[jobType][0]
              return(
                <DropdownItem key={jobType} onClick={() => setSelectedJobs(jobTypeList[jobType])} className="d-flex background-white text-text-color position-relative">
                  <span className="ml-2 pl-1">{`${job?.type?.value}`}</span>
                </DropdownItem>
              )
            })}
            {jobList.length === 0 && <DropdownItem disabled>No Jobs Available</DropdownItem>}
          </DropdownMenu>
        </ButtonDropdown>
        <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={exportToCsv}>
          Export CSV
          <img src='/assets/CSV.svg' className="ml-2"/>
        </Button>
        <Button color="light" className="mr-2 text-callan-blue border-blue-80 btn-thin" onClick={() => {if(refetchId) refetchId()}}>
          <FontAwesomeIcon
              icon={["fas", "sync"]}
              size="lg"
              className="text-callan-blue cursor-pointer"
            />
        </Button>
      </div>
      <JobModal
        isOpen={!!selectedJobs}
        toggle={() => setSelectedJobs(null)}
        jobs={selectedJobs || undefined}
        planId={planId}
        date={selectedDate.date}
        autoRun={selectedRows.length > 0}
      />
      {data?.performanceOrderID && <SumSheetFetch
        date={selectedDate.date}
        period={selectedDate.period}
        search={search}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        jobList={jobList}
        setJobList={setJobList}
        setGridApi={setGridApi}
        orderId={data.performanceOrderID}
        finishedLoad={finishedLoad}
        setFinishedLoad={setFinishedLoad}
        {...props}
      />}
      {!data && <LoadingOverlay />}
    </>
  )
}

interface SumSheetFetchProps extends SumSheetProps {
  date: string
  period: PortfolioPeriod
  search: string
  selectedRows: ReturnColumnDataType[]
  setSelectedRows: (rows: ReturnColumnDataType[]) => void
  jobList: SumSheetSavedJobFragment[]
  setJobList: (jobList: SumSheetSavedJobFragment[]) => void
  setGridApi: (api: GridApi) => void
  orderId: number
  finishedLoad: boolean
  setFinishedLoad: React.Dispatch<React.SetStateAction<boolean>>
}

export const SumSheetFetch: React.FC<SumSheetFetchProps> = (props) => {
  const { auth, listId, reportId, search, period, date, selectedRows,  setSelectedRows, jobList, setJobList, setGridApi, orderId, finishedLoad, setFinishedLoad} = props
  const [refreshing, setRefreshing] = useState(false)

  const { data, loading, error, stopPolling, startPolling, refetch } = useSumSheetQuery({
    variables: {
      id: listId,
      orderId,
      showMonth: period === PortfolioPeriod.Monthly,
      showQuarter: period === PortfolioPeriod.Quarterly,
      startDate: moment(date).subtract(1, period === PortfolioPeriod.Monthly ? "months" : "quarters" ).endOf("month").format(DATE_API_FORMAT),
      endDate: date,
    },
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    pollInterval: 5000,
    onCompleted: () => {
      setRefreshing(false)
    }
  })

  const allDone = (data: SumSheetQuery) => {
    return !some(data.list?.items, (item) => {
      if(item.item?.__typename === "ClientPortfolio") {
        const portfolio = item.item
        let returned = false
        let usedKey = period === PortfolioPeriod.Monthly ? "monthPerformance" : "quarterPerformance"
        returned = some(get(portfolio, usedKey), (performance) => {
          const done = !performance || performance.status === "Done"
          return !done
        })
        // if(!returned){
        //   returned = some(get(portfolio.relatedVehicle?.vehicle, usedKey), (performance) => {
        //     const done = !performance || performance.status === "Done"
        //     return !done
        //   })
        // }
        if(!returned){
          returned = some(portfolio.performanceTargetMap, (performanceTarget) => {
            return some(get(performanceTarget?.target, usedKey), (performance) => {
              const done = !performance || performance.status === "Done"
              return !done
            })
          })
        }
        return returned
      }
    })
  }

  if((data && allDone(data) && !loading && !refreshing) || error){
    stopPolling()
    if(!finishedLoad){
      setFinishedLoad(() => {
        return true
      })
    }
  }

  useEffect(() => {
    if(!finishedLoad){
      refetch()
      startPolling(5000)
      setRefreshing(true)
    }
  }, [finishedLoad])

  if(data?.list?.items){
    return (
      <SumSheetDisplay
        auth={auth}
        reportId={reportId}
        data={data.list}
        search={search}
        period={period}
        date={date}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        jobList={jobList}
        setJobList={setJobList}
        setGridApi={setGridApi}
        refreshing={refreshing}
      />
    )
  } else if (error) {
    return (
      <ErrorDisplay error={error}/>
    )
  }
  return (
    <LoadingOverlay/>
  )
}

interface SumSheetDisplayProps {
  auth: any
  reportId: number
  data: SumSheetQuery["list"]
  search: string
  period: PortfolioPeriod
  date: string
  setSelectedRows?:(rows: ReturnColumnDataType[]) => void
  selectedRows?: ReturnColumnDataType[]
  jobList: SumSheetSavedJobFragment[]
  setJobList: (jobList: SumSheetSavedJobFragment[]) => void
  setGridApi: (api: GridApi) => void
  refreshing: boolean
}

export type ReturnColumnDataType = SumSheetClientPortfolioFragment & {
  rowId: string
}

export const SumSheetDisplay: React.FC<SumSheetDisplayProps> = (props) => {
  const { auth, reportId, data, search, period, date, selectedRows, setSelectedRows, jobList, setJobList, setGridApi, refreshing} = props

  const [getHoverValues, { loading:historicCashflowLoading, error:historicCashflowError, data:historicCashflowData }] = useSumSheetHoverFlowLazyQuery({
    fetchPolicy: "no-cache",
  })

  const columnData = useMemo(() => {
    // const expandedList = expandReportList(data as ReportsListFragment, {appendUnusedGroups: true, excludeList: first(data?.excludeList)  as ReportsListFragment})
    const expandedList = expandList(data as ListDetailFragment, {appendUnusedGroups: true, excludeList: first(data?.excludeList) as ListDetailFragment})

    const iterateList = (list: listExpanded[], hierarchy: string[]):any => {
      return list.flatMap(item => {
        const name = item.item.name || item.item.text || ""
        let baseItems:any[] = []
        if(isEqual(hierarchy, ["Unordered Portfolios"]) && name === "") return baseItems
        baseItems.push({
          group: [...hierarchy, name],
          uniqId: item.uniqId,
          ...item.item
        })

        if(item.subGroup){
          return [...baseItems, ...iterateList(item.subGroup, [...hierarchy, name])]
        }
        return baseItems
      })
    }

    return iterateList(expandedList, [])
  }, [data])
  const [currentColumnData, setCurrentColumnData] = useState<ReturnColumnDataType[]>(columnData)

  useEffect(() => {
    setCurrentColumnData(cloneDeep(columnData))
    let jobList = reduce(columnData, (acc, item) => {
      if(item.savedJobs){
        return uniq(compact(acc.concat(item.savedJobs)))
      }
      return acc
    },
    [])
    setJobList(jobList)
  }, [columnData])

  const onReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    // params.api.forEachNode((node:IRowNode) => {
    //   var hoverFetch = () => {
    //     getHoverValues({variables: {
    //       id: node.data.id,
    //       endDate: date,
    //       showMonth: period === PortfolioPeriod.Monthly,
    //       showQuarter: period === PortfolioPeriod.Quarterly,
    //     }}).then((result) => {
    //       if(result && result.data?.clientPortfolio?.id === node.data.id){
    //         let nodeData = cloneDeep(node.data)
    //         set(nodeData, 'monthCashFlows', result.data?.clientPortfolio?.monthCashFlows)
    //         set(nodeData, 'quarterCashFlows', result.data?.clientPortfolio?.quarterCashFlows)
    //         node.setData(nodeData)
    //       }
    //     })
    //   }

    //   if(node.rowIndex !== null){
    //     node.addEventListener('mouseEnter', hoverFetch)
    //     params.api.addRenderedRowListener('virtualRowRemoved', node.rowIndex, () => {
    //       node.removeEventListener('mouseEnter', hoverFetch)
    //     })
    //   }
    // })
  }

  const getDataPath = useCallback((data) => {
    return data.group;
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: 'Portfolio',
      minwidth: 300,
      width: 340,
      sortable: true,
      cellRendererParams: {
        suppressCount: true,
        innerRenderer: sumSheetPortfolioLinkRenderer,
        checkbox: true,
      },
      cellClass: (params: CellClassParams) => {
        if(params.data.group && params.data.group.length > 1){
          return ''
        } else {
          return 'font-weight-bold';
        }
      },
    };
  }, []);

  const colDef = SumSheetColumnDef(period, date, refreshing)
  return (
    <Container fluid className="d-flex flex-grow-1 flex-direction-column px-0">
      {/* <RouteLeavingGuard
        when={editMode && valuesChanged}
        navigate={path => history.push(path)}
      /> */}
      <SortableTable
        key={`${period}-${date}`}
        loading={false}
        filterText={search}
        columnDefs={colDef}
        tableData={currentColumnData}
        rowId={"uniqId"}
        setSelectedRows={setSelectedRows}
        onReady={onReady}
        // onCellValueChanged={onCellValueChanged}
        treeData={true}
        getDataPath={getDataPath}
        noPagination
        autoGroupColumnDef={autoGroupColumnDef}
      />
    </Container>
  )
}

export interface CashFlowModalProps {
  isOpen: boolean
  toggle: () => void
  // data: SumSheetFragment
  period: PortfolioPeriod
  date: string
  selectedRow: ReturnColumnDataType
}

export type CashFlowDataType = CashFlowTransactionFragment & {
  rowId: string
  touched: boolean
  transactionType: UniqueTransactionTypeCode | "ending-assets"
  convertedAmount?: number
  monthCashFlows?: any[]
  quarterCashFlows?: any[]
}

export type TransactionTypeWithTitles = TransactionTypeFragment | {
  name: string
  code: string
  title?: boolean
  fee?: number
  cashEffect?: number
}

const netCashTypeMapping:{[key in UniqueTransactionTypeCode | "ending-assets"]: "+"|"-"|undefined} = {
  "C": "+",
  "CO": "+",
  "OR": "-",
  "TI": "+",
  "TR": "-",
  "EI": "+",
  "ER": "-",
  "D": "-",
  "CD": "+",
  "RC": "-",
  "DR": "-",
  "RD": "+",
  "DU": "-",
  "TO": "-",
  "TA": "+",
  "EO": "-",
  "EC": "+",
  "F": "-",
  "RF": "+",
  "FO": "-",
  "RO": "+",
  "FE": "-",
  "RE": "+",
  "CI": "-",
  "CL": "+",
  "I": undefined,
  "IA": undefined,
  "IL": undefined,
  "IP": undefined,
  "RA": undefined,
  "RI": undefined,
  "ending-assets": undefined,
}

export const CashFlowModal: React.FC<CashFlowModalProps> = (props) => {
  const {isOpen, toggle, date, period, selectedRow: selectedPortfolio} = props
  const [gridApi, setGridApi] = useState<GridApi | null>(null)
  const [selectedRows, setSelectedRows] = useState<CashFlowDataType[]>([])
  const [saving, setSaving] = useState(false)
  const [outstandingCalls, setOutstandingCalls] = useState(0)
  const [updateSumSheetCashFlow] = useUpdateSumSheetCashFlowMutation()
  const [deleteSumSheetCashFlow] = useDeleteSumSheetCashFlowMutation()
  const [deleteClientPortfolioCashFlowMarketValue] = useDeleteClientPortfolioCashFlowMarketValueMutation()

  const {data, error, loading} = useSumSheetCashFlowQuery({
    variables: {
      id: selectedPortfolio.id,
      date: date,
      showMonth: period === PortfolioPeriod.Monthly,
      showQuarter: period === PortfolioPeriod.Quarterly,
    },
    errorPolicy: "all",
  })


  // let firstDate = reduce(columnData, (acc, transaction) => {
  //   if(!acc || moment(transaction.adjustedTransactionDate).isBefore(acc)) return transaction.adjustedTransactionDate
  //   return acc
  // }, moment(date).subtract(1, period === PortfolioPeriod.Monthly ? "months" : "quarters" ).endOf("month").format(DATE_API_FORMAT))
  let firstDate = moment(date).subtract(1, period === PortfolioPeriod.Monthly ? "months" : "quarters" ).endOf("month").format(DATE_API_FORMAT)

  const {data: exchangeRateData, error: exchangeRateError, loading: exchangeRateLoading } = useExchangeRatesQuery({
    variables: {
      filters: {
        startDate: firstDate,
        endDate: date,
        currencyAbbr: data?.clientPortfolio?.baseCurrency?.code || BaseCurrencyCode.USADOL,
      }
    },
    errorPolicy: "all",
    skip: !data?.clientPortfolio?.baseCurrency?.code //|| data?.clientPortfolio?.baseCurrency?.code === "USADOL",
  })

  const endDate = moment(date)
  const startDate = moment(date).subtract(1, period === PortfolioPeriod.Monthly ? "months" : "quarters").endOf("month")
  const startingExchangeRate = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === startDate.format(DATE_API_FORMAT))?.rate || undefined
  const endingExchangeRate = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === endDate.format(DATE_API_FORMAT))?.rate || undefined

  const [columnData, usedCashFlows, investmentGainLoss, convertedInvestmentGainLoss] = useMemo(() => {
    if(loading || !data){
      return [[], undefined, 0, 0]
    }
    let allTransactions:CashFlowDataType[] = []
    let usedCashFlows = period === PortfolioPeriod.Monthly ? data.clientPortfolio?.monthCashFlows : data.clientPortfolio?.quarterCashFlows
    let investmentGainLoss = 0
    let convertedInvestmentGainLoss = 0
    usedCashFlows?.cashFlowTransactionTypes?.forEach((type) => {
      if(!type?.transactions) return
      allTransactions = allTransactions.concat(compact(type?.transactions?.map((transaction) => {
        if(!transaction || !moment(transaction.periodEndDate).isBetween(startDate, endDate, "D", "(]")) return null
        let matchedExchangeRateData = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === transaction.adjustedTransactionDate)
        return ({
          rowId: `${type.transactionType.code}:${transaction.transactionDate}:${transaction.periodEndDate}:${transaction.transactionNumber}`,
          touched: false,
          transactionType: type.transactionType.code,
          convertedAmount: !!matchedExchangeRateData?.rate ? (transaction.amount || 0) * matchedExchangeRateData.rate : undefined,
          ...transaction,
        })
      })))
    })

    let {netCashActivity, convertedNetCashActivity} = allTransactions.reduce((acc, transaction) => {
      if(transaction.transactionType && netCashTypeMapping[transaction.transactionType]){
        acc.netCashActivity += netCashTypeMapping[transaction.transactionType] === "+" ? transaction.amount : -transaction.amount
        acc.convertedNetCashActivity += netCashTypeMapping[transaction.transactionType] === "+" ? (transaction.convertedAmount || 0) : -(transaction.convertedAmount || 0)
      }
      return acc
    }, {netCashActivity: 0, convertedNetCashActivity: 0})
    investmentGainLoss = (usedCashFlows?.endingNetAssetValue?.amount || 0) - (netCashActivity + (usedCashFlows?.beginningNetAssetValue?.amount || 0))
    // const convertedEndingNetAssetValue = endingExchangeRate ? (usedCashFlows?.endingNetAssetValue?.amount || 0) * endingExchangeRate : undefined
    // const convertedStartingNetAssetValue = startingExchangeRate ? (usedCashFlows?.endingNetAssetValue?.amount || 0) * startingExchangeRate : undefined
    // convertedInvestmentGainLoss = (convertedEndingNetAssetValue) - (convertedNetCashActivity + (convertedStartingNetAssetValue))

    // add ending assets
    usedCashFlows?.marketValues?.forEach((marketValue) => {
      if(!marketValue || marketValue.date === startDate.format(DATE_API_FORMAT)) return
      let matchedExchangeRateData = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === marketValue.date)
      const { assetClassAbbreviation, __typename, amount, ...rest } = marketValue
      allTransactions.unshift({
        rowId: `ending-assets:${marketValue.date}`,
        touched: false,
        transactionType: "ending-assets",
        convertedAmount: !!matchedExchangeRateData?.rate ? (marketValue.amount || 0) * matchedExchangeRateData.rate : undefined,
        adjustedTransactionDate: marketValue.date,
        assetClassAbbreviation: { code: assetClassAbbreviation as AssetClassAbbreviationCode, __typename: "AssetClassAbbreviationLookup" },
        transactionDate: marketValue.date,
        periodEndDate: marketValue.date,
        transactionNumber: -1,
        monthCashFlows: [],
        quarterCashFlows: [],
        amount: marketValue.amount + (marketValue.accruedInterests || 0),
        __typename: "CashFlowTransaction" as "CashFlowTransaction",
        ...rest,
      })
    })

    return [allTransactions, usedCashFlows, investmentGainLoss, convertedInvestmentGainLoss]
  }, [data, loading, exchangeRateData])
  const [currentColumnData, setCurrentColumnData] = useState<CashFlowDataType[]>(columnData)

  let {netCashActivity, convertedNetCashActivity} = currentColumnData.reduce((acc, transaction) => {
    if(transaction.transactionType && netCashTypeMapping[transaction.transactionType]){
      acc.netCashActivity += netCashTypeMapping[transaction.transactionType] === "+" ? transaction.amount : -transaction.amount
      acc.convertedNetCashActivity += netCashTypeMapping[transaction.transactionType] === "+" ? (transaction.convertedAmount || 0) : -(transaction.convertedAmount || 0)
    }
    return acc
  }, {netCashActivity: 0, convertedNetCashActivity: 0})

  useEffect(() => {
    setCurrentColumnData(cloneDeep(columnData))
  }, [columnData])

  const onReady = (params: GridReadyEvent) => {
    setGridApi(params.api)
    params.columnApi.applyColumnState({state: [
      {colId: 'transactionDate', sort:"desc"},
    ]})
  }


  const onCellValueChanged = (params: CellValueChangedEvent) => {
    let updatedData = cloneDeep(currentColumnData)
    const row = find(updatedData, {rowId: params.data.rowId})
    if(row){
      row.touched = true
      let exchangeRate = exchangeRateData?.exchangeRate?.find((rate) => rate?.date === row.adjustedTransactionDate)?.rate
      row.convertedAmount = exchangeRate ? row.amount * exchangeRate : undefined
      setCurrentColumnData(updatedData)
    }
  }

  const addActive = !saving
  const addRow = () => {
    if(!addActive) return
    const rowId = uniqueId()
    let newRow: CashFlowDataType = {
      rowId: `${rowId}`,
      adjustedTransactionDate: date,
      transactionDate: date,
      assetClassAbbreviation: {
        code: data?.clientPortfolio?.cashFlowDefaultKeys?.assetClassAbbreviation || AssetClassAbbreviationCode.ALL,
        __typename: "AssetClassAbbreviationLookup",
      },
      amount: 0,
      convertedAmount: 0,
      country: {
        code: data?.clientPortfolio?.cashFlowDefaultKeys?.country || CountryCode.USA,
        __typename: "PortfolioCountryLookup",
      },
      currency: {
        code: data?.clientPortfolio?.cashFlowDefaultKeys?.currency || PortfolioCurrencyCode.USADOL,
        __typename: "PortfolioCurrencyLookup",
      },
      periodEndDate: date,
      touched: true,
      transactionNumber: -1,
      transactionType: "ending-assets",
      __typename: "CashFlowTransaction",
    }
    setCurrentColumnData([newRow, ...currentColumnData])
    if(gridApi){
      gridApi.paginationGoToPage(0);
      gridApi.ensureIndexVisible(0, "top")
    }
  }

  const deleteActive = !saving && selectedRows.length > 0
  const deleteRows = () => {
    if(!deleteActive) return
    setCurrentColumnData(currentColumnData.filter((item) => !selectedRows.some((row) => row.rowId === item.rowId)))
  }

  const resetModal = () => {
    setSelectedRows([])
    setSaving(false)
    setOutstandingCalls(0)
    setCurrentColumnData(cloneDeep(columnData))
    toggle()
  }

  const handleSubmit = () => {
    setSaving(true)
    let upsertTransactions:CashFlowTransactionTypeInput[] = []
    let deleteTransactions:DeleteCashFlowTransactionInput[] = []
    let deleteMarketTransactions:CashFlowMarketValueInput[] = []
    let marketValues:CashFlowNetAssetValueInput[] = []
    // [{
    //   date: date,
    //   amount: netCashActivity + (usedCashFlows?.beginningNetAssetValue?.amount || 0) + investmentGainLoss,
    //   assetClassAbbreviation: data?.clientPortfolio?.cashFlowDefaultKeys?.assetClassAbbreviation || AssetClassAbbreviationCode.ALL,
    //   country: data?.clientPortfolio?.cashFlowDefaultKeys?.country || CountryCode.USA,
    //   currency: data?.clientPortfolio?.cashFlowDefaultKeys?.currency || PortfolioCurrencyCode.USADOL,
    // }]
    currentColumnData.forEach((item) => {
      if(item.transactionType === "ending-assets"){
        if(item.touched){
          marketValues.push({
            date: item.adjustedTransactionDate,
            amount: item.amount,
            assetClassAbbreviation: item.assetClassAbbreviation?.code,
            country: item.country.code,
            currency: item.currency.code,
          })
        }
        return
      }
      const originalRow = find(columnData, {transactionDate: item.transactionDate, transactionNumber: item.transactionNumber})
      if(item.transactionNumber === -1 || !originalRow){
        // New Record
        const matchedIndex = findIndex(upsertTransactions, {transactionType: item.transactionType})
        let matchedType
        if(matchedIndex !== -1){
          matchedType = cloneDeep(upsertTransactions[matchedIndex])
        } else {
          matchedType = {
            transactionType: item.transactionType,
            transactions: [],
          }
        }
        matchedType.transactions?.push({
          transactionDate: item.transactionDate,
          assetClassAbbreviation: item.assetClassAbbreviation?.code,
          amount: item.amount,
          country: item.country?.code,
          currency: item.currency?.code,
          periodEndDate: item.periodEndDate,
        })
        if(matchedIndex !== -1){
          upsertTransactions[matchedIndex] = matchedType
        } else {
          upsertTransactions.push(matchedType)
        }
      } else if (originalRow && JSON.stringify(item) !== JSON.stringify(originalRow)){
        // Update Record
        const matchedIndex = findIndex(upsertTransactions, {transactionType: item.transactionType})
        let matchedType
        if(matchedIndex !== -1){
          matchedType = cloneDeep(upsertTransactions[matchedIndex])
        } else {
          matchedType = {
            transactionType: item.transactionType,
            transactions: [],
          }
        }
        matchedType.transactions?.push({
          transactionNumber: item.transactionNumber,
          transactionDate: item.transactionDate,
          assetClassAbbreviation: item.assetClassAbbreviation?.code,
          amount: item.amount,
          country: item.country?.code,
          currency: item.currency?.code,
          periodEndDate: item.periodEndDate,
        })
        if(matchedIndex !== -1){
          upsertTransactions[matchedIndex] = matchedType
        } else {
          upsertTransactions.push(matchedType)
        }
      }
    })
    columnData.forEach((item) => {
      if(item.transactionType === "ending-assets"){
        if(!find(currentColumnData, (currentItem) => { return item.transactionDate === currentItem.transactionDate && currentItem.transactionType === "ending-assets"})){
          // Deleted Record
          deleteMarketTransactions.push({
            date: item.transactionDate,
            assetClassAbbreviation: item.assetClassAbbreviation?.code,
            country: item.country.code,
            currency: item.currency.code,
          })
        }
      } else {
        if(!find(currentColumnData, (currentItem) => { return item.transactionDate === currentItem.transactionDate && item.transactionNumber === currentItem.transactionNumber && currentItem.transactionType !== "ending-assets"})){
          // Deleted Record
          deleteTransactions.push({
            periodEndDate: item.periodEndDate,
            transactionDate: item.transactionDate,
            transactionNumber: item.transactionNumber,
          })
        }
      }
    })
    const updateClientPortfolio = upsertTransactions.length > 0 || marketValues.length > 0
    setOutstandingCalls((updateClientPortfolio ? 1 : 0) + (deleteTransactions.length > 0 ? 1 : 0) + (deleteMarketTransactions.length > 0 ? 1 : 0))
    if(updateClientPortfolio){
      updateSumSheetCashFlow({
        variables: {
          input: {
            id: selectedPortfolio.id,
            patch: {
              cashFlows: {
                cashFlowTransactionTypes: upsertTransactions,
                marketValues: marketValues,
              }
            }
          },
          date: date,
          showMonth: period === PortfolioPeriod.Monthly,
          showQuarter: period === PortfolioPeriod.Quarterly,
        }
      }).then(result => {
        setOutstandingCalls((calls) => calls - 1)
      })
      .catch(err => {
        setOutstandingCalls((calls) => calls - 1)
        setSaving(false)
        console.error("Error updating sum sheet cash flow", err.message)
      })
    }
    if(deleteTransactions.length > 0){
      deleteSumSheetCashFlow({
        variables: {
          input: {
            id: selectedPortfolio.id,
            transactions: deleteTransactions,
          },
        },
        update: (cache) => {
          const portfolio: any = cache.readQuery({
            query: SumSheetCashFlowDocument,
            variables: {
              id: selectedPortfolio.id,
              date: date,
              showMonth: period === PortfolioPeriod.Monthly,
              showQuarter: period === PortfolioPeriod.Quarterly,
            },
          })
          let usedKey = period === PortfolioPeriod.Monthly ? "monthCashFlows" : "quarterCashFlows"
          let updatedPortfolio = iassign(
            portfolio,
            [`clientPortfolio`,usedKey,`cashFlowTransactionTypes`],
            (transactions) => {
              let rows = cloneDeep(transactions as any[])
              return rows.map((row) => {
                let usedTransactions = row.transactions.filter((transaction:any) => !find(deleteTransactions, {transactionDate: transaction.transactionDate, transactionNumber: transaction.transactionNumber}))
                return {
                  ...row,
                  transactions: usedTransactions,
                }
              })
            }
          )
          cache.writeQuery({
            query: SumSheetCashFlowDocument,
            variables: {
              id: selectedPortfolio.id,
              date: date,
              showMonth: period === PortfolioPeriod.Monthly,
              showQuarter: period === PortfolioPeriod.Quarterly,
            },
            data: updatedPortfolio,
          })
        }
      }).then(result => {
        setOutstandingCalls((calls) => calls - 1)
      })
      .catch(err => {
        setOutstandingCalls((calls) => calls - 1)
        setSaving(false)
        console.error("Error deleting sum sheet cash flow", err.message)
      })
    }
    if(deleteMarketTransactions.length > 0){
      deleteClientPortfolioCashFlowMarketValue({
        variables: {
          input: {
            id: selectedPortfolio.id,
            marketValues: deleteMarketTransactions,
          },
        },
        update: (cache) => {
          const portfolio: any = cache.readQuery({
            query: SumSheetCashFlowDocument,
            variables: {
              id: selectedPortfolio.id,
              date: date,
              showMonth: period === PortfolioPeriod.Monthly,
              showQuarter: period === PortfolioPeriod.Quarterly,
            },
          })
          let usedKey = period === PortfolioPeriod.Monthly ? "monthCashFlows" : "quarterCashFlows"
          let updatedPortfolio = iassign(
            portfolio,
            [`clientPortfolio`,usedKey,"marketValues"],
            (transactions) => {
              let rows = cloneDeep(transactions as any[])
              return rows.map((row) => {
                let usedTransactions = row.transactions.filter((transaction:any) => !find(deleteTransactions, {transactionDate: transaction.transactionDate, transactionType: "ending-assets"}))
                return {
                  ...row,
                  transactions: usedTransactions,
                }
              })
            }
          )
          cache.writeQuery({
            query: SumSheetCashFlowDocument,
            variables: {
              id: selectedPortfolio.id,
              date: date,
              showMonth: period === PortfolioPeriod.Monthly,
              showQuarter: period === PortfolioPeriod.Quarterly,
            },
            data: updatedPortfolio,
          })
        }
      }).then(result => {
        setOutstandingCalls((calls) => calls - 1)
      })
      .catch(err => {
        setOutstandingCalls((calls) => calls - 1)
        setSaving(false)
        console.error("Error deleting sum sheet cash flow", err.message)
      })
    }
  }

  useEffect(() => {
    if(outstandingCalls <= 0 && saving){
      setSaving(false)
      toggle()
    }
  }, [outstandingCalls, saving])

  let largeTransactionWarning = some(currentColumnData, (item) => {
    if(usedCashFlows?.beginningNetAssetValue?.amount && item.transactionType !== "ending-assets" && item.amount > usedCashFlows?.beginningNetAssetValue?.amount/10){
      return true
    }
    return false
  })

  let bodyContent = <></>
  if(loading || exchangeRateLoading){
    bodyContent = <LoadingOverlay />
  } else if(!data && error){
    bodyContent = <ErrorDisplay error={error} />
  } else if(!!usedCashFlows){
    const usedSymbol = currencySymbolMapping[data?.clientPortfolio?.baseCurrency?.code || BaseCurrencyCode.USADOL]
    const nonUsSymbol = data?.clientPortfolio?.baseCurrency?.code !== BaseCurrencyCode.USADOL || data?.clientPortfolio?.baseCurrency?.code === null
    let transactionTitles = [
      {name: "Inflow", cashEffect: 1, fees: 0, title: true, code: "title-inflow"},
      {name: "Inflow of Fees", cashEffect: 1, fees: 1, title: true, code: "title-inflow-fees"},
      {name: "Outflow", cashEffect: -1, fees: 0, title: true, code: "title-outflow"},
      {name: "Outflow of Fees", cashEffect: -1, fees: 1, title: true, code: "title-outflow-fees"},
      {name: "Income", cashEffect: 0, fees: null, title: true, code: "title-income"}]
    const transactionTypeMapping = sortBy(data?.transactionTypeMap, "order")?.flatMap((type) => {
      let foundTitleIdx = findIndex(transactionTitles, (title) => title.cashEffect === type?.cashEffect && (title.fees === type?.fees || title.fees === null))
      if(foundTitleIdx !== -1){
        const title = transactionTitles.splice(foundTitleIdx,1)[0]
        return [
          title,
          type
        ]
      }
      return type
    })
    const frequentlyUsed = [
      {name: "Frequently Used", cashEffect: 1, fees: 0, title: true, code: "title-frequently-used"},
      {name: "Called Capital", cashEffect: 1, fees: 0, code: "frequently-C"},
      {name: "End of Day Transfer In", cashEffect: 1, fees: 0, code: "frequently-EI"},
      {name: "Distributed Capital", cashEffect: -1, fees: 0, code: "frequently-D"},
      {name: "End of Day Transfer Out", cashEffect: -1, fees: 0, code: "frequently-EO"},
      {name: "Management Fee", cashEffect: -1, fees: 1, code: "frequently-F"},
      {name: "Return of Management Fee", cashEffect: 1, fees: 1, code: "frequently-RF"},
      {name: "Income Purchase", cashEffect: 0, fees: 0, code: "frequently-IP"},
      {name: "Income-Loss", cashEffect: 0, fees: 0, code: "frequently-IL"},
    ]
    let finalTypes = compact([{name: "Assets", title: true, code: "title-assets"}, {name: "Ending Assets", code: "ending-assets"},...frequentlyUsed,...transactionTypeMapping])
    const colDef = CashFlowColumnDef(setCurrentColumnData, finalTypes, data?.clientPortfolio?.baseCurrency?.code || BaseCurrencyCode.USADOL, startDate.add(1, "day").format(DATE_API_FORMAT), endDate.format(DATE_API_FORMAT), (usedCashFlows?.beginningNetAssetValue?.amount || 0 )/10)
    let endingAssets = currentColumnData.find((item) => item.transactionType === "ending-assets" && item.transactionDate === date)?.amount
    let convertedEndingAssets = (endingAssets !== undefined && endingExchangeRate !== undefined) ? endingAssets * endingExchangeRate : undefined
    bodyContent = <div className="p-0 w-100 view-port-60 d-flex flex-grow-1 flex-direction-column">
      <div className="w-100 p-2 border-left border-right border-top">
        <Button color={addActive ? "secondary" : "light"!} className="mr-auto" onClick={() => addRow()}>
          <span className={classNames({"text-gray-70": !addActive})}>
            Add Row
          </span>
          <FontAwesomeIcon
            icon="plus-circle"
            className={classNames("ml-2", {"text-blue-100": addActive, "text-gray-70": !addActive})}
          />
        </Button>
        <Button color={deleteActive ? "secondary" : "light" } className="mr-auto ml-2" onClick={() => deleteRows()}>
          <span className={classNames({"text-gray-70": !deleteActive})}>
            Delete Row
          </span>
          <FontAwesomeIcon
            icon="trash"
            className={classNames("ml-2", {"text-blue-100": deleteActive, "text-gray-70": !deleteActive})}
          />
        </Button>
      </div>
      <SortableTable
        key={`${period}-${date}`}
        loading={false}
        filterText={""}
        columnDefs={colDef}
        tableData={currentColumnData}
        rowId={"rowId"}
        editMode={true}
        setSelectedRows={setSelectedRows}
        onReady={onReady}
        onCellValueChanged={onCellValueChanged}
        noPagination
      />
      <div className="d-flex flex-direction-column align-items-end background-blue-30 p-2">
        <div className={classNames({"w-37": !nonUsSymbol, "w-50": nonUsSymbol})}>
          <Table>
            <tbody>
              <tr>
                <td className="border-0 text-left px-1"><div className="font-weight-bold">Net Cash Activity</div></td>
                <td className={classNames("border-0 text-right px-1",{"negative-value": (!!netCashActivity && netCashActivity < 0)})}><div>{numbro(netCashActivity).format({...currencyWithMantissaFormat, currencySymbol: usedSymbol})}</div></td>
                {(data?.clientPortfolio?.baseCurrency?.code !== BaseCurrencyCode.USADOL) && <td className={classNames("border-0 text-right px-1",{"negative-value": (!!convertedNetCashActivity && convertedNetCashActivity < 0)})}><div className="ml-1">{numbro(convertedNetCashActivity).format(currencyWithMantissaFormat)}</div></td>}
              </tr>
              <tr>
                <td className="border-0 text-left px-1"><div className="font-weight-bold">Ending Assets</div></td>
                <td className={classNames("border-0 text-right px-1",{"negative-value": (!!endingAssets && endingAssets < 0)})}><div>{endingAssets === undefined ? "-" : numbro(endingAssets).format({...currencyWithMantissaFormat, currencySymbol: usedSymbol})}</div></td>
                {(data?.clientPortfolio?.baseCurrency?.code !== BaseCurrencyCode.USADOL) && <td className={classNames("border-0 text-right px-1",{"negative-value": (!!convertedNetCashActivity && convertedNetCashActivity < 0)})}><div className="ml-1">{convertedEndingAssets === undefined ? "-" : numbro(convertedEndingAssets).format(currencyWithMantissaFormat)}</div></td>}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  }

  return (
    <Modal size="lg" isOpen={isOpen} toggle={resetModal} zIndex={1500}>
      <ModalHeader className="fee-modal-header full-width-header">
        {largeTransactionWarning && <div className="text-white background-orange-100 modal-header-warning font-weight-normal">A transaction is more than 10% of the beginning market value. Revalue the portfolio.</div>}
        <div className='d-flex justify-content-between'>
          <div>Cash Flows</div>
          <div onClick={() => toggle()}>
            <FontAwesomeIcon icon='times' className='ml-auto' />
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="pt-0">
        <div>
          <div className="py-2 font-size-16">
            <div className="font-weight-bold">{selectedPortfolio.name}</div>
            <div className="d-block">{period === PortfolioPeriod.Quarterly ? "Quarter" : "Month"} Ending {moment(date).format(DATE_TEXT_FORMAT)}</div>
          </div>
        </div>
        {bodyContent}
      </ModalBody>
      <ModalFooter className={classNames({"justify-content-end": true})}>
        {/* <Button color="secondary btn-thin" className="ml-1 text-callan-blue" onClick={()=> exportTables()}>
          Export CSV
          <img src='/assets/CSV.svg' className="ml-2"/>
        </Button> */}
        <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => resetModal()} saving={saving} onSubmit={handleSubmit} disableOnError={true}/>
      </ModalFooter>
    </Modal>
  )
}

interface JobModalProps {
  isOpen: boolean
  toggle: () => void
  jobs?: SumSheetSavedJobFragment[]
  planId: number
  date: string
  autoRun: boolean
}

export const JobModal: React.FC<JobModalProps> = (props) => {
  const {isOpen, toggle, jobs, planId, date, autoRun} = props
  const [saving, setSaving] = useState(false)
  const [outstandingCalls, setOutstandingCalls] = useState(0)
  const [runSavedJob] = useRunSavedJobMutation()
  const { addAlert } = useContext(TemporaryAlertContext)
  const job = jobs?.[0]

  const resetModal = () => {
    setSaving(false)
    setOutstandingCalls(0)
    toggle()
  }

  useEffect(() => {
    if(outstandingCalls <= 0 && saving){
      resetModal()
      addAlert({message: <>The {job?.type?.value} jobs have been queued. View job status in the <span className="cursor-pointer text-decoration-underline" onClick={() => window.open(`/plans/${planId}/activities` ,'_blank')}>Plan's Activities.</span></>, timeout: 5000, color: "jobSuccess"})
    }
  }, [outstandingCalls])

  const runJob = () => {
    if(!job?.id) return
    setSaving(true)
    setOutstandingCalls(jobs?.length || 0)
    jobs?.forEach((job) => {
      runSavedJob({
        variables: {
          input: {
            id: job.id || -1,
            referenceDate: date
          }
        }
      }).then(result => {
        setOutstandingCalls((calls) => calls - 1)
      }).catch(err => {
        setOutstandingCalls((calls) => calls - 1)
        setSaving(false)
        toggle()
        addAlert({message: `The ${job?.type?.value} failed to be queued.`, timeout: 5000, color: "error"})
        console.error("Error running Job", err.message)
      })
    })
  }

  useEffect(() => {
    if(autoRun && !saving && jobs && jobs?.length > 0){
      runJob()
    }
  }, [autoRun, jobs])

  return <Modal isOpen={!autoRun && isOpen} toggle={toggle} zIndex={1500}>
    <ModalHeader className="full-width-header">
      <div className='d-flex justify-content-between'>
        <div>Confirmation</div>
        <div onClick={() => toggle()}>
          <FontAwesomeIcon icon='times' className='ml-auto' />
        </div>
      </div>
    </ModalHeader>
    <ModalBody>
      <div>
        {job?.type?.value} will run for every portfolio in the current Sumsheet that uses this job.
      </div>
    </ModalBody>
    <ModalFooter>
      <EditButtons editMode={true} setEditMode={() => true} cancelEdit={() => toggle()} saving={saving} onSubmit={runJob} disableOnError={true} saveText={`Run ${job?.type?.value}`}/>
    </ModalFooter>
  </Modal>
}