import React, { Component, useState } from "react"
import { RouteComponentProps } from "react-router"
import { GridApi, GridReadyEvent } from "@ag-grid-community/core"
import Auth from "../../Auth/Auth"
import { ClientsListColumnDef } from "../../helpers/columnDefs"
import { appProps } from "../../queries/extended/appProps"
import { WithTopNav } from "../ui/LayoutWrapper"
import {
  AllClientsQuery,
  AllClientsQueryVariables,
  OrgType,
  useAllClientsQuery,
} from "../../__generated__/graphql"
import { Button, Col, Container, Input, Row } from "reactstrap"
import PlaceHolder from "../ui/PlaceHolder"
import _ from "lodash"
import SortableTable from "../Shared/SortableTable"
import PagePermissionCheckFail from "../Shared/PagePermissionCheckFail"
import ErrorDisplay from "../Shared/ErrorDisplay"
import {CsvExportLimitModal} from '../Shared/CsvExportLimitModal'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

interface authProps {
  auth: Auth
}

interface QueryChildren extends React.HTMLProps<HTMLDivElement> {
  (result: AllClientsQuery & authProps & RouteComponentProps): React.ReactNode
  // any
}

const AllClientsComponent: React.FC<
  RouteComponentProps & QueryChildren & appProps
> = (props) => {
  const { auth } = props
  if (!auth.checkPermissions(["view:all_clients"])) {
    return <PagePermissionCheckFail />
  }

  const [filterText, setFilterText] = useState("")
  let tableData: any, colDef: any, onReady: (event: GridReadyEvent) => void
  const [gridApi, setGridApi] = useState<GridApi | undefined>(undefined)
  const [csvExportModalOpen, setCsvExportModalOpen] = useState(false)
  const { loading, data, error } = useAllClientsQuery({
    variables: {
      type: ["Client"] as OrgType[],
      accountTypes: ["CUST", "FCUST"],
      offset: 0,
      limit: 3500,
    } as AllClientsQueryVariables,
    fetchPolicy: "cache-first",
  })
  
  const openCsvExportModal = () => {
    setCsvExportModalOpen(!csvExportModalOpen)
  }

  const exportToCsv =() => {
    let exportedRows = 0;
    gridApi?.exportDataAsCsv({
      onlySelected: gridApi?.getSelectedRows().length > 0,
      shouldRowBeSkipped: () => {
        exportedRows++
        if(exportedRows > 1000){
          openCsvExportModal()
        }
        return exportedRows > 1000
      }
    })
  }

  onReady = (event) => {
    setGridApi(event.api)
    event.api!.setFilterModel({
      "accountType.code": {
        filterType: "set",
        values: ["Active"],
      },
    })
  }

  if (loading) {
    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="pane">
              <PlaceHolder />
            </div>
          </Col>
        </Row>
      </Container>
    )
  }

  if (error) {
    return (
      <Container fluid>
        <ErrorDisplay error={error}/>
      </Container>
    )
  }
  if (!data) return <div>data doesn't exist</div>
  tableData = _.get(data, "orgs", [])
  let result: any[] = [] // plan list
  tableData.map((client: any) => {
    let plans = client.plans
    if (plans?.length > 0) {
      plans = plans.map((plan: any) => ({ ...plan, client }))
    }
    result = result.concat(plans)
  })
  colDef = ClientsListColumnDef()

  return (
    <Container fluid className="d-flex flex-grow-1 flex-direction-column">
      <div className="mb-4">
        <h2 className="headline">Clients</h2>
      </div>
      <Row>
        <Col>
          <div className="pane pane-toolbar">
            <Input
              className="toolbar-search"
              type="text"
              placeholder="Find Clients by name"
              onChange={(event) => setFilterText(event.target.value)}
            />
            <Button color="light" className="mx-2 text-callan-blue border-blue-80 btn-thin" onClick={exportToCsv}>
              Export CSV
              <img src='/assets/CSV.svg' className="ml-2"/>
            </Button>
          </div>
          <CsvExportLimitModal
            modalOpen={csvExportModalOpen}
            setModalOpen={setCsvExportModalOpen}
          />
        </Col>
      </Row>
      <SortableTable
        key={0}
        loading={loading}
        filterText={filterText}
        columnDefs={colDef}
        tableData={result}
        onReady={onReady}
        // for export as of date
        exportParams={{allColumns: true}}
      />
    </Container>
  )
}

export default WithTopNav(AllClientsComponent)
