import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import iassign from "immutable-assign"
import { cloneDeep, first, get, set } from "lodash"
import { useState } from "react"
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap"
import { appDate } from "../../../Context/CalendarContext"
import { DATE_API_FORMAT } from "../../../helpers/constant"
import { ClientPortfolioDetailComponentSettingsFragment, ClientPortfolioDetailLayoutFragment, ComponentApprovalCode, ComponentType, LayoutSectionType, ReportsFragment } from "../../../__generated__/graphql"
import { IconMapping } from "./ReportComponent"
import { selectedComponentProp } from "./ReportComponent"

type ReportAddableProps = {
  show: boolean
  sectionNumber: number
  componentNumber: number
  report?: ReportsFragment
  setSelectedComponentId?: (value:selectedComponentProp) => void
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editedDraftLayout: ClientPortfolioDetailLayoutFragment
}

export const ReportAddable: React.FC<ReportAddableProps> = ({ show, setEditedDraftLayout, editedDraftLayout, sectionNumber, componentNumber, report, setSelectedComponentId }) => {
  // Hide Addable for now
  return (<></>)

  const [hovering, setHovering] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const defaultDate = get(first(first(editedDraftLayout?.sections)?.components)?.draftSettings, 'date') || appDate.format(DATE_API_FORMAT)
  const defaultList = report?.draftList
  const dataComponents = [
    {
      name: "Asset Allocation",
      componentType: ComponentType.AssetAllocation,
      defaultSettings: {
        list: defaultList,
        date: defaultDate,
        __typename: "AssetAllocation",
      }
    },
    {
      name: "Asset Distribution",
      componentType: ComponentType.AssetDistribution,
      defaultSettings: {
        list: defaultList,
        date: defaultDate,
        __typename: "AssetDistribution",
      }
    },
    {
      name: "Manager Performance",
      componentType: ComponentType.ManagerPerformance,
      defaultSettings: {
        list: defaultList,
        date: defaultDate,
        __type: "ManagerPerformance",
      }
    },
    {
      name: "Performance Comparison",
      componentType: ComponentType.PerformanceComparison,
      defaultSettings: {
        list: defaultList,
        date: defaultDate,
        __type: "PerformanceComparison",
      }
    }
  ]

  const standardComponents = [
    {
      name: "Columns",
      componentType: "column",
      defaultSettings: {
      }
    },
    // {
    //   name: "Text",
    //   componentType: ComponentType.Text,
    //   defaultSettings: {
    //     list: defaultList,
    //     date: defaultDate,
    //     __typename: "Text",
    //   }
    // },
  ]

  const modal = (<Modal
    size="sm"
    className="mt-5"
    isOpen={modalOpen}
    toggle={() => setModalOpen(!modalOpen)}
    zIndex={1500}
    key="modal"
  >
    <ModalHeader className='full-width-header'>
      <div className="d-flex justify-content-between w-100">
        <div>
          Add Component
        </div>
        <div onClick={() => setModalOpen(!modalOpen)}>
          <FontAwesomeIcon
            icon="times"
            className="ml-auto"
          />
        </div>
      </div>
    </ModalHeader>
    <ModalBody>
      <div>
        <div>Data</div>
        {dataComponents.map(({ name, componentType, defaultSettings }) => {
          const handleClick = () => {
            setModalOpen(!modalOpen)
            setEditedDraftLayout((prevState) => {
              let newState = iassign(
                prevState,
                currentState => get(currentState, 'sections'),
                sectionsTable => {
                  let sections = cloneDeep(sectionsTable)
                  const newId = Math.floor(Math.random() * -100_000) // Random id to prevent duplicate id
                  // If not in a column then add to a new single column
                  if (componentNumber || componentNumber === 0) {
                    // -1 signifies a new column
                    if (componentNumber === -1) {
                      sections?.splice(sectionNumber,0,{
                        type: LayoutSectionType.SingleColumnSection,
                        components: [{
                          id: newId,
                          name,
                          type: componentType,
                          // approval: {
                          //   code: ComponentApprovalCode._1,
                          //   value: 'Needs Review',
                          //   _typename: 'ComponentApprovalLookup'
                          // }
                          draftSettings: defaultSettings as ClientPortfolioDetailComponentSettingsFragment,
                          liveSettings: undefined,
                          template: false,
                          __typename: 'Component'
                        }],
                        __typename: 'LayoutSection',
                      })
                    } else {
                      if(sections){
                        var selectedSection = sections[sectionNumber]
                        if(selectedSection) set(selectedSection, `components[${componentNumber}]`, {
                          id: newId,
                          name,
                          type: componentType,
                          // approval: {
                          //   code: ComponentApprovalCode._1,
                          //   value: 'Needs Review',
                          //   _typename: 'ComponentApprovalLookup'
                          // }
                          draftSettings: defaultSettings as ClientPortfolioDetailComponentSettingsFragment,
                          liveSettings: undefined,
                          template: false,
                          __typename: 'Component'
                        });
                      }
                    }
                    if(setSelectedComponentId) setSelectedComponentId({id: newId, type: "component"})
                  }
                  return sections
                }
              )
              return newState
            })
          }
          return (
            <div key={componentType} className="text-gray-100 py-1 cursor-pointer" onClick={() => handleClick()}>
              <FontAwesomeIcon
                icon={IconMapping[componentType] || "table"}
                className="text-gray-70 ml-4 mr-3"
              />
              {name}
            </div>
          )
        })}
        <div>Standard</div>
        {/* Columns must be the root component */}
        {componentNumber === -1 &&
          <div className="text-gray-100 py-1 cursor-pointer" onClick={() => {
            setModalOpen(!modalOpen)
            setEditedDraftLayout((prevState) => {
              let newState = iassign(
                prevState,
                currentState => get(currentState, 'sections'),
                sectionsTable => {
                  let sections = cloneDeep(sectionsTable)
                  sections?.splice(sectionNumber,0,{
                    type: LayoutSectionType.TwoColumnSection,
                    components: [null,null,null],
                    __typename: 'LayoutSection',
                  })
                  return sections
                }
              )
              if(setSelectedComponentId) setSelectedComponentId({id: sectionNumber, type: "section"})
              return newState
            })
          }}>
            <FontAwesomeIcon
              icon={"columns"}
              className="text-gray-70 ml-4 mr-3"
            />
            Columns
          </div>
        }
      </div>
    </ModalBody>
  </Modal>)

  if(!show) return(<>{modal}</>)

  return (
    <>
      {modal}
      <div className={classNames('d-flex position-relative justify-content-center report-addable-container')} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)}>
        {hovering &&
          <div className='position-absolute mt-3 report-addable-bar'></div>
        }
        <Button color="primary" className='z-index-1' onClick={() => setModalOpen(!modalOpen)}>
          <FontAwesomeIcon icon="plus" />
        </Button>

      </div>
    </>
  )
}