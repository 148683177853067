import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official"
import iassign from 'immutable-assign'
import { cloneDeep, compact, findIndex, forEach, get, groupBy, maxBy, merge, set, some } from 'lodash'
import moment from "moment"
import numbro from 'numbro'
import { Fragment, useEffect, useMemo, useRef, useState } from "react"
import { Col, Row, Table, UncontrolledTooltip } from "reactstrap"

import { AutocompleteFilters, ClientPerformanceTypeCode, ClientPortfolioDetailComponentFragment, ComponentOverrideSettings, ComponentType, DataPoint, DataPointAttributeType, DataPointStatus, Fact, FeeType, LayoutSectionType, ManagerPerformanceType, PerformanceComparisonData, PerformanceComparisonTable, PerformanceComparisonTableRow, PlanClientPortfolioFragment, ReportCategoryCode, ReportingPerformancePeriod, ReportPerformanceComparisonDataQueryVariables, ReportPerformanceComparisonDcPortfolioFragment, ReportPerformanceComparisonFetchFragment, ReportPerformanceComparisonTrailingClientPortfolioFragment, ReportPerformanceComparisonYearsClientPortfolioFragment, ReportsFragment, useNewPerformanceComparisonDataQuery, usePlanPortfoliosQuery, useReportPerformanceComparisonDataQuery } from "../../../__generated__/graphql"
import { DATE_DISPLAY_FORMAT } from '../../../helpers/constant'
import { graphColors } from '../../../helpers/highCharts'
import { excludePropertyArray } from "../../../helpers/object"
import { convertComponentForMutation } from "../../../helpers/report"
import SearchAddStatic from '../../Search/SearchAddStatic'
import { AutocompleteItemTypes } from '../../Search/SearchAutocomplete'
import { FormInput } from '../../ui/Forms/FormInput'
import { GetLookupDataToOptions } from '../../ui/LookupOptions'
import { ApprovalsReportDisplay } from '../Shared/ReportApprovals'
import { AggregatedComponentProps, AggregatedExportSettingsProps, BaseComponent, currencyFormat, ErrorComponent, FootnotableComponent, getPortfolioRowLinks, IconMapping, IndividualComponentProps, LoadingComponent, OptionsModal, percentFormat, rankFormat, ReportDisplayType, TemplateComponent, ToolbarProps } from "../Shared/ReportComponent"
import { AggregatedReportEditProps, ReportEditField, ReportEditInfoFields, ReportEditTemplate, SimpleReportEdit } from '../Shared/ReportEdit'


interface HoveredCell {
  rowName: string
  columnName: string
}

const PerformanceComparison: React.FC<AggregatedComponentProps> = (props) => {
  return (
    <BaseComponent<ReportPerformanceComparisonFetchFragment>
      expectedTypename={"PerformanceComparison"}
      reactComponent={PerformanceComparisonDisplay}
      {...props}
    />
  )
}

const allTableCellsLoaded = (tableData: PerformanceComparisonTable):boolean => {
  const singleRowLoading = (row: PerformanceComparisonTableRow) => {
    return some(row?.columnData as DataPoint[], (dataPoint) => {
      return dataPoint?.status === DataPointStatus.LOADING
    })
  }

  const anyRowsLoading = (rows: PerformanceComparisonTableRow[]):boolean => {
    return some(rows || [], (row) => singleRowLoading(row))
  }

  return !anyRowsLoading((tableData?.rows || []) as PerformanceComparisonTableRow[])
}

const PerformanceComparisonDisplay: React.FC<IndividualComponentProps<ReportPerformanceComparisonFetchFragment>> = ({component, auth, settings, selected, handleSelect, editMode, sectionNumber, componentNumber, report, setEditedDraftLayout, editedDraftLayout, setSelectedComponentId, view, setReportState, reportState: inState, hideSingleExport, clientId, overwriteDate, updateSettings: parentUpdateSettings }) => {
  const name = component.name || ""
  const componentId = component.id
  const asOfDate = moment(overwriteDate || settings.date).format(DATE_DISPLAY_FORMAT)

  const [initSettings, setInitSettings] = useState(settings)
  const [hoveredCell, setHoveredCell] = useState<HoveredCell | null>(null)
  const [optionModalOpen, setOptionModalOpen] = useState(false)
  const [optionsSettingUsed, setOptionsSettingUsed] = useState(false)

  const updateSettings = (newSettings: ReportPerformanceComparisonFetchFragment) => {
    parentUpdateSettings(newSettings)
    setOptionsSettingUsed(true)
    setOptionModalOpen(false)
  }

  useEffect(()=>{
    if(editMode === false && initSettings !== settings) {
      setInitSettings(settings)
    }
  }, [editMode]) // eslint-disable-line react-hooks/exhaustive-deps
  // Only update this when editMode changes, otherwise the options modal will overwrite the initSettings

  const usePreview = (initSettings !== settings || optionsSettingUsed)
  const useNew = component.id < 1
  let usedSettings = {}

  if(usePreview || useNew) {
    let convertedSettings = excludePropertyArray(convertComponentForMutation(settings, ComponentType.PerformanceComparison), ["__typename"])
    usedSettings = {
      performanceComparison: {
        ...convertedSettings,
        showTargetFootnote: get(convertedSettings, 'performanceComparison.showTargetFootnote', true),
        showFootnote: get(convertedSettings, 'performanceComparison.showFootnote', true)
      }
    }
  }

  const queryVariables: ReportPerformanceComparisonDataQueryVariables = {
    ids: [component.id],
    live: (view === ReportDisplayType.Live) && !usePreview,
    draft: (view === ReportDisplayType.Draft || view === ReportDisplayType.External) && !usePreview,
    preview: usePreview,
    settings: usedSettings,
  }

  const { loading: baseLoading, data: baseData, error: baseError, stopPolling: baseStopPolling, startPolling: baseStartPolling } = useReportPerformanceComparisonDataQuery({
    variables: queryVariables,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    pollInterval: 5000,
    skip: useNew
  })

  const { loading: newLoading, data: newData, error: newError, stopPolling: newStopPolling, startPolling: newStartPolling} = useNewPerformanceComparisonDataQuery({
    variables: {settings: usedSettings},
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
    pollInterval: 5000,
    skip: !useNew,
  })

  let loading, error
  if(useNew) {
    loading = newLoading
    error = newError
  } else {
    loading = baseLoading
    error = baseError
  }

  useEffect(() => {
    if (useNew) {
      baseStopPolling()
    } else {
      newStopPolling()
    }
  },[useNew])

  if(loading) return <LoadingComponent name={name} rightText={`As of ${asOfDate}`} componentNumber={componentNumber}/>
  if(error) return <ErrorComponent name={name} error={error?.message} rightText={`As of ${asOfDate}`} selected={selected} onClick={handleSelect}/>

  if(!(settings.clientPortfolio?.styleGroup?.id || settings.group?.id) && settings.vehicles?.length === 0){
    return <ErrorComponent name={component?.name || ""} error={"Data points missing. The component must contain one group and one portfolio or vehicle."}/>
  } else if(!(settings.clientPortfolio?.styleGroup?.id || settings.group?.id)){
    return <ErrorComponent name={component?.name || ""} error={"Group missing. The component must contain one group."}/>
  } else if(!(settings.clientPortfolio?.styleGroup?.id || settings.vehicles?.length !== 0)){
    return <ErrorComponent name={component?.name || ""} error={"Data points missing. The component must contain one portfolio or vehicle."}/>
  }

  if (newData || baseData) {
    let displayedData = baseData?.components?.[0]?.liveData as PerformanceComparisonData
    if(useNew && newData?.componentPreviewData) {
      displayedData = newData?.componentPreviewData as PerformanceComparisonData
    } else if ((view === ReportDisplayType.Draft || view === ReportDisplayType.External) && baseData?.components?.[0]?.draftData) {
      displayedData = baseData?.components?.[0]?.draftData as PerformanceComparisonData
    } else if (usePreview && baseData?.components?.[0]?.previewData) {
      displayedData = baseData?.components?.[0]?.previewData as PerformanceComparisonData
    }

    const tableData = displayedData?.table

    if((tableData && allTableCellsLoaded(tableData) && !loading) || error){
      console.log('stop polling performance comparison data')
      if(useNew){
        newStopPolling()
      } else {
        baseStopPolling()
      }
    }

    let tooltipProps:ToolbarProps = {}

     // TODO update export
    if(componentNumber === -1){
      tooltipProps = {'exportOptions': {
        name: name,
        slides: [{
          title: name,
          sections: [{
            components: [componentId],
            type: LayoutSectionType.SingleColumnSection
          }]
        }],
        settings: {
          live: view === ReportDisplayType.Live,
          footerName: report?.exportSettings?.footerName || report?.client?.name,
        }
      }}
    }
    tooltipProps['openOptionsModal'] = (view !== ReportDisplayType.External || auth.checkPermissions(["edit:product_analysis"])) ? () => setOptionModalOpen(true) : undefined

    const chartJSON = JSON.parse(displayedData?.chart || '{}')

    return (
      <TemplateComponent
        name={name}
        componentTypeName='Asset Allocation'
        rightText={`As of ${asOfDate}`}
        selected={selected}
        onClick={handleSelect}
        tooltipProps={tooltipProps}
        editMode={editMode}
        sectionNumber={sectionNumber}
        report={report}
        setEditedDraftLayout={setEditedDraftLayout}
        editedDraftLayout={editedDraftLayout}
        componentNumber={componentNumber}
        setSelectedComponentId={setSelectedComponentId}
        view={view}
        auth={auth}
        hideExport={hideSingleExport}
      >
        <PerformanceComparisonOptionsModal
          settings={settings}
          optionModalOpen={optionModalOpen}
          setOptionModalOpen={setOptionModalOpen}
          report={report}
          updateSettings={updateSettings}
          componentId={componentId}
          view={view}
        />
        <Row className="no-gutters">
        {/* <Col sm="12"> // Removed for the time being until new components are created.
          <PerformanceComparisonSupporting
            settings={settings}
            portfolio={portfolio}
            dcPortfolio={dcPortfolio}
          />
        </Col> */}
        <Col sm="12">
          <div className="form-section-title headline underline small-font-size mb-0 pb-0">{displayedData.title}</div>
          <Table className={"report-table performance-comparison-table"}>
            { displayedData && chartJSON && (
              <thead>
                <tr>
                  <td></td>
                  <td className="p-0" colSpan={tableData?.columns?.length || 0}>

                    <PerformanceComparisonGraph
                      editMode={editMode}
                      chartData={chartJSON}
                      hoveredCell={hoveredCell}
                      setHoveredCell={setHoveredCell}
                    />
                  </td>
                </tr>
              </thead>
            )}
            { displayedData && tableData && (
              <PerformanceComparisonTableDisplay
                settings={settings}
                tableData={tableData}
                report={report}
                clientId={clientId}
                view={view}
                component={component}
                hoveredCell={hoveredCell}
                setHoveredCell={setHoveredCell}
              />
            )}
          </Table>
        </Col>
        </Row>
      </TemplateComponent>
    )
  }

  return <ErrorComponent name={name} error={"No data returned"} rightText={`As of ${asOfDate}`} selected={selected} onClick={handleSelect}/>
}

type PointData = Highcharts.Point & {
  graphic: {
    symbolName: string
  }
}

type TooltipFormatterProps = Highcharts.TooltipFormatterContextObject & {
  point: PointData
}



interface PerformanceComparisonSupportingProps {
  settings: ReportPerformanceComparisonFetchFragment
  portfolio?: ReportPerformanceComparisonTrailingClientPortfolioFragment | ReportPerformanceComparisonYearsClientPortfolioFragment
  dcPortfolio?: ReportPerformanceComparisonDcPortfolioFragment
}

const PerformanceComparisonSupporting: React.FC<PerformanceComparisonSupportingProps> = ({settings, portfolio, dcPortfolio}) => {
  const factWriteUps = groupBy(portfolio?.relatedVehicle?.vehicle?.product?.product?.writeUps?.facts, "researchCategory.code")
  const philosophy = maxBy(factWriteUps["POVER"] as Fact[], (f:Fact) => moment(f.lastUpdated).valueOf())
  const firstParagraph = (philosophy?.text || "").split("\n")[0]

  const showPhilosophy = settings.showInvestmentPhilosophy && !!philosophy
  const showDcInfo = settings.showDCInfo && !!dcPortfolio
  const showAssetGrowth = settings.showAssetGrowth && !!portfolio

  const beginningMarketValue = get(portfolio, "priorAssets[0].totalMarketValue", 0)
  const netNewInvestment = get(portfolio, "cashFlowActivity[0].netFlow", 0)
  const endingMarketValue = get(portfolio, "assets[0].totalMarketValue", 0)
  const gains_losses = endingMarketValue - beginningMarketValue - netNewInvestment

  const dcAssets = get(dcPortfolio, "assets[0].totalMarketValue")
  const dcParticipants = get(dcPortfolio, "numberOfParticipants[0].count")
  const dcRevenueShare = get(dcPortfolio, "datedFees[0].revenueShare")
  const dcFee = get(dcPortfolio, "relatedVehicle.stableValueCollectiveInvestmentFund.fees[0].allInFee") || get(dcPortfolio, "relatedVehicle.stableValueSeparateAccount.feeAtAum.fee") || get(dcPortfolio, "datedFees[0].totalExpense")
  const dcTicker = get(dcPortfolio, "relatedVehicle.vehicle.identifiers.ticker") || get(dcPortfolio, "relatedVehicle.vehicle.category.shortValue")

  const colCount = (showPhilosophy ? 1 : 0) + (showDcInfo ? 1 : 0) + (showAssetGrowth ? 1 : 0)
  const colSize = 12 / colCount

  return (
    <Row noGutters>
      {showPhilosophy &&
        <Col sm={colSize} className='mb-2'>
          <div className="form-section-title headline underline smaller-font-size mb-2 pb-0">Investment Philosophy</div>
          <p className="pl-2" dangerouslySetInnerHTML={{__html: firstParagraph}}></p>
        </Col>
      }
      {showDcInfo &&
        <Col sm={colSize} className={classNames("mb-3",{"pl-2": showPhilosophy})}>
          <div className="form-section-title headline underline smaller-font-size mb-2 pb-0">Plan Information</div>
          <Row noGutters className='border-bottom border-gray-20 pl-2 mb-1 pb-1'>
            <Col sm={4}>
              <strong>Assets</strong>
              <br />
              <span className={classNames({"text-danger": (!!dcAssets && dcAssets < 0)})}>
                {dcAssets ? numbro(dcAssets).format(currencyFormat) : '-'}
              </span>
            </Col>
            <Col sm={4}>
              <strong>% of Total Assets</strong>
              <br />
              {(dcAssets && endingMarketValue) ? numbro(endingMarketValue/dcAssets).format(percentFormat) : '-'}
            </Col>
            <Col sm={4}>
              <strong># of Participants</strong>
              <br />
              {dcParticipants || '-'}
            </Col>
          </Row>
          <Row noGutters className='pl-2'>
            <Col sm={4}>
              <strong>Fee</strong>
              <br />
              {dcFee ? numbro(dcFee).format(currencyFormat) : '-'}
            </Col>
            <Col sm={4}>
              <strong>Revenue Share</strong>
              <br />
              {dcRevenueShare ? numbro(dcRevenueShare).format(currencyFormat) : '-'}
            </Col>
            <Col sm={4}>
              <strong>Vehicle/Ticker</strong>
              <br />
              {dcTicker || "-"}
            </Col>
          </Row>
        </Col>
      }
      {showAssetGrowth &&
        <Col sm={Math.min(colSize, 6)} className={classNames("mb-3",{"pl-2": showPhilosophy || showDcInfo})}>
          <div className="form-section-title headline underline smaller-font-size mb-2 pb-0">Quarterly Asset Growth</div>
          <div className='pl-2'>
            <div className="d-flex justify-content-between">
              <div>Beginning Assets</div>
              <div className={classNames("text-right", {"text-danger": (!!beginningMarketValue && beginningMarketValue < 0)})}>{numbro(beginningMarketValue).format(currencyFormat)}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>Net Cash Activity</div>
              <div className={classNames("text-right", {"text-danger": (!!netNewInvestment && netNewInvestment < 0)})}>{numbro(netNewInvestment).format(currencyFormat)}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>Investment Gains/(Losses)</div>
              <div className={classNames("text-right", {"text-danger": (!!gains_losses && gains_losses < 0)})}>{numbro(gains_losses).format(currencyFormat)}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div>Ending Assets</div>
              <div className={classNames("text-right", {"text-danger": (!!endingMarketValue && endingMarketValue < 0)})}>{numbro(endingMarketValue).format(currencyFormat)}</div>
            </div>
          </div>
        </Col>
      }
    </Row>
  )
}


interface PerformanceComparisonGraphProps {
  editMode: boolean
  chartData: any
  hoveredCell: HoveredCell | null
  setHoveredCell: (cell: HoveredCell | null) => void
}
const PerformanceComparisonGraph: React.FC<PerformanceComparisonGraphProps> = ({editMode, chartData, hoveredCell, setHoveredCell}) => {
  const graphRef = useRef<{ chart: Highcharts.Chart; container: React.RefObject<HTMLDivElement>; }>(null)

  useEffect(() => {
    if (graphRef.current) {
      graphRef.current.chart.reflow()
      graphRef.current.chart.reflow()
      graphRef.current.chart.reflow()
    }
  }, [editMode])

  let chartOptions = merge(
    chartData,
    {
      plotOptions: {
        series: {
          point: {
            events: { // Events to highlight table cell when hovering chart cell
              mouseOver(this: Highcharts.Point) {
                setHoveredCell({
                  columnName: `${this?.category}`,
                  rowName: this?.series?.name,
                })
              },
              mouseOut(this: Highcharts.Point) {
                setHoveredCell(null)
              },
            },
          },
        }
      },
      yAxis: {
        labels: { // Negative y values should be red
          formatter(this: Highcharts.AxisLabelsFormatterContextObject) {
            const v:number = this.value as number || 0
            return `<span class="${v && v < 0 && "negative-value"}">${numbro(this?.value).format(rankFormat)}%</span>`
          },
          style: {
            fontSize: "14px",
          }
        },
      },
      tooltip: { // Setup tooltips
        formatter(this: TooltipFormatterProps) {
          const symbol = getSymbol(this?.point.graphic.symbolName)
          const value = this?.point.high
            ? `From ${(this?.point.high).toFixed(2)}%
            To ${((this?.point?.low || 0)).toFixed(2)}%`
            : (this?.y)?.toFixed(2) + "%"
          return `<span style="color:${this?.point.color}">${symbol}</span> ${this?.point.series.name}: <b>${value}</b><br/>`
        },
      }
    }
  )

  // Reset the color of the cells before highlighting the hovered cell.
  forEach(chartOptions.series, (series: Highcharts.Series, seriesKey) => {
    if (series.type === 'columnrange') {
      forEach(series.data, (data, dataKey) => {
        set(chartOptions, ['series',seriesKey,'data',dataKey,'color'], '#E1E1E1')
      })
    }
  })

  // Highlight the cell or cell block which is being hovered in the data table.
  if (hoveredCell) {
    const hoveredSeries = findIndex(chartOptions.series, (series:Highcharts.Series) => series.name === hoveredCell?.rowName)
    const colIdx = chartOptions.xAxis.categories.indexOf(hoveredCell?.columnName)

    if (hoveredSeries > -1 && chartOptions.series[hoveredSeries].type === 'columnrange') {
      set(chartOptions, ['series',hoveredSeries,'data',colIdx,'color'], '#055f8e')
    } else if (hoveredSeries > -1 && chartOptions.series[hoveredSeries].type === 'scatter') {

      chartOptions = iassign(
        chartOptions,
        (chartOptions) => chartOptions.series,
        (series) => series.map((s: Highcharts.Series) => (
          s.type === 'columnrange' ? {
            ...s,
            data: s.data.map((dataPoint, dIdx) => {
              return dIdx === colIdx ? {
                ...dataPoint,
                color: '#e4eff5'
              } : dataPoint
            })
          } : s
        )
      )
      )
    }
  }


  const graph = useMemo(() => {
    return (
      <HighchartsReact
        ref={graphRef}
        options={chartOptions}
        highcharts={Highcharts}
        containerProps={{ style: { height: "400px", marginLeft: "-85px"  }}}
      />
    )
  }, [chartOptions])

  return graph
}

const getSymbol = (symbolName:string) => {
  let symbol = ""
  switch (symbolName) {
    case "circle":
      symbol = "●"
      break
    case "diamond":
      symbol = "♦"
      break
    case "square":
      symbol = "■"
      break
    case "triangle":
      symbol = "▲"
      break
    case "triangle-down":
      symbol = "▼"
      break
  }
  return symbol
}

interface PerformanceComparisonCellProps {
  dataPoint: DataPoint
  hoveredCell: HoveredCell | null
  setHoveredCell: (cell: HoveredCell | null) => void
  columnName: string
  colIdx: number
  row: PerformanceComparisonTableRow
  idPostfix: string
}

const PerformanceComparisonCell: React.FC<PerformanceComparisonCellProps> = ({ dataPoint, columnName, colIdx, hoveredCell, setHoveredCell, row, idPostfix }) => {
  const { formattedValue, status, additionalAttributes } = dataPoint
  const loading = status === DataPointStatus.LOADING
  const isHovering = (colIdx:number) => {
    return hoveredCell?.rowName === row.name && hoveredCell?.columnName === columnName
  }

  const inceptionTooltip = additionalAttributes?.find((attr) => attr?.type && attr.type === DataPointAttributeType.INCEPTION_DATE_TOOLTIP)

  return (
    <td
      className={classNames('text-center', {
        'background-blue-30': isHovering(colIdx),
      })}
      key={`asset-row-${idPostfix}`}
      onMouseEnter={() => setHoveredCell({
        rowName: row.name,
        columnName: columnName || ''
      })}
      onMouseLeave={() => setHoveredCell(null)}
    >
      {loading && <FontAwesomeIcon icon="spinner-third" size="sm" className="ml-2" spin />}
      { !loading && inceptionTooltip && (
        <UncontrolledTooltip placement='top' target={`performance-comparison-cell-${idPostfix}`} delay={200} autohide={false}>
          {inceptionTooltip.stringValue}
        </UncontrolledTooltip>
      )}
      { !loading && dataPoint && (
        <div className={classNames({ "hover-transition-underline": !!inceptionTooltip })} id={`performance-comparison-cell-${idPostfix}`}>
          { formattedValue || ' - '}
        </div>
      )}
    </td>
  )
}

interface PerformanceComparisonTableProps {
  settings: ReportPerformanceComparisonFetchFragment
  tableData: PerformanceComparisonTable
  report?: ReportsFragment
  clientId?: number
  view: ReportDisplayType
  component: ClientPortfolioDetailComponentFragment
  hoveredCell: HoveredCell | null
  setHoveredCell: (cell: HoveredCell | null) => void
}

const PerformanceComparisonTableDisplay: React.FC<PerformanceComparisonTableProps> = ({settings, tableData, report, clientId, view, component, hoveredCell, setHoveredCell}) => {
  const draftView = view === ReportDisplayType.Draft
  const columns = tableData.columns || [];

  const tableRows = compact(tableData.rows) || [];

  return (
    <>
      <thead>
        <tr>
          <th className="pl-1 pr-0"></th>
          {
            columns.map((column, idx) => (
              <th className="pl-0 text-center" key={`asset-column-heaader-${idx}`}>{ column }</th>
            ))
          }
        </tr>
      </thead>
      <tbody>
        {tableRows.map((row: PerformanceComparisonTableRow, rowIdx: number) => {
          const firstAgregateRow = row.group === false && tableRows[rowIdx - 1]?.group === true
          const links = getPortfolioRowLinks({componentId: component.id, reportId: report?.id, clientId, view, productId: row.productId || undefined})

          return(
            <Fragment key={`performance-comparison-row-${rowIdx}`}>
              <tr
                className={classNames({
                  "report-tr-title": true,
                  "report-tr-total": row.total,
                  "row-border-olive-100": firstAgregateRow
                })}
              >
                <td className="pl-0 text-right">
                  <FootnotableComponent
                    preDefinedFootnote={row?.footnote}
                    errors={row.errors}
                    showErrors={draftView}
                    warnings={row.warnings}
                    showWarnings={draftView}
                    links={links}
                  >
                    {row.name}
                    {row.showFeeBadge &&
                      <span className={classNames("ml-2 badge text-uppercase text-text-color", "badge-pill", { "background-blue-30": row.feeType === FeeType.NET, "background-olive-10": row.feeType === FeeType.GROSS })}>
                        {row.feeType === FeeType.NET ? "Net" : "Gross"}
                      </span>
                    }
                    { row.symbol && (
                      <span
                        className="pl-1"
                        style={{
                          color: row.color || '#000',
                          fontSize: row.symbol === "circle" ? 24 : 18
                        }}>
                          {getSymbol(row.symbol || "")}
                      </span>
                    )}
                  </FootnotableComponent>
                </td>
                { row?.columnData?.map((dataPoint, colIdx) => (
                  dataPoint && (<PerformanceComparisonCell
                    dataPoint={dataPoint}
                    columnName={columns[colIdx] || ''}
                    colIdx={colIdx}
                    hoveredCell={hoveredCell}
                    setHoveredCell={setHoveredCell}
                    row={row}
                    idPostfix={`${rowIdx}-${colIdx}`}
                    key={`performance-comparison-cell-${rowIdx}-${colIdx}`}
                  />)
                ))}
              </tr>
            </Fragment>
          )
        })}
      </tbody>
    </>
  )
}

const PerformanceComparisonPortfolioPerformanceFields:ReportEditField[] = [
  {
    property: "portfolioPerformanceType",
    label: "Portfolio Performance",
    type: "select",
    optionSource: "ManagerPerformanceType",
    required: true,
  },
]

const PerformanceComparisonPerformanceFields:ReportEditField[] = [
  {
    property: "groupPerformanceType.code",
    label: "Group Performance",
    type: "select",
    optionSource: "GroupPerformanceTypeCode",
    required: true,
  },
]

const PerformanceComparisonVehiclePerformanceFields:ReportEditField[] = [
  {
    property: "vehiclePerformanceType.code",
    label: "Vehicle Performance",
    type: "select",
    optionSource: "VehiclePerformanceTypeCode",
    required: true,
  },
]

const PerformanceComparisonPercentileTableFields:ReportEditField[] = [
  {
    property: "showPerformanceTable",
    label: "Percentile Table",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
]

const PerformanceComparisonPercentileMedianFields:ReportEditField[] = [
  {
    property: "showMedianColumn",
    label: "Percentiles",
    type: "select",
    options: GetLookupDataToOptions({
      data: [{
        code: "true",
        value: "Median Only",
      },{
        code: "false",
        value: "All Percentiles",
      }],
      hideBlank: true
    }) || [],
  },
]

const PerformanceComparisonReportingPeriodFields:ReportEditField[] = [
  {
    property: "reportingPerformancePeriod",
    label: "",
    type: "radio",
    options: [{
      code: "Trailing",
      value: "Trailing",
    },{
      code: "CalendarYears",
      value: "Calendar Year",
    },{
      code: "FiscalYears",
      value: "Fiscal Year",
    }],
    required: true,
    subClasses: {
      inputWrapperClasses: "col-sm-12 radio-input-with-divider mt-1 mb-2 w-100",
    },
  },
]

const PerformanceComparisonSupportingDetailsFields = (portfolios:PlanClientPortfolioFragment[] | undefined):ReportEditField[] => {

  return [
    {
      property: "showInvestmentPhilosophy",
      label: "Investment Philosophy",
      type: "checkbox",
      subtype: "show",
    },
    {
      property: "showAssetGrowth",
      label: "Quarterly Asset Growth (DB only)",
      type: "checkbox",
      subtype: "show",
    },
    {
      property: "showDCInfo",
      label: "DC Fund Info",
      type: "checkbox",
      subtype: "show",
    },
    {
      property: "DCTotalFundPortfolio.id",
      label: "Total Fund (DC only)",
      type: "select",
      options: portfolios?.map((p) => ({
        code: p.id.toString() || "",
        value: p.name || "",
      })) || [],
    },
  ]
}

const PerformanceComparisonTableFields:ReportEditField[] = [
  {
    property: "performanceComparisonPeriod.lastQuarter",
    label: "1 Quarter",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.yearToDate",
    label: "Year to date (YTD)",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.fiscalYear",
    label: "Fiscal YTD",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last1Year",
    label: "1 Year",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last2Years",
    label: "2 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last3Years",
    label: "3 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last5Years",
    label: "5 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last7Years",
    label: "7 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last10Years",
    label: "10 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last15Years",
    label: "15 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.last20Years",
    label: "20 Years",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "performanceComparisonPeriod.sinceInception",
    label: "Since Inception",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
]

const PerformanceComparisonYearsFields:ReportEditField[] = [
  {
    property: "numberOfYearsToInclude",
    label: "Years to include",
    type: "select",
    subtype: "single",
    options: [{
      code: "1",
      value: " 1",
    },{
      code: "2",
      value: " 2",
    },{
      code: "3",
      value: " 3",
    },{
      code: "4",
      value: " 4",
    },{
      code: "5",
      value: " 5",
    },{
      code: "6",
      value: " 6",
    },{
      code: "7",
      value: " 7",
    },{
      code: "8",
      value: " 8",
    },{
      code: "9",
      value: " 9",
    },{
      code: "10",
      value: "10",
    }],
    required: true,
  },
]

const FiscalMonthEndFields:ReportEditField[] = [
  {
    property: "plan.fiscalMonth",
    label: "Fiscal Month End",
    type: "text",
    readonly: true,
    tooltip: {
      icon: "question-circle",
      id: "performanceComparisonFiscalMonthEndTooltip",
    },
  },
]

const PerformanceComparisonFootnoteFields:ReportEditField[] = [
  {
    property: "showFootnote",
    label: "Portfolio",
    type: "checkbox",
    subtype: "show",
    required: true,
  },
  {
    property: "showTargetFootnote",
    label: "Benchmark",
    type: "checkbox",
    subtype: "show",
    required: true,
  }
]

const getColumnsByPeriod = (settings: ReportPerformanceComparisonFetchFragment) => {
  let result = PerformanceComparisonTableFields
  switch (settings.reportingPerformancePeriod) {
    case ReportingPerformancePeriod.CalendarYears:
      result = PerformanceComparisonYearsFields
      break
    case ReportingPerformancePeriod.FiscalYears:
      result = [...PerformanceComparisonYearsFields, ...FiscalMonthEndFields]
      break
    default: // ReportingPerformancePeriod.Trailing
      break
  }
  return result
}

export const PerformanceComparisonEdit: React.FC<AggregatedReportEditProps> = ({portfolio, component, handleInputChange, clientId, user, auth, report}) => {
  const settings = component.draftSettings as ReportPerformanceComparisonFetchFragment

  const { data } = usePlanPortfoliosQuery({ variables: {id: settings.clientPortfolio?.plan?.id || 0, includeTargetsAndGroup: false}})

  if(!settings){
    return(
      <></>
    )
  }

  const totalFunds = data?.plan?.clientPortfolios?.filter((p) => p?.dataType?.code === "TOT") || []
  let supportingColumns = [...PerformanceComparisonSupportingDetailsFields(totalFunds as PlanClientPortfolioFragment[])]
  const ownedComponent = component.owner?.id === user?.person?.id
  let infoColumns = [...ReportEditInfoFields(handleInputChange, {hideList: true})]
  let reportingPeriodColumns = [...PerformanceComparisonReportingPeriodFields, ...getColumnsByPeriod(settings)]
  const hasPortfolio = !!settings.clientPortfolio?.id
  const isFundProfile = report.category?.code === ReportCategoryCode.FPROF
  let performanceFields:ReportEditField[] = [] //PerformanceComparisonActualPerformanceFields
  if(hasPortfolio) performanceFields = performanceFields?.concat(PerformanceComparisonPortfolioPerformanceFields)
  let colorCounter = 0

  const getColor = () => {
    const color = graphColors[colorCounter].name
    colorCounter++
    if(colorCounter >= graphColors.length) colorCounter = 0
    return color
  }

  if(!!settings.group) performanceFields = performanceFields?.concat(PerformanceComparisonPerformanceFields)
  if((settings.vehicles?.length || 0) > 0) performanceFields = performanceFields?.concat(PerformanceComparisonVehiclePerformanceFields)
  performanceFields = performanceFields?.concat(PerformanceComparisonPercentileTableFields)
  if(settings.showPerformanceTable) performanceFields = performanceFields?.concat(PerformanceComparisonPercentileMedianFields)

  return (
    <>
      <ReportEditTemplate className={classNames('text-white', {'background-blue-100': ownedComponent, 'background-gray-50': !ownedComponent })}>
        <div className="d-flex my-2">
          <div className="d-flex">
            <FontAwesomeIcon
              icon={IconMapping[ComponentType.ManagerPerformance] || 'table'}
              className="text-white unlock-icon-height mr-2 fa-2-5x"
            />
          </div>
          <div>
            <strong>Performance Comparison</strong> | ID {component.id}
            <br />
            Owned by {ownedComponent ? 'me' : `${component.owner?.firstName} ${component.owner?.lastName}`}
            <br />
            <small className={classNames('small-text', {'text-blue-50': ownedComponent, 'text-gray-30': !ownedComponent })}>
              Data from
              <span className='ml-1 cursor-pointer tooltip-icon hover-transition-underline' onClick={(e) => {e.stopPropagation(); window.open(`/clientportfolios/${portfolio.id}`,'_blank')}}>
                Portfolio ID {portfolio.id}
                <FontAwesomeIcon icon={"external-link"} className={classNames('ml-2', {'light-blue-color': ownedComponent, 'light-gray-color': !ownedComponent })}/>
              </span>
            </small>
          </div>
        </div>
      </ReportEditTemplate>
      {auth.checkPermissions(['edit:component_approval']) &&
        <ApprovalsReportDisplay
          value={component.approval?.code || undefined}
        />
      }
      <SimpleReportEdit
        name={"info"}
        retractable={true}
        fields={infoColumns}
        handleInputChange={handleInputChange}
        currentState={{...settings, name: component.name} as any}
        clientId={clientId}
      />
      <ReportEditTemplate name={"Data Points"} retractable>
        {settings.clientPortfolio &&
          <div className='report-data-points-box p-2 mb-2 hover-container'>
            <div className='d-flex justify-content-between'>
              <div>
                <span className={classNames("mr-2", "text-"+getColor())} style={{fontSize: 18}}>{getSymbol("circle")}</span>{settings.clientPortfolio?.name}
              </div>
              {!isFundProfile &&
                <div className='cursor-pointer' onClick={() => handleInputChange(null, "clientPortfolio")}>
                  <FontAwesomeIcon
                    icon="trash"
                    className='show-on-container-hover'
                  />
                </div>
              }
            </div>
            {settings?.portfolioPerformanceType === ManagerPerformanceType.Default && settings.clientPortfolio.performanceType?.code === ClientPerformanceTypeCode.BOTH && getColor() && ""} {/* skip a color if gross and net would be shown so they line up correctly */}
            {settings.clientPortfolio?.performanceTargetMap?.map((ptm, idx) => {
              return (
                <div className="ml-3 mt-1" key={idx}>
                  <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("triangle")}</span>{ptm?.target?.name}
                </div>
              )
            })}
            {settings.clientPortfolio?.styleGroup &&
              <div className={classNames([
                "d-flex",
                "justify-content-between",
                "align-items-center",
                "ml-3",
                "mt-1"
              ], {
                "text-gray-50": !!settings.group
              })}>
                <div>
                  <span>
                    <FontAwesomeIcon
                      icon="bars"
                      className='text-olive-100 mr-2'
                    />
                  </span>{settings.clientPortfolio?.styleGroup?.shortName}
                </div>
              </div>
            }
          </div>
        }
        {settings.vehicles?.map((vehicle, idx) => (
          <div key={idx} className='report-data-points-box p-2 mb-2 hover-container'>
            <div className='d-flex justify-content-between'>
              <div>
                <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("square")} </span>{vehicle?.vehicle?.name}
              </div>
              <div className='cursor-pointer' onClick={() => handleInputChange(settings.vehicles?.filter(item => item?.vehicle?.fundid !== vehicle?.vehicle?.fundid), "vehicles")}>
                <FontAwesomeIcon
                  icon="trash"
                  className='show-on-container-hover'
                />
              </div>
            </div>
            <div className='d-flex justify-content-between px-1'>
              <div className='ml-3'>
                <small>
                  {vehicle?.vehicle?.product?.product?.manager?.name}
                </small>
              </div>
              <div className='mr-3'>
                <small>
                  {vehicle?.vehicle?.fundid}
                </small>
              </div>
            </div>
          </div>
        ))}
        {settings.indices?.map((index, idx) => (
          <div key={idx} className='report-data-points-box p-2 mb-2 d-flex justify-content-between hover-container'>
            <div>
              <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("triangle")} </span>{index?.name}
            </div>
            <div className='cursor-pointer' onClick={() => handleInputChange(settings.indices?.filter(item => item?.id !== index?.id), "indices")}>
              <small className='mr-2'>
                {index?.id}
              </small>
              <FontAwesomeIcon
                icon="trash"
                className='show-on-container-hover'
              />
            </div>
          </div>
        ))}
        {settings.group &&
          <div className='report-data-points-box p-2 mb-2 d-flex justify-content-between hover-container'>
            <div>
              <span className='text-olive-100 mr-2'>
                <FontAwesomeIcon
                  icon="bars"
                  className='text-olive-100'
                />
              </span>{settings.group?.shortName}
            </div>
            <div className='cursor-pointer' onClick={() => handleInputChange(null, "group")}>
              <small className='mr-2'>
                {settings.group?.id}
              </small>
              <FontAwesomeIcon
                icon="trash"
                className='show-on-container-hover'
              />
            </div>
          </div>
        }
        <ReportAddStatic
          settings={settings}
          handleInputChange={handleInputChange}
          isFundProfile={isFundProfile}
        />
      </ReportEditTemplate>
      {performanceFields.length > 0 &&
        <SimpleReportEdit
          name={"Performance"}
          retractable={true}
          fields={performanceFields}
          handleInputChange={handleInputChange}
          currentState={settings}
        />
      }
      <SimpleReportEdit
        name={"Reporting Period"}
        retractable={true}
        fields={reportingPeriodColumns}
        handleInputChange={(value, property) => {
          if(property === "numberOfYearsToInclude"){
            handleInputChange(parseInt(value), property)
          } else if (property === "reportingPerformancePeriod") {
            if(!get(settings, "numberOfYearsToInclude")) handleInputChange(5, "numberOfYearsToInclude")
            handleInputChange(value, property)
          } else {
            handleInputChange(value, property)
          }
        }}
        currentState={{...settings, plan: (report?.plans ? report?.plans[0] : null)} as any}
      />
      {/* {hasPortfolio && // Removed until new components are created
        <SimpleReportEdit
          name={"Supporting Details"}
          retractable={true}
          fields={supportingColumns}
          handleInputChange={(value, property) => {
            if(property === "DCTotalFundPortfolio.id"){
              const matchingPortfolio = find(totalFunds, (p) => p?.id === parseInt(value))
              handleInputChange(matchingPortfolio, "DCTotalFundPortfolio")
            } else {
              handleInputChange(value, property)
            }
          }}
          currentState={settings}
        />
      } */}
      <SimpleReportEdit
        name={"footnotes"}
        retractable={true}
        fields={PerformanceComparisonFootnoteFields}
        handleInputChange={handleInputChange}
        currentState={settings}
      />
    </>
  )
}

interface ReportAddStaticProps {
  settings: ReportPerformanceComparisonFetchFragment
  handleInputChange: (value: any, property: string) => void
  isFundProfile: boolean
  externalView?: boolean
}

const ReportAddStatic: React.FC<ReportAddStaticProps> = ({ settings, handleInputChange, isFundProfile, externalView }) => {
  const [showSearch, setShowSearch] = useState(false)
  const handleAdd = (addItem:AutocompleteItemTypes, linkUrl: string) => {
    if(addItem.__typename === "ClientPortfolio"){
      handleInputChange(addItem, "clientPortfolio")
    } else if(addItem.__typename === "GroupAutocomplete"){
      handleInputChange({id: addItem.groupId, shortName: addItem.groupName, __typename: "Group"}, "group")
    } else if (addItem.__typename === "VehicleAutocomplete"){
      let currentVehicles = settings.vehicles || []
      handleInputChange([...currentVehicles, {vehicle: {id: addItem.vehicleId, fundid: addItem.vehicleId, name: addItem.vehicleName, product: { product: { manager: { name: addItem.managerName }}} , __typename: "VehicleFields"}}], "vehicles")
    } else if (addItem.__typename === "IndexAutocomplete"){
      let currentIndices = settings.indices || []
      handleInputChange([...currentIndices, {id: addItem.indexId, name: addItem.indexName, __typename: "Index"}], "indices")
    }
    setShowSearch(false)
  }

  let includedRows:string[] = []
  let filters:AutocompleteFilters = {}
  if(externalView){
    filters = {
      group: {
        classCode: ["s","m","l","5","c","h"]
      }
    }
  }

  if(settings.clientPortfolio) includedRows.push(`ClientPortfolio:${settings.clientPortfolio?.id}`)
  if(settings.group) includedRows.push(`Group:${settings.group?.id}`)

  includedRows = includedRows.concat(settings.indices?.map((index) => {
    return `Index:${index?.id}`
  }) || [])

  includedRows = includedRows.concat(settings.vehicles?.map((vehicle) => {
    return `Vehicle:${vehicle?.vehicle?.fundid}`
  }) || [])

  let searchedTypes = ["Portfolios", "Vehicles", "Indices", "Groups"]
  if(isFundProfile) searchedTypes = ["Vehicles", "Indices", "Groups"]

  if(showSearch){
    return <SearchAddStatic
      handleAdd={handleAdd}
      includedRows={includedRows}
      // auth={auth}
      searchedTypes={searchedTypes}
      dropdown
      forPerformanceComparison
      filters={filters}
    />
  }

  return (
    <div className='report-data-points-box mb-2 d-flex justify-content-center text-blue-120 p-2 font-weight-bold cursor-pointer' onClick={() => setShowSearch(true)}>
      <div>
        Add Data Point
        <FontAwesomeIcon
          className='text-blue-120 ml-2'
          icon="plus-circle"
        />
      </div>
    </div>
  )
}

interface PerformanceComparisonOptionsModalProps {
  settings: ReportPerformanceComparisonFetchFragment
  optionModalOpen: boolean
  setOptionModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  report?: ReportsFragment
  updateSettings?: (settings: ReportPerformanceComparisonFetchFragment) => void
  componentId: number
  view: ReportDisplayType
}

const PerformanceComparisonOptionsModal = (props: PerformanceComparisonOptionsModalProps) => {
  const { settings, optionModalOpen, setOptionModalOpen, report, updateSettings, view } = props
  const [settingsCopy, setSettingsCopy] = useState<ReportPerformanceComparisonFetchFragment>(settings)
  let reportingPeriodColumns = [...(settings.reportingPerformancePeriod === ReportingPerformancePeriod.Trailing ? PerformanceComparisonTableFields : PerformanceComparisonYearsFields)]
  if(view === ReportDisplayType.External) reportingPeriodColumns = reportingPeriodColumns.filter((col) => !["performanceComparisonPeriod.fiscalYear","performanceComparisonPeriod.sinceInception"].includes(col.property))

  useEffect(() => {
    setSettingsCopy(settings)
  }, [optionModalOpen, settings])

  const externalView = view === ReportDisplayType.External
  const isFundProfile = report?.category?.code === ReportCategoryCode.FPROF
  const stopPortfolioChange = isFundProfile || externalView
  let colorCounter = 0
  const getColor = () => {
    const color = graphColors[colorCounter].name
    colorCounter++
    if(colorCounter >= graphColors.length) colorCounter = 0
    return color
  }
  const handleInputChange = (value:any, property: string) => {
    setSettingsCopy((prevState) => {
      let newState = cloneDeep(prevState)
      set(newState, property, value)
      return newState
    })
  }
  const handleSubmit = () => {
    if(updateSettings) updateSettings(settingsCopy)
  }
  // if(settingsCopy.clientPortfolio){
  //   getColor() // Portfolio
  //   if(settingsCopy?.portfolioPerformanceType === ManagerPerformanceType.Default && settingsCopy.clientPortfolio.performanceType?.code === ClientPerformanceTypeCode.BOTH) getColor() // Portfolio Net
  //   settingsCopy.clientPortfolio?.performanceTargetMap?.forEach((ptm) => {
  //     getColor() // Portfolio Targets
  //   })
  // }
  return (
    <OptionsModal show={optionModalOpen} setShow={setOptionModalOpen} onSubmit={handleSubmit}>
      <ReportEditTemplate name={"Data Points"}>
        {settingsCopy.clientPortfolio &&
          <div className='report-data-points-box p-2 mb-2 hover-container'>
            <div className='d-flex justify-content-between'>
              <div>
                <span className={classNames("mr-2", "text-"+getColor())} style={{fontSize: 18}}>{getSymbol("circle")}</span>{settingsCopy.clientPortfolio?.name}
              </div>
              {!stopPortfolioChange &&
                <div className='cursor-pointer' onClick={() => handleInputChange(null, "clientPortfolio")}>
                  <FontAwesomeIcon
                    icon="trash"
                    className='show-on-container-hover'
                  />
                </div>
              }
            </div>
            {settingsCopy.portfolioPerformanceType === ManagerPerformanceType.Default && settingsCopy.clientPortfolio.performanceType?.code === ClientPerformanceTypeCode.BOTH && getColor() && ""} {/* skip a color if gross and net would be shown so they line up correctly */}
            {settingsCopy.clientPortfolio?.performanceTargetMap?.map((ptm, idx) => {
              return (
                <div className="ml-3 mt-1" key={idx}>
                  <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("triangle")}</span>{ptm?.target?.name}
                </div>
              )
            })}
            {settingsCopy.clientPortfolio?.styleGroup &&
              <div className="d-flex justify-content-between align-items-center ml-3 mt-1">
                <div>
                  <span>
                    <FontAwesomeIcon
                      icon="bars"
                      className='text-olive-100 mr-2'
                    />
                  </span>{settingsCopy.clientPortfolio?.styleGroup?.shortName}
                </div>
                <div>
                  <FormInput
                    property={"category"}
                    displayName={""}
                    type={"checkbox"}
                    subtype={"show"}
                    idx={(settingsCopy.clientPortfolio?.performanceTargetMap?.length || 0) + 1}
                    editMode={false}
                    propertyVal={!settingsCopy.group?.id}
                    optionSource={"ReportCategoryCode"}
                    updateValue={(value) => null}
                    readonly={true}
                    subClasses={{wrapperClasses: "no-gutters"}}
                  />
                </div>
              </div>
            }
          </div>
        }
        {settingsCopy.vehicles?.map((vehicle, idx) => (
          <div key={idx} className='report-data-points-box p-2 mb-2 hover-container'>
            <div className='d-flex justify-content-between'>
              <div>
                <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("square")} </span>{vehicle?.vehicle?.name}
              </div>
              {(!externalView || (settingsCopy.vehicles?.length || 0) > 1) &&
                <div className='cursor-pointer' onClick={() => handleInputChange(settingsCopy.vehicles?.filter(item => item?.vehicle?.fundid !== vehicle?.vehicle?.fundid), "vehicles")}>
                  <FontAwesomeIcon
                    icon="trash"
                    className='show-on-container-hover'
                  />
                </div>
              }
            </div>
            <div className='d-flex justify-content-between px-1'>
              <div className='ml-3'>
                <small>
                  {vehicle?.vehicle?.product?.product?.manager?.name}
                </small>
              </div>
              <div className='mr-3'>
                <small>
                  {vehicle?.vehicle?.fundid}
                </small>
              </div>
            </div>
          </div>
        ))}
        {settingsCopy.indices?.map((index, idx) => (
          <div key={idx} className='report-data-points-box p-2 mb-2 d-flex justify-content-between hover-container'>
            <div>
              <span className={classNames("mr-2", "text-"+getColor())}>{getSymbol("triangle")} </span>{index?.name}
            </div>
            <div className='cursor-pointer' onClick={() => handleInputChange(settingsCopy.indices?.filter(item => item?.id !== index?.id), "indices")}>
              <small className='mr-2'>
                {index?.id}
              </small>
              <FontAwesomeIcon
                icon="trash"
                className='show-on-container-hover'
              />
            </div>
          </div>
        ))}
        {settingsCopy.group &&
          <div className='report-data-points-box p-2 mb-2 d-flex justify-content-between hover-container'>
            <div>
              <span className='text-olive-100 mr-2'>
                <FontAwesomeIcon
                  icon="bars"
                  className='text-olive-100'
                />
              </span>{settingsCopy.group?.shortName}
            </div>
            <div className={classNames({'cursor-pointer': !externalView})} onClick={() =>  externalView? null : handleInputChange(null, "group")}>
              <small className='mr-2'>
                {settingsCopy.group?.id}
              </small>
              {!externalView &&
                <FontAwesomeIcon
                  icon="trash"
                  className='show-on-container-hover'
                />
              }
            </div>
          </div>
        }
        <ReportAddStatic
          settings={settingsCopy}
          handleInputChange={handleInputChange}
          isFundProfile={stopPortfolioChange}
          externalView={view === ReportDisplayType.External}
        />
      </ReportEditTemplate>
      <SimpleReportEdit
        name={"Time Periods"}
        fields={reportingPeriodColumns}
        handleInputChange={(value, property) => {
          if(property === "numberOfYearsToInclude"){
            handleInputChange(parseInt(value), property)
          } else if (property === "reportingPerformancePeriod") {
            if(!get(settingsCopy, "numberOfYearsToInclude")) handleInputChange(5, "numberOfYearsToInclude")
            handleInputChange(value, property)
          } else {
            handleInputChange(value, property)
          }
        }}
        currentState={settingsCopy}
      />
    </OptionsModal>
  )
}

export const PerformanceComparisonExportSettings = (props: AggregatedExportSettingsProps): ComponentOverrideSettings =>  {
  // const { component } = props
  // const reportState = props.reportState as componentState
  // const tab = reportState?.tab
  // const selectedDrillDown = reportState?.selectedDrillDown

  // return {PerformanceComparison: [
  //   {
  //     componentId: component?.id,
  //     selectedDate: tab?.value,
  //     drillDownGroup: parseInt(selectedDrillDown || "") || undefined
  //   }
  // ]}
  return {}
}

export default PerformanceComparison