import { ApolloClient } from '@apollo/client'
import { IconName, IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import iassign from 'immutable-assign'
import { cloneDeep, compact, find, findIndex, get, isArray, isEmpty, reduce, set, uniqueId } from 'lodash'
import moment from 'moment'
import numbro from "numbro"
import React, { ErrorInfo, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Tooltip, UncontrolledTooltip } from 'reactstrap'
import Auth from '../../../Auth/Auth'
import { ClientPortfolioDetailComponentFragment, ClientPortfolioDetailComponentSettingsFragment, ClientPortfolioDetailLayoutFragment, ComponentOverrideSettings, ComponentType, ExportExcelReportInput, ExportReportInput, FootnoteHistoryFragment, FootnoteShowFragment, FootnoteShowTargetFragment, Maybe, ReportCommonClientPortfolioFragment, ReportCommonVehicleFragment, ReportManagerPerformanceBaseDataRowFragment, ReportsFragment, useExportExcelReportLazyQuery, useExportReportLazyQuery } from '../../../__generated__/graphql'
import { DATE_API_FORMAT, DATE_DISPLAY_FORMAT } from '../../../helpers/constant'
import { downloadWithFilename } from '../../../helpers/download'
import { StoplightReport } from '../../Consultant/Dashboard/Stoplight'
import EditButtons from '../../ui/EditButtons'
import PlaceHolder from '../../ui/PlaceHolder'
import AssetAllocation, { AssetAllocationExportSettings } from '../Components/AssetAllocation'
import AssetDistribution, { AssetDistributionExportSettings } from '../Components/AssetDistribution'
import ManagerPerformance, { ManagerPerformanceAfterPublish, ManagerPerformanceExportSettings } from '../Components/ManagerPerformance'
import PerformanceComparison, { PerformanceComparisonExportSettings } from '../Components/PerformanceComparison'
import { ReportAddable } from './ReportAddable'

// extract type from component
export type selectedComponentProp = {
  type: "section" | "component"
  id: number
}

export enum ReportDisplayType{
  Live = 1,
  Draft = 2,
  External = 3,
}

export const currencyFormat:numbro.Format = {
  output: "currency",
  mantissa: 0,
  thousandSeparated: true,
  negative: "parenthesis",
}

export const currencyWithMantissaFormat:numbro.Format = {
  output: "currency",
  mantissa: 2,
  thousandSeparated: true,
}

export const percentFormat:numbro.Format = {
  output: "percent",
  mantissa: 2,
  negative: "parenthesis",
}

export const percentFormatSign:numbro.Format = {
  output: "percent",
  mantissa: 2,
}

export const rankFormat:numbro.Format = {
  mantissa: 0
}

export const rankingFormat:numbro.Format = {
  mantissa: 2,
  negative: "parenthesis",
}

export const largeMantissaFormat:numbro.Format = {
  mantissa: 8,
  negative: "parenthesis",
}

export const longPercentFormat:numbro.Format = {
  output: "percent",
  mantissa: 6,
  negative: "parenthesis",
}

export type AggregatedComponentProps = {
  report?: ReportsFragment
  component: ClientPortfolioDetailComponentFragment
  auth: Auth
  view: ReportDisplayType
  sectionNumber: number
  componentNumber: number
  editMode: boolean
  selected?: boolean
  handleSelect?: (e: React.MouseEvent) => void
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editedDraftLayout: ClientPortfolioDetailLayoutFragment
  setSelectedComponentId?: (value:selectedComponentProp) => void
  setReportState: (value:React.SetStateAction<object>) => void
  reportState: object
  hideSingleExport?: boolean
  clientId?: number
  overwriteDate?: string
}

type ReportComponentProps = {
  report?: ReportsFragment
  component: ClientPortfolioDetailComponentFragment
  auth: Auth
  view: ReportDisplayType
  selectedComponentId?: selectedComponentProp
  setSelectedComponentId?: (value:selectedComponentProp) => void
  sectionNumber: number
  componentNumber: number
  editMode: boolean
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editedDraftLayout: ClientPortfolioDetailLayoutFragment
  setReportState: (value:React.SetStateAction<object>) => void
  reportState: object
  hideSingleExport?: boolean
  clientId?: number
  overwriteDate?: string
}

const ComponentMapping: { [key in ComponentType]?: React.FC<AggregatedComponentProps>} = {
  [ComponentType.AssetAllocation]: AssetAllocation,
  [ComponentType.AssetDistribution]: AssetDistribution,
  [ComponentType.ManagerPerformance]: ManagerPerformance,
  [ComponentType.PerformanceComparison]: PerformanceComparison,
  [ComponentType.Stoplight]: StoplightReport,
}

export const IconMapping: { [key in ComponentType]?: IconProp} = {
  [ComponentType.AssetAllocation]: ["fal", "chart-pie"],
  [ComponentType.AssetDistribution]: ["fal", "table"],
  [ComponentType.ManagerPerformance]: ["fal", "table"],
  [ComponentType.PerformanceComparison]: ["fal", "table"],
}

export type AggregatedAfterPublishProps = AggregatedComponentProps & {
  publishCount: number
}

export const AfterPublishMapping: { [key in ComponentType]?: React.FC<AggregatedAfterPublishProps>} = {
  [ComponentType.ManagerPerformance]: ManagerPerformanceAfterPublish,
}

export type AggregatedExportSettingsProps = AggregatedComponentProps & {
  graphqlClient: ApolloClient<any>
}

export const ExportSettingsMapping: { [key in ComponentType]?: (props: AggregatedExportSettingsProps) => ComponentOverrideSettings | undefined} = {
  [ComponentType.AssetAllocation]: AssetAllocationExportSettings,
  [ComponentType.AssetDistribution]: AssetDistributionExportSettings,
  [ComponentType.ManagerPerformance]: ManagerPerformanceExportSettings,
  [ComponentType.PerformanceComparison]: PerformanceComparisonExportSettings,
}

const ReportComponent: React.FC<ReportComponentProps> = (props) => {
  const type = props.component.type
  if(!type) return(<ErrorComponent name={props.component.name || ""} error="Component type not chosen"/>)

  const { selectedComponentId, setSelectedComponentId, setReportState, reportState, ...passedOnProps} = props
  const selected = props.component.id === selectedComponentId?.id && selectedComponentId?.type === "component" && props.editMode
  const handleSelect = (e: React.MouseEvent) => {
    e.stopPropagation()
    if(props.editMode && setSelectedComponentId) setSelectedComponentId({id: props.component.id, type: "component"})
  }
  const component = ComponentMapping[type]
  const setComponentReportState = (value: object) => {
    setReportState(prev => {
      let newState = cloneDeep(prev)
      const prevValue = cloneDeep(get(newState, props.component.id.toString(), {}))
      const newValue = {...prevValue, ...value}
      set(newState, props.component.id.toString(), newValue)
      return newState
    })
  }
  if(component)return React.createElement(component, {selected, handleSelect, setSelectedComponentId, setReportState: setComponentReportState, reportState: get(reportState, props.component.id.toString(), {}), ...passedOnProps})

  return(<ErrorComponent name={props.component.name || ""} error="Component not Implemented"/>)
}

export type ReportTab = {
  label: string
  value: any
  primary?: boolean
}

type TemplateComponentProps = {
  auth?: Auth
  name?: string
  componentTypeName?: string
  rightText?: string | JSX.Element
  selected?: boolean
  onClick?: (e: React.MouseEvent) => void
  tabs?: ReportTab[]
  onTabChange?: (value:any) => void
  currentTab?: ReportTab
  tooltipProps?: ToolbarProps
  editMode?: boolean
  view?: ReportDisplayType
  hideTitle?: boolean
  sectionNumber?: number
  componentNumber?: number
  report?: ReportsFragment
  editedDraftLayout?: ClientPortfolioDetailLayoutFragment
  setEditedDraftLayout?: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  setSelectedComponentId?: (value:selectedComponentProp) => void
  hideExport?: boolean
}

// The base look of a component so that they all look uniform
export const TemplateComponent: React.FC<TemplateComponentProps> = ({name, componentTypeName, children, rightText, selected, onClick, tabs, onTabChange, currentTab, tooltipProps, editMode, sectionNumber, report, editedDraftLayout, setEditedDraftLayout, hideTitle, componentNumber, setSelectedComponentId, view, auth, hideExport}) => {
  const [hovering, setHovering] = useState(false)
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false)
  const hasAddable = (editMode && (sectionNumber || sectionNumber === 0) && componentNumber === -1 && report && editedDraftLayout && setEditedDraftLayout) || tooltipProps
  const exportOptions = tooltipProps?.exportOptions
  const exportExcelOptions = tooltipProps?.exportExcelOptions
  const [getExportUrl, {loading: exportLoading}] = useExportReportLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if(data.exportReport.url) downloadWithFilename(data.exportReport.url, exportOptions?.name + ".pptx")
    }
  })
  const [getExportExcelUrl, {loading: exportExcelLoading}] = useExportExcelReportLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if(data.exportExcelReport.url) downloadWithFilename(data.exportExcelReport.url, exportExcelOptions?.name + ".xlsx")
    }
  })
  const exportFunction = tooltipProps?.exportFunction
  const refreshFunction = tooltipProps?.refreshFunction
  const openOptionsModal = tooltipProps?.openOptionsModal
  const showRefresh = !!refreshFunction && view === ReportDisplayType.Draft
  const showExport = !hideExport && !editMode && (exportOptions || !!exportFunction || exportExcelOptions) && view !== ReportDisplayType.External && (view === ReportDisplayType.Draft || auth?.checkPermissions(['export:report']))
  const multipleExports = compact([exportOptions, exportExcelOptions, exportFunction]).length > 1
  const showOptions = (!!openOptionsModal && !editMode)
  const showComponentTypeName = false && view === ReportDisplayType.External && componentNumber === -1 && !!componentTypeName && sectionNumber === 0 // No longer Required
  const handleExport = () => {
    if(exportOptions && !exportLoading) {
      getExportUrl({variables: {input: exportOptions}})
    } else if(exportFunction) {
      exportFunction()
    }
  }
  const handleExcelExport = () => {
    if(exportExcelOptions && !exportExcelLoading) {
      getExportExcelUrl({variables: {input: exportExcelOptions}})
    }
  }
  return (
    <div>
      {showComponentTypeName &&
        <div className="form-section-title headline underline small-font-size py-2 mb-2">
          {componentTypeName}
        </div>
      }
      <div className={classNames({"mt-4": componentNumber === -1 && !showComponentTypeName})} onMouseEnter={()=> setHovering(true)} onMouseLeave={() => setHovering(false)}>
        {editMode && (sectionNumber || sectionNumber === 0) && componentNumber === -1 && report && editedDraftLayout && setEditedDraftLayout &&
          <ReportAddable
            show={hovering}
            sectionNumber={sectionNumber}
            setSelectedComponentId={setSelectedComponentId}
            componentNumber={-1}
            report={report}
            setEditedDraftLayout={setEditedDraftLayout}
            editedDraftLayout={editedDraftLayout}
          />
        }
        {/* <TemplateToolbar
          tooltipProps={tooltipProps}
          editMode={!!editMode}
          view={view}
          show={hovering}
          auth={auth}
        /> */}
        <div className={classNames("pane pane-table pane-profile pb-3 w-100", {"pane-selected": selected, "pane-addable": (hasAddable)})} onClick={(e: React.MouseEvent) => {if(onClick)onClick(e)}}>
          {!hideTitle &&
            <div className="pane-title d-flex justify-content-between">
              <div className='hover-expand-container'>
                {showRefresh &&
                  <div
                    className={classNames('d-inline-block cursor-pointer text-callan-blue hover-expand-button mr-2', {expanded: hovering})}
                    onClick={refreshFunction}
                  >
                    <FontAwesomeIcon
                      icon={["fas", "sync"]}
                      size="lg"
                      className={classNames(hovering ? "text-callan-blue" : "text-grey", "vertical-align-middle m-2")}
                    />
                  </div>
                }
                {showOptions &&
                  <div className={classNames('d-inline-block cursor-pointer text-callan-blue hover-expand-button mr-2', {expanded: hovering})} onClick={() => {if(openOptionsModal)openOptionsModal()}}>
                    <FontAwesomeIcon
                      icon={["fas", "cog"]}
                      size="lg"
                      className={classNames(hovering ? "text-callan-blue" : "text-grey", "vertical-align-middle m-2")}
                    />
                  </div>
                }
                {showExport && multipleExports &&
                  <ButtonDropdown isOpen={exportDropdownOpen} toggle={() => setExportDropdownOpen(!exportDropdownOpen)} className='export-dropdown'>
                    <DropdownToggle caret={hovering} color="export" className={classNames('mr-2 pl-0 py-0 pr-2 btn-no-style', {expanded: hovering})}>
                      <FontAwesomeIcon
                        icon={(exportLoading || exportExcelLoading) ? "spinner-third" : ["fas", "file-download"]}
                        spin={exportLoading || exportExcelLoading}
                        size="lg"
                        className={classNames(hovering ? "text-callan-blue" : "text-grey", "vertical-align-middle m-2")}
                      />
                      {hovering &&
                        <div className='d-inline-block mx-2 vertical-align-middle'>
                          Export
                        </div>
                      }
                    </DropdownToggle>
                    <DropdownMenu>
                      {(exportOptions || !!exportFunction) &&
                        <DropdownItem key={'exportPPT'} onClick={handleExport}>
                          <img src='/assets/PPTX.svg' className="mr-2"/>
                          PPT
                        </DropdownItem>
                      }
                      {exportExcelOptions &&
                        <DropdownItem key={'exportXLSX'} onClick={handleExcelExport}>
                          <img src='/assets/XLS.svg' className="mr-2"/>
                          Excel
                        </DropdownItem>
                      }
                    </DropdownMenu>
                  </ButtonDropdown>
                }
                {showExport && !multipleExports &&
                  <div
                    className={classNames('d-inline-block cursor-pointer text-callan-blue hover-expand-button mr-2', {expanded: hovering})}
                    onClick={handleExport}
                  >
                    {exportExcelOptions && !(exportLoading || exportExcelLoading) &&
                      <img src='/assets/XLS.svg' className="mx-2"/>
                    }
                    {!exportExcelOptions && !(exportLoading || exportExcelLoading) &&
                      <img src='/assets/PPTX.svg' className="mx-2"/>
                    }
                    {(exportLoading || exportExcelLoading) &&
                      <FontAwesomeIcon
                        icon={"spinner-third"}
                        spin={true}
                        size="lg"
                        className={classNames(hovering ? "text-callan-blue" : "text-grey", "vertical-align-middle m-2")}
                      />
                    }
                    {hovering &&
                      <div className='d-inline-block mx-2 vertical-align-middle'>
                        Export
                      </div>
                    }
                  </div>
                }
                <h3 className='d-inline-block vertical-align-middle'>{name}</h3>
              </div>
              <div className="d-flex">
                {!!tabs && tabs.length > 1 && tabs.map((tab, idx) => {
                  const handleTabChange = () => {
                    if(onTabChange) onTabChange(tab)
                  }
                  return(
                    <Nav className="sub-nav sub-nav-secondary collapsed" tabs role="group" key={`${idx}-${tab.label}`}>
                      <NavItem>
                        <NavLink
                          className={classNames("report-tab small-font-size",{
                            active: currentTab?.label === tab.label && currentTab.value === tab.value,
                            primary: tab.primary
                          })}
                          onClick={() => handleTabChange()}
                        >
                          {tab.label}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  )
                })}
              </div>
              {rightText &&
                <div className="pane-right-text">{rightText}</div>
              }
            </div>
          }
          <div className="overflow-auto">
            {children}
          </div>
        </div>
        {editMode && (sectionNumber || sectionNumber === 0) && componentNumber === -1 && report && editedDraftLayout && setEditedDraftLayout &&
          <ReportAddable
            show={hovering}
            sectionNumber={sectionNumber + 1}
            setSelectedComponentId={setSelectedComponentId}
            componentNumber={-1}
            report={report}
            setEditedDraftLayout={setEditedDraftLayout}
            editedDraftLayout={editedDraftLayout}
          />
        }
      </div>
    </div>
  )
}

export type ToolbarProps = {
  moveButtons?: boolean
  refreshFunction?: () => void
  trashFunction?: () => void
  exportOptions?: ExportReportInput
  exportExcelOptions?: ExportExcelReportInput
  exportFunction?: () => void
  openOptionsModal?: () => void
}

interface TemplateToolbarProps {
  tooltipProps?: ToolbarProps
  editMode: boolean
  view?: ReportDisplayType
  show: boolean
  auth?: Auth
}

export const TemplateToolbar: React.FC<TemplateToolbarProps> = ({tooltipProps, editMode, view, show, auth}) => {
  const exportOptions = tooltipProps?.exportOptions
  const exportExcelOptions = tooltipProps?.exportExcelOptions
  const [getExportUrl, {loading: exportLoading}] = useExportReportLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if(data.exportReport.url) downloadWithFilename(data.exportReport.url, exportOptions?.name + ".pptx")
    }
  })
  const [getExportExcelUrl, {loading: exportExcelLoading}] = useExportExcelReportLazyQuery({
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if(data.exportExcelReport.url) downloadWithFilename(data.exportExcelReport.url, exportExcelOptions?.name + ".xlsx")
    }
  })
  if(!show || !tooltipProps){
    return (<></>)
  }
  const {moveButtons, refreshFunction, trashFunction} = tooltipProps
  const showDrag = editMode && moveButtons
  const showRefresh = !!refreshFunction
  const showExport = false && !editMode && exportOptions && (view === ReportDisplayType.Draft || auth?.checkPermissions(['export:report']))
  const showDelete = editMode && trashFunction
  if(!showDrag && !showRefresh && !showExport && !showDelete){
    return (<></>)
  }
  const handleExport = () => {
    if(exportOptions && !exportLoading) {
      getExportUrl({variables: {input: exportOptions}})
    }
  }
  const handleExcelExport = () => {
    if(exportExcelOptions && !exportExcelLoading) {
      getExportExcelUrl({variables: {input: exportExcelOptions}})
    }
  }
  return (
    <div className={classNames('pane-top-tooltip background-white d-inline-block')}>
      {showDrag &&
        <div className='d-inline-block'>
          <FontAwesomeIcon
            icon={["fas", "grip-vertical"]}
            size="lg"
            className="m-2 text-callan-blue cursor-pointer"
          />
          <FontAwesomeIcon
            icon={["fas", "chevron-up"]}
            size="lg"
            className="m-2 text-callan-blue cursor-pointer"
          />
          <FontAwesomeIcon
            icon={["fas", "chevron-down"]}
            size="lg"
            className="m-2 text-callan-blue cursor-pointer"
          />
        </div>
      }
      {showRefresh &&
        <div className='d-inline-block border-blue-100'>
          <FontAwesomeIcon
            icon={["fas", "sync"]}
            size="lg"
            className="m-2 text-callan-blue cursor-pointer"
            onClick={refreshFunction}
          />
        </div>
      }
      {showExport &&
        <div
          className='d-inline-block border-left border-blue-100 cursor-pointer text-callan-blue'
          onClick={handleExport}
        >
          <FontAwesomeIcon
            icon={exportLoading ? "spinner-third" : ["fas", "file-download"]}
            spin={exportLoading}
            size="lg"
            className="m-2 text-callan-blue"
          />
          <div className='d-inline-block vertical-align-super mr-2'>
            Export
          </div>
        </div>
      }
      {showDelete &&
        <div className='d-inline-block border-left border-blue-100'>
          <FontAwesomeIcon
            icon={["far", "trash-alt"]}
            size="lg"
            className="m-2 text-callan-blue cursor-pointer"
            onClick={trashFunction}
          />
        </div>
      }
    </div>
  )
}

interface OptionsModalProps {
  tooltipProps?: ToolbarProps
  view?: ReportDisplayType
  show: boolean
  setShow: (value:React.SetStateAction<boolean>) => void
  onSubmit: () => void
}

export const OptionsModal: React.FC<OptionsModalProps> = ({tooltipProps, view, show, setShow, children, onSubmit}) => {
  const toggle = () => setShow(!show)
  return (
    <Modal size="md" className="mt-5 client-report-edit-options" isOpen={show} toggle={toggle} zIndex={1500}>
      <ModalHeader toggle={toggle}>Options</ModalHeader>
      <ModalBody>
        {children}
      </ModalBody>
      <ModalFooter>
        <EditButtons editMode={true} setEditMode={() => true} cancelEdit={toggle} saving={false} onSubmit={onSubmit} disableOnError={true} saveText={"View"}/>
      </ModalFooter>
    </Modal>
  )
}


type ErrorComponentProps = {
  name?: string
  rightText?: string
  error?: string
  selected?: boolean
  onClick?: (e: React.MouseEvent) => void
}

// When errors happen show this component
export const ErrorComponent: React.FC<ErrorComponentProps> = ({name, error, selected, onClick, rightText}) => {
  return (
    <div className={classNames("pane pane-table pane-profile pane-error-component mt-4 pb-3", {"pane-selected": selected})} onClick={(e) => {if(onClick)onClick(e)}}>
      <div className="pane-title d-flex justify-content-between">
        <h3>{name}</h3>
        {rightText &&
          <div className="pane-right-text">{rightText}</div>
        }
      </div>
      <div className="w-100 m-3 py-4 d-flex align-items-center justify-content-center">
        <div>
          <strong>Error Rendering Component</strong> | {error}
        </div>
      </div>
    </div>
  )
}

type LoadingComponentProps = {
  name?: string
  rightText?: string
  selected?: boolean
  onClick?: (e: React.MouseEvent) => void
  tabs?: {label: string, value: any}[]
  onTabChange?: (value:any) => void
  currentTab?: {label: string, value: any}
  componentNumber?: number
}

// When errors happen show this component
export const LoadingComponent: React.FC<LoadingComponentProps> = ({name, rightText, selected, onClick, tabs, onTabChange, currentTab, componentNumber}) => {
  return (
    <TemplateComponent
      name={name}
      rightText={rightText}
      selected={selected}
      onClick={onClick}
      tabs={tabs}
      onTabChange={onTabChange}
      currentTab={currentTab}
      componentNumber={componentNumber}
    >
      <PlaceHolder height={300}/>
    </TemplateComponent>
  )
}

export type NotedColumn = {
  label: string
  notes: string[]
}

export type FootnoteLink = {
  label?: string
  url: string
  external?: boolean
}

export type LinkableReportFragments = ReportCommonClientPortfolioFragment|ReportCommonVehicleFragment

interface getReportLinksProps {
  component?: LinkableReportFragments
  reportId?: number
  clientId?: number
  portfolioId?: number
  view: ReportDisplayType
}

export const getReportLinks = (props:getReportLinksProps): FootnoteLink[] => {
  const {component, reportId, clientId, view, portfolioId } = props
  if(component?.__typename === "ClientPortfolio") return getPortfolioLinks({component, reportId, clientId, view, portfolioId})
  if(component?.__typename === "VehicleFields") return getVehicleLinks({component})
  return []
}

const getPortfolioLinks = ({component, reportId, clientId, view, portfolioId}:{component: ReportCommonClientPortfolioFragment, reportId?: number, clientId?: number, view: ReportDisplayType, portfolioId?: number}): FootnoteLink[] => {
  if(component.isComposite) return []
  let links = []
  if(component.id !== portfolioId){
    if(view === ReportDisplayType.Draft){
      links.push({label: "Report Details", url: "/reports/" + reportId + "/" + component.id, external: false})
    } else if (view === ReportDisplayType.Live){
      links.push({label: "Report Details", url: `/clients/${clientId}/${reportId}/report/${component.id}`, external: false})
    }
  }
  if(component.relatedVehicle?.vehicle?.product?.product?.id){
    links.push({label: "Product Profile", url: "/products/" + component.relatedVehicle?.vehicle?.product?.product?.id + "/profile", external: true})
  }
  return links
}

const getVehicleLinks = ({component}:{component: ReportCommonVehicleFragment}): FootnoteLink[] => {
  let links = [{url: "/products/" + component?.product?.product?.id + "/profile", external: true}]
  return links
}

interface getPortfolioReportLinksProps {
  componentId?: number | string
  reportId?: number
  clientId?: number
  productId?: number
  view: ReportDisplayType
}

export const getPortfolioRowLinks = ({view, productId, componentId, clientId, reportId}:getPortfolioReportLinksProps): FootnoteLink[] => {
  // if(component.isComposite) return []
  let links: FootnoteLink[] = []
  // if(componentId !== portfolioId){
    if(view === ReportDisplayType.Draft){
      links.push({label: "Report Details", url: "/reports/" + reportId + "/" + componentId, external: false})
    } else if (view === ReportDisplayType.Live){
      links.push({label: "Report Details", url: `/clients/${clientId}/${reportId}/report/${componentId}`, external: false})
    }
  // }
  if(!!productId){
    links.push({label: "Product Profile", url: "/products/" + productId + "/profile", external: true})
  }
  return links
}

type FootnotableComponentProps = {
  footnote?: FootnoteShowFragment | null
  preDefinedFootnote?: ReportManagerPerformanceBaseDataRowFragment["footnote"]
  bareFootnote?: string | null
  bareTargetFootnotes?: string[]
  targetFootnotes?: Maybe<FootnoteShowTargetFragment>[] | null
  showFootnote?: boolean | null
  showTargetFootnote?: boolean | null
  errors?: NotedColumn[] | ReportManagerPerformanceBaseDataRowFragment["errors"] | string[]
  showErrors?: boolean | null
  warnings?: NotedColumn[] | ReportManagerPerformanceBaseDataRowFragment["warnings"]
  showWarnings?: boolean | null
  selectedDate?: string
  links?: FootnoteLink[]
  linkClasses?: string[]
}

export const FootnotableComponent: React.FC<FootnotableComponentProps> = ({ footnote, preDefinedFootnote, targetFootnotes, children, showFootnote, showTargetFootnote, errors, showErrors, warnings, showWarnings, selectedDate, links, bareFootnote, bareTargetFootnotes, ...props }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  let linkClasses = ["d-inline"]
  if (isArray(props.linkClasses)) linkClasses.push(...props.linkClasses)

  const linkCount = links?.length || 0
  const firstLink = links && links[0]
  if(linkCount > 1) {
    linkClasses.push("fake-link")
    linkClasses.push("hover-transition-underline")
  }
  if(firstLink?.external){
    linkClasses.push("hover-container position-relative")
  }

  const history = useHistory()
  const toggle = (event: React.MouseEvent<any, MouseEvent>) => {
    const relatedTarget = event.relatedTarget as any;
    if(tooltipOpen && !(relatedTarget?.closest('.footnote-mouse-tracker'))) {
      setTooltipOpen(false)
    } else {
      setTooltipOpen(true)
    }
  };
  const getNoteForDate = (note?: FootnoteShowFragment | FootnoteShowTargetFragment | null, date?: string) => {
    let usedInstance = reduce(note?.history, (found, history) => {
      if(!history || !date) return found
      if(moment(history?.date).diff(moment(date)) >= 0){
        if(moment(history?.date).diff(moment(found?.date)) <= 0){
          return history
        }
      }
      return found
    }, {text: note?.text, date: note?.date} as FootnoteHistoryFragment)
    return usedInstance?.text || ""
  }
  const tooltipRef = useRef<HTMLDivElement>(null)

  const [errorTooltipOpen, setErrorTooltipOpen] = useState(false);
  const errorToggle = (event: React.MouseEvent<any, MouseEvent>) => {
    const relatedTarget = event.relatedTarget as any;
    if(errorTooltipOpen && !(relatedTarget.closest('.footnote-mouse-tracker'))) {
      setErrorTooltipOpen(false)
    } else {
      setErrorTooltipOpen(true)
    }
  };
  const errorTooltipRef = useRef<HTMLDivElement>(null)
  const hasFutureFootnote = footnote && selectedDate && moment(footnote.date).diff(moment(selectedDate)) >= 0

  const hasFutureTargetFootnote = targetFootnotes && selectedDate && !!find(targetFootnotes, (tf) => tf && moment(tf?.date).diff(moment(selectedDate)) >= 0)

  const footnoteValue = getNoteForDate(footnote, selectedDate).trim()
  const hasFootnote = showFootnote && !!footnote && (!selectedDate || hasFutureFootnote) && !!footnoteValue
  const hasTargetFootnote = (showTargetFootnote && !isEmpty(compact(targetFootnotes)) && (!selectedDate || hasFutureTargetFootnote)) && targetFootnotes?.some((tf) => !!getNoteForDate(tf, selectedDate).trim())
  const hasBareTargetFootnotes = !!bareTargetFootnotes && compact(bareTargetFootnotes).length > 0
  const hasErrors = (showErrors && !isEmpty(errors))
  const hasWarnings = (showWarnings && !isEmpty(warnings))
  const handleLink = (link?:FootnoteLink) => {
    // const link = get(links, `[${linkNumber}]`)
    if(!link) return
    if(link.external){
      window.open(link.url, '_blank')
    } else {
      history.push(link.url || "")
    }
  }
  const tooltipId = useMemo(() => `footnote-tooltip-${uniqueId()}`, [])
  const tooltipItem = (linkNumber:number, link?:FootnoteLink) => {
    if(!link) return <React.Fragment key={linkNumber}/>
    return (
      <div key={linkNumber} onClick={() => handleLink(link)} className={classNames("text-align-left cursor-pointer", {"border-bottom pb-2 pt-1": linkNumber === 0, "pb-1 pt-2": linkNumber === 1, "py-1": linkNumber > 1 })}>
        {link.label}
        {link.external &&
          <FontAwesomeIcon icon="external-link" className='ml-2'/>
        }
      </div>
    )
  }
  return (
    <>
      {linkCount > 1 &&
        <UncontrolledTooltip placement='top' target={tooltipId} delay={200} autohide={false}>
          {links?.map((link, idx) => tooltipItem(idx,link))}
        </UncontrolledTooltip>
      }
      <div className={classNames(linkClasses)} onClick={() => handleLink(firstLink)} id={tooltipId}>
        {children}
        {firstLink?.external &&
          <FontAwesomeIcon icon="external-link" className="show-on-container-hover text-blue-80 position-absolute right-negative-20px"/>
        }
      </div>
      {(hasFootnote || hasTargetFootnote || !!preDefinedFootnote || bareFootnote) &&
        <>
          <div className="footnote-icon d-inline" ref={tooltipRef}>
            <FontAwesomeIcon
              icon={"info-circle" as IconName}
              size="sm"
            />
          </div>
          <Tooltip className="footnote-tooltip" placement="top" isOpen={tooltipOpen} target={tooltipRef} toggle={toggle}>
            <div className='footnote-mouse-tracker' onMouseLeave={() => setTooltipOpen(false)}>
              {hasFootnote &&
                <>
                  {hasTargetFootnote && <strong>Portfolio: </strong>}
                  <div>
                    {getNoteForDate(footnote, selectedDate)}
                  </div>
                </>
              }
              {hasTargetFootnote && targetFootnotes?.map((targetFootnote, idx) => {
                let clientPortfolioTargets = targetFootnote?.clientPortfolioTargets
                let target = clientPortfolioTargets && clientPortfolioTargets[0] ? clientPortfolioTargets[0].target : {name: ""}
                return(
                  <div key={idx}>
                    <strong>{target?.name || ""}:</strong> {getNoteForDate(targetFootnote, selectedDate)}
                  </div>
                )
              })}
              {hasBareTargetFootnotes && bareTargetFootnotes?.map((note, idx) => {
                return(
                  <div key={idx}>
                    {note}
                  </div>
                )
              })}
              {!!preDefinedFootnote &&
                <div>
                  {preDefinedFootnote.note}
                </div>
              }
              {!!bareFootnote &&
                <div>
                  {bareFootnote}
                </div>
              }
            </div>
          </Tooltip>
        </>
      }
      {(hasErrors || hasWarnings) &&
        <>
          <div className="footnote-icon d-inline text-error" ref={errorTooltipRef}>
            <FontAwesomeIcon
              icon={"question-circle" as IconName}
              size="sm"
              className={hasErrors ? 'text-danger' : 'text-warning'}
            />
          </div>
          <Tooltip className="footnote-tooltip" placement="top" isOpen={errorTooltipOpen} target={errorTooltipRef} toggle={errorToggle}>
            <div className='footnote-mouse-tracker' onMouseLeave={() => setErrorTooltipOpen(false)}>
              {hasErrors &&
                <>
                  {hasWarnings && <strong>Error: </strong>}
                  {errors?.map((note, idx) =>{
                    if (typeof note === "string") {
                      return (
                        <div key={idx}>
                          {note}
                        </div>
                      )
                    }else if(!!note && "__typename" in note && note.__typename === "ManagerPerformanceMessages"){
                      return (
                        <div key={idx}>
                          <strong>{note?.period}: </strong> {note?.messages.join(", ")}
                        </div>
                      )
                    } else if (!!note && "label" in note){
                      return (
                        <div key={idx}>
                          <strong>{note?.label}: </strong> {note?.notes.join(", ")}
                        </div>
                      )
                    }
                    return <React.Fragment key={idx}/>
                  })}
                </>
              }
              {hasWarnings &&
                <>
                  {hasErrors && <strong>Warning: </strong>}
                  {warnings?.map((note, idx) => {
                    if(!!note && "__typename" in note && note.__typename === "ManagerPerformanceMessages"){
                      return (
                        <div key={idx}>
                          <strong>{note?.period}: </strong> {note?.messages.join(", ")}
                        </div>
                      )
                    } else if (!!note && "label" in note){
                      return (
                        <div key={idx}>
                          <strong>{note?.label}: </strong> {note?.notes.join(", ")}
                        </div>
                      )
                    }
                    return <React.Fragment key={idx}/>
                  })}
                </>
              }
            </div>
          </Tooltip>
        </>
      }
    </>
  )
}


type BaseComponentProps<Fragment> = {
  report?: ReportsFragment
  component: ClientPortfolioDetailComponentFragment
  auth: Auth
  view: ReportDisplayType
  expectedTypename: string
  reactComponent: React.FC<IndividualComponentProps<Fragment>>
  sectionNumber: number
  componentNumber: number
  editMode: boolean
  selected?: boolean
  handleSelect?: (e: React.MouseEvent) => void
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editedDraftLayout: ClientPortfolioDetailLayoutFragment
  setReportState: (value:object) => void
  reportState: object
  clientId?: number
  overwriteDate?: string
}

type BaseComponentState<Fragment> = {
  error?: string
  settings?: Fragment
}

export type IndividualComponentProps<Fragment> = {
  report?: ReportsFragment
  component: ClientPortfolioDetailComponentFragment
  auth: Auth
  view: ReportDisplayType
  settings: Fragment
  sectionNumber: number
  componentNumber: number
  editMode: boolean
  selected?: boolean
  handleSelect?: (e: React.MouseEvent) => void
  portfolioId?: number
  setSelectedComponentId?: (value:selectedComponentProp) => void
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editedDraftLayout: ClientPortfolioDetailLayoutFragment
  updateSettings: (value:Fragment) => void
  setReportState: (value:object) => void
  reportState: object
  hideSingleExport?: boolean
  clientId?: number
  overwriteDate?: string
}

// Fetch and make sure that the settings returned match what they should
// Then render the component passed in with reactComponent and the now fetched settings
// Need to pass the fragment of the settings
export class BaseComponent<Fragment extends ClientPortfolioDetailComponentSettingsFragment> extends React.Component<BaseComponentProps<Fragment>, BaseComponentState<Fragment>> {
  constructor(props:BaseComponentProps<Fragment>) {
    super(props)
    const {component, view}= props
    let settings: Fragment | undefined = undefined
    let error: string = ""
    if(view === ReportDisplayType.Draft){
      if(component.draftSettings?.__typename === props.expectedTypename){
        settings = component.draftSettings as Fragment
      } else {
        error= "Draft settings don't match expected type"
      }
    } else if (view === ReportDisplayType.Live) {
      if(component.liveSettings?.__typename === props.expectedTypename){
        settings = component.liveSettings as Fragment
      } else {
        error= "Live settings don't match expected type"
      }
    } else if (view === ReportDisplayType.External) {
      if(component.draftSettings?.__typename === props.expectedTypename){
        settings = component.draftSettings as Fragment
      } else {
        error= "External settings don't match expected type"
      }
    } else {
      error= "View not supported"
    }
    this.state = {
      error,
      settings,
    }
  }

  static getDerivedStateFromProps<Fragment extends ClientPortfolioDetailComponentSettingsFragment>(props: BaseComponentProps<Fragment>, state:BaseComponentState<Fragment>) {
    const {component, view}= props
    let settings: Fragment | undefined = undefined
    let error: string | undefined = undefined
    if(view === ReportDisplayType.Draft){
      if(component.draftSettings?.__typename === props.expectedTypename){
        settings = component.draftSettings as Fragment
      } else {
        error= "Draft settings don't match expected type"
      }
    } else if (view === ReportDisplayType.Live) {
      if(component.liveSettings?.__typename === props.expectedTypename){
        settings = component.liveSettings as Fragment
      } else {
        error= "Live settings don't match expected type"
      }
    } else if (view === ReportDisplayType.External) {
      if(component.draftSettings?.__typename === props.expectedTypename){
        settings = component.draftSettings as Fragment
      } else {
        error= "External settings don't match expected type"
      }
    } else {
      error= "View not supported"
    }
    return {
      settings,
      error: error || state.error,
    }
  }

  componentDidCatch(error:Error, info:ErrorInfo) {
    this.setState({ error: error.message });
    console.log({component: this.props.component, error, info})
  }

  updateSettings = (settings:Fragment) => {
    this.props.setEditedDraftLayout((prevState: any) => {
      let newState = iassign(
        prevState,
        currentState => currentState?.sections,
        sectionsTable => {
          let sections = cloneDeep(sectionsTable)
          var selectedSection = findIndex(sections, (o:any) => {return !!find(o?.components, {id: this.props.component.id})})
          if(sections && selectedSection >= 0){
            sections = iassign(
              sections,
              currentSection => currentSection[selectedSection]?.components,
              componentsTable => {
                let components = cloneDeep(componentsTable)
                var editedComponent = find(components, {id: this.props.component.id})
                if(this.props.view === ReportDisplayType.Live){
                  set(editedComponent, 'liveSettings', settings);
                } else {
                  set(editedComponent, 'draftSettings', settings);
                }
                return components
              }
            )
          }
          return sections
        }
      )
      return newState
    })
  }

  render() {
    const name = this.props.component.name || ""
    // const { reactComponent, component, auth, selected, handleSelect } = this.props
    const { expectedTypename, reactComponent, ...passedInProps } = this.props
    const settings = this.state.settings
    let rightText = undefined
    if(settings && "date" in settings){
      const asOfDate = moment(this.props.overwriteDate || settings.date, DATE_API_FORMAT).format(DATE_DISPLAY_FORMAT)
      rightText = `As of ${asOfDate}`
    }
    if(this.state.error){
      return(<ErrorComponent name={name} error={this.state.error} rightText={rightText}/>)
    } else if (settings) {
      // Render the reactComponent from props
      return(React.createElement(reactComponent, {settings, updateSettings: this.updateSettings, ...passedInProps}))
    }
    return(<ErrorComponent name={name} error={"No settings for component"} rightText={rightText}/>)
  }
}

export default ReportComponent