import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import DOMPurify from 'dompurify'
import { ContentState, convertFromHTML, convertToRaw, EditorState, Modifier } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import React, { Component } from 'react'
import { Editor, SyntheticKeyboardEvent } from 'react-draft-wysiwyg'
import { Button } from 'reactstrap'

const toolbarOptions = {
  inline: {
    options: ["bold", "italic", "underline"],
  },
  list: {
    options: ["unordered"],
  },
  link: {
    //CAL-1406
    defaultTargetOption: '_blank',
  },
  options: [
    // "blockType",
    // "fontSize",
    // "fontFamily",
    "list",
    "inline",
    // "textAlign",
    // "colorPicker",
    "link",
    // "embedded",
    // "emoji",
    // "image",
    // "remove",
    // "history"
  ]
}

// examples in https://github.com/jpuri/react-draft-wysiwyg/tree/master/stories
function uploadImageCallBack(file: File) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open("POST", "https://api.imgur.com/3/image")
    xhr.setRequestHeader("Authorization", "Client-ID XXXXX")
    const data = new FormData()
    data.append("image", file)
    xhr.send(data)
    xhr.addEventListener("load", () => {
      const response = JSON.parse(xhr.responseText)
      resolve(response)
    })
    xhr.addEventListener("error", () => {
      const error = JSON.parse(xhr.responseText)
      reject(error)
    })
  })
}

interface Props {
  editMode: boolean
  value: string
  updateValue: (event: any) => void
  onFocus?: () => void
  toolbarOptions?: object
  stopAutoFocus?: boolean
  placeholder?: string
  // toolbar visible only when editor is focused
  toolbarOnFocus?: boolean
  expandable?: boolean
  readOnly?: boolean // expanded in editMode but not editable
  identifier?: string // need to be unique across the whole page
}

interface State {
  editMode: boolean
  editorState: EditorState
  initialFocus: boolean
  toolbarOnFocus: boolean
  expandable: boolean
  expandedState: boolean
  readOnly: boolean
  // setEditorRef: (ref:RefObject<Editor>) => void
}

class RichTextEditor extends Component<Props, State> {
  private editorRef: React.RefObject<Editor>
  private inputRef: React.RefObject<HTMLInputElement>
  constructor(props: Props) {
    super(props)
    this.state = {
      editorState: this.getEditorState(this.props.value),
      editMode: this.props.editMode,
      readOnly: !!this.props.readOnly,
      initialFocus: true,
      toolbarOnFocus: !!props.toolbarOnFocus,
      expandable: !!this.props.expandable,
      expandedState: false
    }
    this.editorRef = React.createRef<Editor>()
    this.inputRef = React.createRef<HTMLInputElement>()
  }

  focus = () => {
    this.editorRef.current!.focusEditor()
    // this.inputRef.current!.focus()
  }
  getEditorState = (value: string) => {

    // fix CAL-1409 Underline doesn't work due to conversion mismatch
    // wrap content to avoid error "Unknown DraftEntity key: null". see https://github.com/jpuri/react-draft-wysiwyg/issues/609
    let blocks = htmlToDraft(`${value}`)
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(blocks.contentBlocks)
    )
  }

  isEmpty = () => {
    // how to check if RTE value is empty?
    // https://github.com/sstur/react-rte/issues/103#issuecomment-254251845
    return !this.state.editorState.getCurrentContent().hasText()
  }

  updateValue = (value: any) => this.props.updateValue(value)

  componentDidUpdate = (prevProps: Props) => {
    const { editMode, stopAutoFocus } = this.props
    if (prevProps.editMode  !== editMode) {
      if (editMode) {
        // if (!stopAutoFocus) { this.focus() }
        this.setState({ editMode })
      } else {
        this.setState({editorState: this.getEditorState(this.props.value), editMode})
      }
    }
  }

  expandToggle = () => {
    let {expandedState} = this.state
    this.setState({expandedState: !expandedState})
  }

  // expand editor if editMode
  editorOpen = () => {
    return this.state.expandedState||this.props.editMode
  }


  onEditorStateChange = (editorState: EditorState) => {
    // fix CMS-1779 by keep the <br> from original note.
    const removeEmptyTagsRegex = /(((<\w+>)+[ \n]*(<\/\w+>)+)+)/g
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    // fix CAL-1410 saving issue if containing empty lines.
    html = html.replace(removeEmptyTagsRegex, "")
    if (this.props.value !== html) {
      this.updateValue(html)
    }
    
    this.setState({
      editorState
    })
  }

  onTab = (e:SyntheticKeyboardEvent) => {
    // insert space with tabs
    // yield list indenting default behavior
    // https://github.com/facebook/draft-js/issues/121#issuecomment-451763302
    e.preventDefault();
    let currentState = this.state.editorState

    const selection = currentState.getSelection();
    const blockType = currentState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();

    if(blockType === "unordered-list-item" || blockType === "ordered-list-item"){
      return false
    }else{
      let newContentState = Modifier.replaceText(
          currentState.getCurrentContent(),
          currentState.getSelection(),
          '    '
      );
      this.onEditorStateChange(EditorState.push(currentState, newContentState, 'insert-characters'))
      return true
    }
}

  stripPasteFormatting = (text: string, html: string, editorState: EditorState) => {
    // CAL-2064
    /**
     * 1. keep the inline style for almost all tags. Removing all styles will cause text-decoration like underline, bold, italic removed after copy and paste.
     * 2. remove figure img tags as https://github.com/jpuri/react-draft-wysiwyg/issues/609
     * 3. remove h1-h6 as they are huge after paste.
     * */
    const pasteText = DOMPurify.sanitize(html || text, {FORBID_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'figure', 'img'], FORCE_BODY: true})
    const blocksFromHTML = convertFromHTML(pasteText.replace(/\s+/g,' '))
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    const blockMap = state.getBlockMap()
    const newState = Modifier.replaceWithFragment(editorState.getCurrentContent(), editorState.getSelection(), blockMap)
    this.onEditorStateChange(EditorState.push(editorState, newState, 'insert-fragment'))
    return true
  }

  render() {
    const { editorState, editMode, initialFocus, expandable, expandedState, readOnly } = this.state
    return (
      <>
        <div className={classnames({"expandable-container": expandable}, {"expanded": this.editorOpen(), "editing": editMode && !readOnly, "one-line": this.isEmpty() && !editMode, "readonly": editMode &&readOnly})} onClick={this!.focus}>
          <div className={classnames("process-editor", { editing: editMode && !readOnly})}>
            <Editor
              spellCheck={true}
              ref={this.editorRef}
              toolbarClassName="editor-toolbar"
              // wrapperClassName="demo-wrapper"
              // editorClassName="editor"
              // onTab={this.onTab}
              toolbarStyle={{
                position: 'sticky', // CAL-1742
                top: 0,
                zIndex: 1000,
              }}
              editorState={editorState}
              onEditorStateChange={this.onEditorStateChange}
              readOnly={readOnly || !editMode}
              toolbarHidden={readOnly || !editMode}
              toolbarOnFocus={this.state.toolbarOnFocus}
              handlePastedText={this.stripPasteFormatting}
              // onFocus={(event) => {
              //   // event.preventDefault()
              //   event.stopPropagation()
              //   if(initialFocus){
              //     this.setState({ initialFocus: false })
              //   } else if(!initialFocus && this.props.onFocus){
              //     this.props.onFocus()
              //   }
              // }}
              placeholder={this.props.placeholder}
              toolbar={{
                ...(this.props.toolbarOptions || toolbarOptions),
                //   // inline: { inDropdown: true },
                //   // list: { inDropdown: true },
                //   // textAlign: { inDropdown: true },
                //   // link: { inDropdown: true },
                //   // history: { inDropdown: true },
                image: {
                  uploadCallback: uploadImageCallBack,
                  alt: { present: true, mandatory: true }
                }
              }}
              />
            <input type="hidden" ref={this.inputRef}>
            </input>
          </div>
        </div>
        {!editMode && expandable && !this.isEmpty() && (<div className="expand-link">
          <Button color="link" onClick={()=> this.expandToggle()}>
            <FontAwesomeIcon
              icon={expandedState  ? "chevron-up" : "chevron-down"}
              size="sm"
              />
            <span className="pl-2 expand-text">{expandedState  ? "Collapse" : "Expand"}</span>
          </Button>
        </div>)}
      </>
    )
  }
}

export default RichTextEditor
