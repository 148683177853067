import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { first } from 'lodash'
import React, { useState } from 'react'
import { Row, Col, Button } from 'reactstrap'

import Auth from '../../../Auth/Auth'
import { ClientPortfolioDetailComponentFragment, ClientPortfolioDetailLayoutFragment, LayoutSectionType, ReportsFragment } from '../../../__generated__/graphql'
import { ReportAddable } from './ReportAddable'
import ReportComponent, { ErrorComponent, ReportDisplayType, selectedComponentProp, TemplateComponent, ToolbarProps } from './ReportComponent'

type sectionProps = {
  report?: ReportsFragment
  components: ClientPortfolioDetailComponentFragment[]
  auth: Auth
  view: ReportDisplayType
  selectedComponentId?: selectedComponentProp
  setSelectedComponentId?: (value:selectedComponentProp) => void
  sectionNumber: number
  editMode: boolean
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editedDraftLayout: ClientPortfolioDetailLayoutFragment
  setReportState: (value:React.SetStateAction<object>) => void
  reportState: object
  hideSingleExport?: boolean
  clientId?: number
  portfolioId?: number
  overwriteDate?: string
}

type aggregatedSectionProps = sectionProps & {
  type: LayoutSectionType
}

// Find the correct section component and render components with that section
const ReportSection: React.FC<aggregatedSectionProps> = (props) => {
  const { type, ...passedOnProps} = props
  const section = SectionMapping[type]
  if(section){
    return React.createElement(section['component'], {...passedOnProps})
  }

  return(<ErrorComponent name={type || ""} error="Section not Implemented"/>)
}

type EmptyComponentProps = {
  report?: ReportsFragment
  componentNumber: number
  selectedComponentId?: selectedComponentProp
  setSelectedComponentId?: (value:selectedComponentProp) => void
  sectionNumber: number
  editMode: boolean
  setEditedDraftLayout: (value:React.SetStateAction<ClientPortfolioDetailLayoutFragment | undefined>) => void
  editedDraftLayout: ClientPortfolioDetailLayoutFragment
  hideSingleExport?: boolean
}

const EmptyComponent: React.FC<EmptyComponentProps> = (props) => {
  const {editMode, sectionNumber, componentNumber, report, setEditedDraftLayout, editedDraftLayout, setSelectedComponentId} = props

  if(!editMode){
    return (
      <></>
    )
  }

  return (
    <div className='d-flex w-100 justify-content-center report-empty-component py-2'>
      <ReportAddable
        show={true}
        setSelectedComponentId={setSelectedComponentId}
        sectionNumber={sectionNumber}
        componentNumber={componentNumber}
        report={report}
        setEditedDraftLayout={setEditedDraftLayout}
        editedDraftLayout={editedDraftLayout}
      />
    </div>
  )
}

const SingleColumnSection: React.FC<sectionProps> = (props) => {
  const { components, ...passedOnProps} = props
  const firstComponent = first(components)
  if(firstComponent){
    return (
      <ReportComponent
        component={firstComponent}
        componentNumber={-1}
        {...passedOnProps}
      />
    )
  }
  return <></>
}

type MultiColumnProps = sectionProps & {
  sectionType: LayoutSectionType
}

const MultiColumnSection: React.FC<MultiColumnProps> = (props) => {
  const { components, children, ...passedOnProps } = props
  const { sectionNumber, report, setEditedDraftLayout, editedDraftLayout, editMode, selectedComponentId, setSelectedComponentId, view, sectionType, auth } = props

  const selected = sectionNumber === selectedComponentId?.id && selectedComponentId.type === "section" && props.editMode
  const handleSelect = (e: React.MouseEvent) => {
    e.stopPropagation()
    if(props.editMode && setSelectedComponentId) setSelectedComponentId({id: sectionNumber, type: "section"})
  }

  let tooltipProps:ToolbarProps = {'exportOptions': {
    name: report?.name || "",
    slides: [{
      title: report?.name || "",
      sections: [{
        components: components.map((component) => component.id),
        type: sectionType
      }]
    }],
    settings: {
      live: view === ReportDisplayType.Live,
    }
  }}

  return (
    <TemplateComponent
      hideTitle={true}
      selected={selected}
      onClick={handleSelect}
      tooltipProps={tooltipProps}
      editMode={editMode}
      sectionNumber={sectionNumber}
      report={report}
      setEditedDraftLayout={setEditedDraftLayout}
      editedDraftLayout={editedDraftLayout}
      componentNumber={-1}
      setSelectedComponentId={setSelectedComponentId}
      auth={auth}
    >
      {children}
    </TemplateComponent>
  )
}

const TwoColumnSection: React.FC<sectionProps> = (props) => {
  const { components, ...passedOnProps } = props
  return (
    <MultiColumnSection
      sectionType={LayoutSectionType.TwoColumnSection}
      {...props}
    >
      <Row className='mx-0'>
        <Col md={6}>
          {components[0] ? (
            <ReportComponent
              component={components[0]}
              componentNumber={0}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={0} {...passedOnProps} />
          )}
        </Col>
        <Col md={6}>
          {components[1] ? (
            <ReportComponent
              component={components[1]}
              componentNumber={1}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={1} {...passedOnProps} />
          )}
        </Col>
      </Row>
    </MultiColumnSection>
  )
}

const TwoColumnSectionWithLeftSideBar: React.FC<sectionProps> = (props) => {
  const { components, ...passedOnProps} = props
  return (
    <MultiColumnSection
      sectionType={LayoutSectionType.TwoColumnSectionWithLeftSideBar}
      {...props}
    >
      <Row className='mx-0'>
        <Col md={3}>
          {components[0] ? (
            <ReportComponent
              component={components[0]}
              componentNumber={0}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={0} {...passedOnProps} />
          )}
        </Col>
        <Col md={9}>
          {components[1] ? (
            <ReportComponent
              component={components[1]}
              componentNumber={1}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={1} {...passedOnProps} />
          )}
        </Col>
      </Row>
    </MultiColumnSection>
  )
}

const TwoColumnSectionWithRightSideBar: React.FC<sectionProps> = (props) => {
  const { components, ...passedOnProps} = props
  return (
    <MultiColumnSection
      sectionType={LayoutSectionType.TwoColumnSectionWithRightSideBar}
      {...props}
    >
      <Row className='mx-0'>
        <Col md={9}>
          {components[0] ? (
            <ReportComponent
              component={components[0]}
              componentNumber={0}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={0} {...passedOnProps} />
          )}
        </Col>
        <Col md={3}>
          {components[1] ? (
            <ReportComponent
              component={components[1]}
              componentNumber={1}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={1} {...passedOnProps} />
          )}
        </Col>
      </Row>
    </MultiColumnSection>
  )
}

const ThreeColumnSection: React.FC<sectionProps> = (props) => {
  const { components, ...passedOnProps} = props
  return (
    <MultiColumnSection
      sectionType={LayoutSectionType.ThreeColumnSection}
      {...props}
    >
      <Row className='mx-0'>
        <Col md={4}>
          {components[0] ? (
            <ReportComponent
              component={components[0]}
              componentNumber={0}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={0} {...passedOnProps} />
          )}
        </Col>
        <Col md={4}>
          {components[1] ? (
            <ReportComponent
              component={components[1]}
              componentNumber={1}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={1} {...passedOnProps} />
          )}
        </Col>
        <Col md={4}>
          {components[2] ? (
            <ReportComponent
              component={components[2]}
              componentNumber={2}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={2} {...passedOnProps} />
          )}
        </Col>
      </Row>
    </MultiColumnSection>
  )
}

const ThreeColumnSectionWithSideBars: React.FC<sectionProps> = (props) => {
  const { components, ...passedOnProps} = props
  return (
    <MultiColumnSection
      sectionType={LayoutSectionType.ThreeColumnSectionWithSideBars}
      {...props}
    >
      <Row className='mx-0'>
        <Col md={3}>
          {components[0] ? (
            <ReportComponent
              component={components[0]}
              componentNumber={0}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={0} {...passedOnProps} />
          )}
        </Col>
        <Col md={6}>
          {components[1] ? (
            <ReportComponent
              component={components[1]}
              componentNumber={1}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={1} {...passedOnProps} />
          )}
        </Col>
        <Col md={3}>
          {components[2] ? (
            <ReportComponent
              component={components[2]}
              componentNumber={2}
              {...passedOnProps}
            />
          ) : (
            <EmptyComponent componentNumber={2} {...passedOnProps} />
          )}
        </Col>
      </Row>
    </MultiColumnSection>
  )
}

export const SectionMapping: { [key in LayoutSectionType]?: {component: React.FC<sectionProps>, expectedCount: number}} = {
  [LayoutSectionType.SingleColumnSection]: {component: SingleColumnSection, expectedCount: 1},
  [LayoutSectionType.TwoColumnSection]: {component: TwoColumnSection, expectedCount: 2},
  [LayoutSectionType.TwoColumnSectionWithLeftSideBar]: {component: TwoColumnSectionWithLeftSideBar, expectedCount: 2},
  [LayoutSectionType.TwoColumnSectionWithRightSideBar]: {component: TwoColumnSectionWithRightSideBar, expectedCount: 2},
  [LayoutSectionType.ThreeColumnSection]: {component: ThreeColumnSection, expectedCount: 3},
  [LayoutSectionType.ThreeColumnSectionWithSideBars]: {component: ThreeColumnSectionWithSideBars, expectedCount: 3},
}

export default ReportSection