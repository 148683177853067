import _ from "lodash"
import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"

import { FormInputSubClasses } from "../../../helpers/constant"
import { getNewStateObject } from "../../../helpers/helpers"
import { FormInput } from "../../ui/Forms/FormInput"

type RowDefItem = {
  field: string
  type: string
  title: string
  subtype?: string
  subClasses?: { [name in FormInputSubClasses]?: string }
  optionSource?: string
  charactersLimit?: number
  tdClass?: string
}

interface DealLimitationsTableItemProps {
  data: any
  colDefItem: RowDefItem
  rowDefItem: RowDefItem
  highlightRow?: boolean
  idx: number
  row: number
  editMode: boolean
  updateValue: (idx: number, type: string, value: any, property: string) => void
}

type DealLimitationsTableProps = {
  data: any
  handleChange: (state: any, property: string) => void
  editMode?: boolean
}

// constants setting here
const EXPLANATION_CHARACTER_LIMIT = 200

export const DealLimitationsTableRowDef: RowDefItem[] = [
  {
    field: "enterpriseValue",
    type: "float",
    subtype: "currency",
    title: "Enterprise Value ($)"
  },
  {
    field: "trailing12mEBITDA",
    type: "float",
    subtype: "currency",
    title: "Trailing 12M EBITDA ($)"
  },
  {
    field: "revenue",
    type: "float",
    subtype: "currency",
    title: "Trailing 12M Revenue ($)"
  },
  {
    field: "dealSize",
    type: "float",
    subtype: "currency",
    title: "Deal Size ($)"
  },
  {
    field: "numberOfInvestments",
    type: "float",
    title: "Number of Investments",
  }
]

const ManagementColDef: RowDefItem[] = [
  {
    field: "lower",
    type: "float",
    title: "Lower",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    }
  },
  {
    field: "higher",
    type: "float",
    title: "Higher",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    }
  },
  {
    field: "average",
    type: "float",
    title: "Average",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    }
  },
  {
    field: "median",
    type: "float",
    title: "Median",
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    }
  },
  {
    field: "explanation",
    type: "text",
    title: "Explanation",
    charactersLimit: EXPLANATION_CHARACTER_LIMIT,
    subClasses: {
      inputWrapperClasses: "col-sm-12 pl-1"
    },
    tdClass: "w-50"
  }
]

interface DealLimitationsTableRowProps {
  data: any
  index: number
  colDef: RowDefItem[]
  rowDef: RowDefItem
  highlightRow?: boolean
  editMode: boolean
  updateValue: (idx: number, value: any, type: string, property: string) => void
}

export const DealLimitationsTableRow = ({
  data,
  index,
  colDef,
  rowDef,
  editMode,
  updateValue
}: DealLimitationsTableRowProps) => {
  let field = rowDef.field
  const value = _.get(data, field)
  if (_.isUndefined(value)) {
    return <tr key={`${index}`} className={"invisible"}></tr>
  }
  return (
    <tr key={`${index}`}>
      <td key={`DealLimitations-${field}`} className={"text-left"}>
        {rowDef.title}
      </td>
      {colDef.map((col, colIndex) => {
        return (
          <DealLimitationsTableItem
            data={value[col.field]}
            colDefItem={col}
            rowDefItem={rowDef}
            key={colIndex}
            idx={colIndex}
            row={index}
            editMode={editMode}
            updateValue={updateValue}
          />
        )
      })}
    </tr>
  )
}

const DealLimitationsTableItem = ({
  data,
  colDefItem,
  rowDefItem,
  idx,
  row,
  editMode,
  updateValue
}: DealLimitationsTableItemProps) => {
  let { field } = rowDefItem
  let { type, subClasses, subtype, tdClass, charactersLimit } = colDefItem
  subtype = subtype || colDefItem?.subtype || ""
  if(field === "numberOfInvestments" && type ==="float") {
    type = "number"
  }

  if(colDefItem.field === "explanation") {
    // align the explanation field to the left inputs.
    if(editMode) {
      subClasses!.inputWrapperClasses = "col-sm-12 pl-1 pt-3 mt-2"
    } else {
      subClasses!.inputWrapperClasses = "col-sm-12 pl-1"
    }
  }

  return (
    <td key={`${field}-${idx}`} className={tdClass || ""}>
      <FormInput
        property={field + "." + colDefItem.field + "dl"}
        displayName={""}
        type={type}
        subtype={subtype}
        idx={row}
        editMode={editMode}
        propertyVal={data}
        placeholder={type === "number" ? "0" : ""}
        subClasses={subClasses}
        updateValue={value => updateValue(idx, value, type, field)}
        charactersLimit={charactersLimit}
      />
    </td>
  )
}

const DealLimitationsTable = (seed: DealLimitationsTableProps) => {
  const tableName = "Management"
  const widthClassName = "col-sm-12"
  const { data, editMode, handleChange } = seed
  const [tableState, setData] = useState(data)

  useEffect(() => {
    setData(seed.data)
  }, [seed.data])

  if (!data) {
    return <div>No data</div>
  }

  const onHandleChangeManagementRow = (
    idx: number,
    value: any,
    type: string,
    property: string
  ) => {
    let newData =
      getNewStateObject({
        state: tableState,
        newValue: value,
        property: `${property}.${ManagementColDef[idx].field}`,
        type
      }) || {}
    setData(newData)
    handleChange(_.get(newData, property), property)
  }

  return (
    <>
      <div
        className={
          "form-section-title headline underline small-font-size w-100 py-2 mb-2"
        }
        key={`${tableName}-0`}
      >
        {`Deal Limitations`}
      </div>
      <div
        className={`${tableName}-table ${widthClassName} row form-group pl-0`}
      >
        <Table striped hover borderless size="sm" key={`DealLimitations-table`} className={"exportable"} data-export-name={"Deal Limitations Table"}>
          <thead>
            <tr className="table-header">
              <th key={0}>Target At Entry</th>
              {ManagementColDef.map((header, headerIdx) => (
                <th key={headerIdx + 1}>{header.title}</th>
              ))}
            </tr>
          </thead>
          <tbody key={0}>
            {DealLimitationsTableRowDef.map((rowDef, rowIndex) => {
              return (
                <DealLimitationsTableRow
                  index={rowIndex}
                  key={rowIndex}
                  data={tableState}
                  rowDef={rowDef}
                  editMode={!!editMode}
                  updateValue={onHandleChangeManagementRow}
                  colDef={ManagementColDef}
                />
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default DealLimitationsTable
